import React, { useCallback, useEffect } from "react";
import { Table } from "../../components/Table";
import { handleToast } from "../../components/HandleToast";
import { listSales, updateSale } from "../../services/sales-service";
import { AuthContainer } from "../Containers";
import { Content, LowCard, Select } from "./styles";
import { CardsWrapper } from "../../components/CardsWrapper";
import { CardInfo } from "../../components/CardInfo";
import { MdOutlineSell } from "react-icons/md";
import { BiArchiveIn } from "react-icons/bi";
import { useAppSelector } from "../../store/hooks";

interface IOrganizationAddress {
	city: string;
	complement: string;
	country: string;
	createdAt: string;
	district: string;
	number: number;
	state: string;
	street: string;
	updatedAt: string;
	zipcode: string;
	_id: string;
}

interface IOrganization {
	address: IOrganizationAddress[];
	companyName: string;
	isActive: boolean;
	name: string;
	_id: string;
}

interface IUser {
	cel: string;
	celDDD: string;
	image: string;
	name: string;
	tel: string;
	telDDD: string;
	userType: string;
	_id: string;
}

interface ISale {
	amount: number;
	createdAt: string;
	isActive: boolean;
	organization: IOrganization;
	products: string[];
	status: string;
	total: number;
	updatedAt: string;
	user: IUser;
	_id: string;
}

export function Sales() {
	const { data } = useAppSelector((state) => state.user);

	const [sales, setSales] = React.useState<ISale[]>();
	const [loading, setLoading] = React.useState(false);
	const [salesDay, setSalesDay] = React.useState<any>();
	const [salesMonth, setSalesMonth] = React.useState<any>();
	const [startDate, setStartDate] = React.useState(Date.now());
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const onStartDateChange = (e) => {
		setStartDate(e.target.value);
	};

	const handleSales = useCallback(
		async (orgId: String) => {
			setLoading(true);
			const response = await listSales(orgId, { page, rowsPerPage });
			if (!response.error) {
				setSales(response?.data?.items);
				setSalesDay(response?.data?.dailySales);
				setSalesMonth(response?.data?.monthSales);
				setLoading(false);
			}
			setLoading(false);
			return handleToast(response.error, response.message);
		},
		[page, rowsPerPage]
	);

	const handleStatusChange = async (status: string, id: string) => {
		setLoading(true);
		const response = await updateSale(data?.organization._id, {
			status,
			id,
		});
		if (response.error) {
			setLoading(false);
			return handleToast(true, response.message);
		}
		setLoading(false);
		handleSales(data?.organization._id);
	};

	useEffect(() => {
		handleSales(data.organization?._id);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, startDate]);

	return (
		<AuthContainer pageTitle="Minhas Vendas" loading={loading}>
			<Content>
				<CardsWrapper className={"sales-cards-wrapper"}>
					<CardInfo
						color="blue"
						value={salesMonth?.count || 0}
						icon={<MdOutlineSell />}
						label="Total"
						subtitle="Vendas do mês"
						subtitleIcon={<BiArchiveIn />}
						className="card"
					/>
					<CardInfo
						color="green"
						value={salesDay?.count || 0}
						icon={<MdOutlineSell />}
						label="Total"
						subtitle="Vendas do dia"
						subtitleIcon={<BiArchiveIn />}
						className="card"
					/>
				</CardsWrapper>
				<Select>
					<label>Filtro por período:</label>
					<input type="date" value={startDate} onChange={onStartDateChange} />
				</Select>
				<LowCard>
					<Table
						columns={["user", "title", "status", "amount", "createdAt"]}
						header={[
							"#",
							"Usuário",
							"Produto",
							"Status",
							"Quantidade",
							"Pedido",
						]}
						items={sales}
						onRowsPerPageChange={(rows, page) => {
							setRowsPerPage(parseInt(rows));
							setPage(page);
						}}
						onPageChange={(newPage) => setPage(newPage)}
						onClickRefresh={() => handleSales(data.organization?._id)}
						onStatusChange={(value, itemId) =>
							handleStatusChange(value, itemId)
						}
					/>
				</LowCard>
			</Content>
		</AuthContainer>
	);
}
