import api from ".";

export async function listSchedules(orgId, params: object = null) {
    const response = await api.get(`/schedules/${orgId}`, { params });
    return response.data;
}

export async function schedulesAdmin() {
    const response = await api.get(`/admin/schedules`);
    return response.data;
}

export async function changeSchedulesStatus(id: string, body) {
    // let obj = {
    //     status: status,
    // };

    const response = await api.patch(`/schedules/${id}`, body);
    return response.data;
}
