import styled from 'styled-components';
import { Button, TextField } from '@mui/material';

export const InputField = styled(TextField)`
    .MuiTextField-root,
    .MuiOutlinedInput-Base,
    .MuiInputBase-root {
        border: none!important;
        background-color: transparent!important;
    }
`;

export const ButtonField = styled(Button)`
    display: flex;
    width: 100%;
`;
