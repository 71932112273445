import { FiX } from "react-icons/fi";
import styled from "styled-components";

interface PropsContent {
    width?: string;
    maxHeight?: string;
}

export const OutModalStyled = styled.aside`
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: black;

    top: 0;
    right: 0;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    h1 {
        padding: 0;
    }
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    h1 {
        margin: 0;
        padding: 0 !important;
    }
`;
export const Close = styled(FiX)`
    font-size: 1.5rem;
    cursor: pointer;
`;

export const ModalStyled = styled.section<PropsContent>`
    align-items: center;
    justify-content: center;
    width: fit-content(1280px);
    padding: 10px 5px;
    background: #fff;
    border-radius: 10px;
    max-height: ${({ maxHeight }) => maxHeight || "500px"};
    overflow-y: auto;
`;

export const Content = styled.div<PropsContent>`
    padding: 16px;
    width: ${(props) => (props.width ? props.width : "auto")};
`;
