import React, { useEffect, useState } from "react";
import { TableCollaborator } from "../../components/TableCollaborator";
import { PropsCollaborator } from "../../utils/types/collaborator";
import { AuthContainer } from "../Containers";

export function Collaborator() {
    const [page, setPage] = useState<number>(1);
    const [filter, setFilter] = useState<string>();
    const [
        collaborator,
        // setCollaborator
    ] = useState<PropsCollaborator[]>([]);
    useEffect(() => {
  
    }, [page, filter]);
    return (
        <AuthContainer pageTitle="Colaboradores">
            <TableCollaborator
                collaborator={collaborator}
                setPage={(newPage) => setPage(newPage)}
                setFilter={(newFilter) => setFilter(newFilter)}
            />
        </AuthContainer>
    );
}
