import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid var(--border);
`;

export const DataContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
    padding-bottom: 5px;
`;

export const Data = styled.p`
    text-align: start;
    width: 100%;
`;

export const ButtonContainer = styled.button`
    margin: 20px;
    border: none;
    width: 100%;
    button {
        width: 100%;
    }
`;
