import { MenuItem } from "react-pro-sidebar";
import styled from "styled-components";

export const CollapseButton = styled(MenuItem)`
	// margin-left: 10px;
`;

export const Container = styled.div<{ collapsed?: boolean }>`
	.submenu:last-child {
		margin-bottom: ${({ collapsed }) => !collapsed && "30px"};
	}
	height: 100%;
`;

export const ImageWrapper = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20;
	marginBottom: 15;
`