import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    h2 {
        font-family: "Roboto", sans-serif;
        font-size: 26px;
        line-height: 1;
        color: $theme;
        margin-bottom: 0;
    }
    p {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        color: $dark-text;
    }

    // Upload Demo
    //

    .background {
        position: absolute;
        object-fit: cover;
        width: 60%;
        top: 50%;
        left: 50%;
        opacity: 0.15;

        transform: translate(-50%, -50%);
    }

    .uploader {
        display: block;
        clear: both;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;
        position: relative;

        overflow: hidden;

        label {
            float: left;
            clear: both;
            width: 100%;
            padding: 2rem 1.5rem;
            text-align: center;
            background: #fff;
            border-radius: 7px;
            border: 3px solid #eee;
            transition: all 0.2s ease;
            user-select: none;

            &:hover {
                border-color: $theme;
            }
            &.hover {
                border: 3px solid $theme;
                box-shadow: inset 0 0 0 6px #eee;

                #start {
                    i.fa {
                        transform: scale(0.8);
                        opacity: 0.3;
                    }
                }
            }
        }

        #start {
            float: left;
            clear: both;
            width: 100%;
            &.hidden {
                display: none;
            }
            i.fa {
                font-size: 50px;
                margin-bottom: 1rem;
                transition: all 0.2s ease-in-out;
            }
        }
        #response {
            float: left;
            clear: both;
            width: 100%;
            &.hidden {
                display: none;
            }
            #messages {
                margin-bottom: 0.5rem;
            }
        }

        #file-image {
            display: inline;
            margin: 0 auto 0.5rem auto;
            width: auto;
            height: auto;
            max-width: 180px;
            &.hidden {
                display: none;
            }
        }

        #notimage {
            display: block;
            float: left;
            clear: both;
            width: 100%;
            &.hidden {
                display: none;
            }
        }

        progress,
        .progress {
            // appearance: none;
            display: inline;
            clear: both;
            margin: 0 auto;
            width: 100%;
            max-width: 180px;
            height: 8px;
            border: 0;
            border-radius: 4px;
            background-color: #eee;
            overflow: hidden;
        }

        .progress[value]::-webkit-progress-bar {
            border-radius: 4px;
            background-color: #eee;
        }

        .progress[value]::-webkit-progress-value {
            background: linear-gradient(
                to right,
                darken($theme, 8%) 0%,
                $theme 50%
            );
            border-radius: 4px;
        }
        .progress[value]::-moz-progress-bar {
            background: linear-gradient(
                to right,
                darken($theme, 8%) 0%,
                $theme 50%
            );
            border-radius: 4px;
        }

        input[type="file"] {
            display: none;
        }

        div {
            margin: 0 0 0.5rem 0;
            color: $dark-text;
        }
        .btn {
            display: inline-block;
            margin: 0.5rem 0.5rem 1rem 0.5rem;
            clear: both;
            font-family: inherit;
            font-weight: 700;
            font-size: 14px;
            text-decoration: none;
            text-transform: initial;
            border: none;
            border-radius: 0.2rem;
            outline: none;
            padding: 0 1rem;
            height: 36px;
            line-height: 36px;
            color: #fff;
            transition: all 0.2s ease-in-out;
            box-sizing: border-box;
            background: $theme;
            border-color: $theme;
            cursor: pointer;
        }
    }
    .files-messages {
        border: 1px solid black;
        border-top: none;
        border-left: none;
        border-right: none;
        font-size: 15px;
        margin-top: 10px;

        img {
            width: 80px;
            object-fit: fill;
        }
    }

    .list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        align-items: center;
    }

    .icon {
        width: 22px;
        height: 22px;
        cursor: pointer;
        margin: 0;
        padding: 0;
    }
`;
