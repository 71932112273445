import styled from "styled-components";

export const AuthForm = styled.form`
	display: flex;
	flex: 1 1 100%;
	flex-direction: column;
	width: 100%;
	gap: 10px;

	input {
		margin-left: 25px;
		padding: 10px 0;
	}

	@media (min-width: 730px) {
		max-width: 600px;
	}
`;

export const SignInStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;

	.toast {
		position: fixed;
		max-width: 10%;
		max-height: 10%;
	}

	h1 {
		font: var(--text-title);
		padding-bottom: 3rem;
		text-align: center;
	}

	div {
		display: flex;
		flex-direction: column;
		width: 100%;

		span {
			color: red;
		}
		label {
			font: var(--text-body);
		}
	}

	@media (max-width: 1100px) {
		display: flex;
		width: 90%;
		align-items: center;
		justify-content: center;
	}
`;
