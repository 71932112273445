import { useCallback, useEffect, useState } from "react";
import { listSettings } from "../../../services/settingsService";
import { PropsSettings } from "../../../utils/types/settings";
import { AuthContainer } from "../../Containers";
import { WorkDays } from "../../../components/Tabs/WorkDays";
import { useAppSelector } from "../../../store/hooks";

interface IPropsSettings {
	items: PropsSettings[];
}

export function WorkDaysPage() {
	const { data } = useAppSelector((state) => state.user);

	const [settings, setSettings] = useState<IPropsSettings>({
		items: [],
	});

	useEffect(() => {
		handleLoadSettings();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoadSettings = useCallback(async () => {
		const response = await listSettings(data.organization?._id);
		if (!response.error) {
			setSettings(response.data);
		}
	}, [data.organization?._id]);

	return (
		<AuthContainer pageTitle="Configurações | Horário de funcionamento">
			<WorkDays
				settings={settings.items}
				onUpdate={() => handleLoadSettings()}
			/>
		</AuthContainer>
	);
}
