import { AuthContainer } from "../../../views/Containers";

import { FormBank } from "../../Forms/Bank";

import { Container, Content } from "./styles";
import { useAppSelector } from "../../../store/hooks";
import { handleToast } from "../../HandleToast";

export function Bank() {
	const { data } = useAppSelector((state) => state.user);

	return (
		<AuthContainer pageTitle="Configurações | Bancos">
			<Container>
				<Content>
					<FormBank
						company={data}
						edit
						onUpdate={() => handleToast(false, "Dados bancários atualizados.")}
					/>
				</Content>
			</Container>
		</AuthContainer>
	);
}
