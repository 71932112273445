import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 10px;
    width:100%;
    overflow:hidden;

    .form-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }
    .input-div {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0;

        label {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }
    }
    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 120%;

        gap: 1rem;

        .input-div {
            width: 100%;
        }
    }
    .input {
        width: 300px;
        height: 43px;
        padding: 10px;
        border-radius: 10px;

        border: 1px solid rgba(28, 28, 28, 0.25);

        outline: none;

        font: var(--text-body);
    }

    .column {
        display: flex;
        flex: 1;
        width: 20rem;
        flex-direction: column;
    }

    .upload-wrapper {
        position: relative;

        width: 100%;
        max-width: 300px;
        height: 300px;

        border: 3px dashed #e1e1e1;
        border-radius: 4px;

        .image-wrapper {
            object-fit: fill;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: fill;
                border-radius: 4px;
            }
        }
    }

    .content-button {
        display: flex;

        margin: 1rem 0;
        justify-content: flex-end;
    }

`;

export const ImageUpladComponent = styled.label<{ image?: any }>`
    position: absolute;
    bottom: 0;
    z-index: 9;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${(props) => (props.image ? "30px" : "100%")};
    padding: 10px;
    background-color: ${(props) =>
        props.image ? "rgba(255,255,255,.65)" : "#ededed"};
    cursor: pointer;
    border-radius: ${(props) => (props.image ? "0 0 4px 4px" : "4px")};

    span {
        display: flex;
        flex-direction: ${(props) => (props.image ? "row" : "column")};
        align-items: center;
        justify-content: center;
        gap: 8px;

        svg {
            font-size: 38px;
        }
    }
`;

export const TextArea = styled.textarea`
    width: 100%;
    height: 280px;
    padding: 10px;
    resize: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    border-radius: 4px;
`;

export const ContainerD = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 900px;

    form {
        display: flex;
        flex-direction: column;
    }

    ul {
        list-style: decoration;
    }

    .button-content {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
    }
    .ck {
        max-width: 900px;
        max-height: 500px;
    }
    .ck-reset {
        max-height: 600px;
    }

    .ck-sticky-panel__content_sticky {
        margin-top: 4rem;
    }
`;
