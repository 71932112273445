import { BiRefresh } from "react-icons/bi";
import styled from "styled-components";

export const Container = styled.section`
	display: flex;
	flex: 2;
	flex-direction: column;
	max-height: 100%;
	width: 100%;
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	background-color: #ffffff;

	@media (max-width: 1200px) {
		max-width: 650px;
	}
`;

export const ContainerCalendar = styled.section`
	display: flex;
	height: 510px;
	max-width: 650px;
	width: 100%;

	flex: 1;
	flex-direction: column;
	padding: 20px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	@media (min-width: 1400px) {
		padding: 32px;
	}
	@media (max-width: 500px) {
		height: 100%;
		padding: 50px;
	}
`;

export const CardsWrapperScheduling = styled.section`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	margin-bottom: 1rem;
	max-width: 100%;
	max-height: 100%;

	flex-wrap: wrap;

	@media (max-width: 1050px) {
		justify-content: center;
	}

	@media (max-width: 600px) {
		max-width: 500px;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 1rem;
`;

export const Row = styled.div`
	display: flex;
	max-width: 100%;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	@media (max-width: 1200px) {
		flex-direction: column;
		gap: 5px;
	}
`;

export const ListContainer = styled.section`
	max-width: 100%;
	height: 450px;
	width: 100%;
	overflow-y: auto;
	@media (max-width: 550px) {
		padding: 50px;
	}
`;

export const LoadingContainer = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	// background-color: rgba(0,0,0,0.2)
`;

export const ContentHeader = styled.section`
	padding: 10px;
	border-bottom: 0.75px solid #e0e0e0;
`;

export const DrawerContent = styled.section`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 350px;
	padding: 20px;
	gap: 15px;

	li {
		width: 100%;
		list-style-type: none;
	}
`;

export const ContainerButton = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 8px;
`;

export const ContainerVehicleData = styled.section`
	display: flex;
	width: 100%;
	gap: 15px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const SelectDrawerContent = styled.section`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 15px;

	input {
		width: 100%;
		height: 45px;
		padding: 5px;
		box-sizing: border-box;
		border: 1px solid black;
		/* outline: none; */
		cursor: pointer;
	}
	label {
		width: 100%;
		text-align: left;
		font-size: 12px;
		gap: 1rem;
	}
`;

export const Refresh = styled(BiRefresh)`
	width: 100%;
	height: 100%;
`;

export const Select = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 15px;

	input {
		width: 120px;
		border: none;
		height: 45px;
		padding: 5px;
		box-sizing: border-box;
		outline: none;
	}
	label {
		width: 100%;
		text-align: left;
		font-size: 12px;
		gap: 1rem;
	}
`;

export const ContainerButtonModal = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-end;
	padding-top: 10px;
	gap: 2rem;
`;
