import { FaUser } from "react-icons/fa";
import { FiAlignJustify } from "react-icons/fi";
import styled from "styled-components";

export const Container = styled.div<{ collapsed?: boolean }>`
	display: flex;
	gap: 20px;

	margin-top: 40px;
	bottom: 20px;

	flex-direction: ${({ collapsed }) => (collapsed ? "column" : "row")};
	max-width: 190px;
	background: white;
	margin: auto auto 20px auto;
	align-self: center;
`;

export const Avatar = styled.div<{ collapsed?: boolean }>`
	align-items: center;
	width: 100%;

	cursor: pointer;
	display: flex;

	flex-direction: row;

	p {
		font: var(--text-body);
		display: flex;

		align-items: center;
	}
	.image {
		display: flex;
		flex-direction: row;
		gap: 1rem;

		img {
			width: 25px;
			height: 25px;
			border-radius: 100%;
		}
	}

	@media (max-width: 800px) {
		p {
			display: none;
		}
	}
`;

export const ImageUser = styled(FaUser)`
	width: 25px;
	height: 25px;

	border-radius: 100%;
`;

export const IconsWrapper = styled.section`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
	// margin-right: 2rem;

	svg {
		cursor: pointer;
	}
`;

export const ShowMenu = styled(FiAlignJustify)`
	display: none;
	color: black;

	@media (max-width: 800px) {
		display: flex;
		color: var(--primary-blue);
	}
`;

export const Divider = styled.aside`
	height: 35px;
	width: 1px;
	background-color: rgba(21, 21, 21, 0.3);
`;
