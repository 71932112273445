import styled from "styled-components";


export const AuthForm = styled.form`
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    @media (min-width: 730px) {
        max-width: 600px;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const RecoverPasswordStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    margin: auto 0;

    h1 {
        font: var(--text-title);
        padding-bottom: 3rem;
        text-align: center;
    }
`;
