import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1rem;
`;

export const Content = styled.div`
    width: 100%;
    h1 {
        color: #2020e6;
        font: var(--text-title);
        padding-top: 2rem;
    }
    .row-data {
        display: flex;
        gap: 1rem;
    }

    .data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .button-profile {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
    }

    .data-img {
        justify-content: center;
        margin: 0;
        padding: 0;
        img {
            width: 12.875rem;
            border-radius: 50%;
            border: 1px solid #1c1c1c;
            margin-bottom: 15px;
        }
    }

    .data-user {
        display: flex;
        width: 100%;
        justify-content: start;
        @media (max-width: 1100px) {
            flex-direction: column;
            gap: 0;
        }
    }

    .user {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .user-teste {
        display: flex;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        align-items: center;
        justify-content: flex-start;
        /* text-align: center; */
        gap: 2rem;
        h3 {
            display: flex;
            flex: 1;
            width: 100%;
            font: var(--text-body);
            color: black;
            font-weight: 400;
            padding: 1rem;
        }
        p {
            display: flex;
            flex: 2;
            width: 100%;
            font-weight: 200;
            color: #696969;
            font: var(--text-small);
            padding: 1rem;
        }
    }

    .update-user {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 2.125rem;
        justify-content: center;
        align-items: center;
    }

    form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }

    /* form {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        margin-bottom: 2rem;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;

        div {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            i {
                display: flex;
                align-items: center;
                cursor: pointer;
                border: 0;
                background: transparent;
                font-size: 1.2rem;
                z-index: 10;
                position: absolute;
                margin-left: 13rem;
                margin-top: 1.8rem;
            }
            input {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                outline: 0;
                border: 1px solid rgba(21, 21, 21, 0.1);
                font: var(--text-body);
                padding: 0.8rem;
                margin-bottom: 2.234rem;
                color: #808080;
            }
            p {
                width: 100%;
                text-align: start;
                font-weight: 400;
                color: black;
            }
        }

        @media (max-width: 1100px) {
            gap: 0;
        }

        .form-update {
            display: flex;
            flex-direction: column;

            @media (max-width: 1100px) {
                gap: 0;
                flex-direction: column;
            }
        }
    } */

    .row-button {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
    }
`;
