import React from "react";
import {
	IconButton,
	MenuItem,
	Table,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import {
	Column,
	Container,
	InputContainer,
	Row,
	TableImageCell,
	TBody,
	TContainer,
	Title,
	ProductEdit,
	ProductDelete,
} from "./styles";
import { IoMdRefresh } from "react-icons/io";
import moment from "moment";
import Search from "../Search";

interface IProps {
	columns?: string[];
	header: string[];
	items: any[];
	onClickAdd?: () => void;
	onClickRefresh?: () => void;
	handleDelete?: (itemId: string) => void;
	handleEdit?: (item: any, itemId: string) => void;
	handleChange?: (value: any) => void;
	onStatusChange?: (value: any, itemId: any) => void;
	add?: boolean | false;
	options?: string[];
	onRowsPerPageChange?: (rows: string, page: number) => void;
	onPageChange?: (page: number) => void;
	heading?: string;
	buttonTitle?: string;
}

export default function TableComponent(props: IProps) {
	const {
		columns,
		header,
		items,
		handleDelete,
		handleEdit,
		handleChange,
		onClickAdd,
		onClickRefresh,
		onRowsPerPageChange,
		onPageChange,
		options,
		onStatusChange,
		heading,
		buttonTitle,
	} = props;

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		onPageChange(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
		onRowsPerPageChange(event.target.value, 1);
	};

	const renderBody = (data: any[]) => {
		let rows = [];
		if (data && data.length > 0) {
			data.map((item, index) => {
				return rows.push(
					<TableRow key={index}>
						<TableCell
							key={index}
							align="center"
							sx={{
								width: "50px",
							}}
						>
							{index + 1}
						</TableCell>
						{columns.map((column, index) => {
							if (column === "image") {
								return (
									<TableImageCell key={index} className="img" align="center">
										<img src={item[column]} alt="imagem do produto" />
									</TableImageCell>
								);
							}
							if (column === "user") {
								return (
									<TableImageCell
										key={index}
										align="center"
										sx={{
											width: "80px",
										}}
									>
										{item[column].name}
									</TableImageCell>
								);
							}
							if (column === "products") {
								let title = [];
								item[column].map((item) => title.push(item.title));
								return (
									<TableImageCell
										key={index}
										align="center"
										sx={{
											width: "80px",
										}}
									>
										{title.join(",") || "-"}
									</TableImageCell>
								);
							}
							if (
								column === "salePrice" ||
								column === "costPrice" ||
								column === "price"
							) {
								return (
									<TableCell
										key={index}
										className="price"
										align="center"
										sx={{
											width: "80px",
										}}
									>
										{new Intl.NumberFormat("pt-BR", {
											style: "currency",
											currency: "BRL",
										}).format(item[column])}
									</TableCell>
								);
							}
							if (column === "createdAt" || column === "updatedAt") {
								return (
									<TableCell
										key={index}
										className="date"
										align="center"
										sx={{
											width: "80px",
										}}
									>
										{moment(item[column]).format("DD/MM/YYYY")}
									</TableCell>
								);
							}
							if (column === "status" && onStatusChange) {
								return (
									<TableCell
										key={index}
										className="date"
										align="center"
										sx={{
											width: "80px",
										}}
									>
										<TextField
											select
											size="small"
											fullWidth
											value={item[column]}
											onChange={(event) =>
												onStatusChange(event.target.value, item._id)
											}
										>
											<MenuItem value="Aguardando">Aguardando</MenuItem>
											<MenuItem value="Finalizado">Finalizado</MenuItem>
											<MenuItem value="Cancelado">Cancelado</MenuItem>
										</TextField>
									</TableCell>
								);
							}
							if (column === "actions") {
								return (
									<TableCell
										key={index}
										className="actions"
										align="center"
										sx={{
											width: "80px",
										}}
									>
										{handleEdit && (
											<ProductEdit
												onClick={() => handleEdit(item?._id, item)}
											/>
										)}
										{handleDelete && (
											<ProductDelete onClick={() => handleDelete(item?._id)} />
										)}
									</TableCell>
								);
							}
							if (column === "amount") {
								return (
									<TableCell
										key={index}
										className="amount"
										align="center"
										sx={{
											width: "80px",
										}}
									>
										{item[column]}
									</TableCell>
								);
							}
							if (column === "installmentValue") {
								return (
									<TableCell
										key={index}
										className="price"
										align="center"
										sx={{
											width: "80px",
										}}
									>
										{new Intl.NumberFormat("pt-BR", {
											style: "currency",
											currency: "BRL",
										}).format(item[column] || 0)}
									</TableCell>
								);
							}
							if (column === "total") {
								return (
									<TableCell
										key={index}
										className="price"
										align="center"
										sx={{
											width: "80px",
										}}
									>
										{new Intl.NumberFormat("pt-BR", {
											style: "currency",
											currency: "BRL",
										}).format(item[column])}
									</TableCell>
								);
							}
							return (
								<TableCell
									key={index}
									align="center"
									sx={{
										width: "80px",
									}}
								>
									{item[column] || "-"}
								</TableCell>
							);
						})}
					</TableRow>
				);
			});
		}

		return rows;
	};

	const renderHeader = (data: any[]) => {
		let rows = [];
		if (data)
			data.forEach((item, index) => {
				if (item === "Quantidade") {
					rows.push(
						<TableCell
							key={index}
							className="column-header"
							align="center"
							sx={{
								width: "80px",
							}}
						>
							{item}
						</TableCell>
					);
				} else {
					rows.push(
						<TableCell key={index} className="column-header" align="center">
							{item}
						</TableCell>
					);
				}
			});

		return rows;
	};

	return (
		<Container>
			<Row>
				{heading && (
					<Column>
						<Title>{heading}</Title>
					</Column>
				)}
				{handleChange && (
					<InputContainer>
						<Search
							options={options}
							handleChange={(value) => handleChange(value)}
						/>
					</InputContainer>
				)}
				{onClickAdd && (
					// <IconButton onClick={onClickAdd} size="small">
					//     <IoMdAdd />
					// </IconButton>
					<Button
						variant="contained"
						color={"primary"}
						onClick={onClickAdd}
						// startIcon={<IoMdAdd />}
					>
						{buttonTitle}
					</Button>
				)}
				{onClickRefresh && (
					<IconButton color={"primary"} onClick={onClickRefresh} size="medium">
						<IoMdRefresh />
					</IconButton>
				)}
			</Row>
			<TContainer className="table-container">
				<Table stickyHeader className="table" aria-label="sticky table">
					<TableHead className="table-header">
						<TableRow>{renderHeader(header)}</TableRow>
					</TableHead>
					<TBody className="table-body" id="table-body">
						{renderBody(items)}
					</TBody>
					<TableFooter></TableFooter>
				</Table>
			</TContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 50, 100]}
				component="section"
				count={items?.length}
				labelRowsPerPage="Itens por página"
				labelDisplayedRows={({ from, to, count }) =>
					`${from}-${to} de ${count !== -1 ? count : 0}`
				}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Container>
	);
}
