import api from "."

export async function deleteOrganization(orgId: string) {
    const response = await api.delete(`/organization/${orgId}/delete-all` );
    return response.data;
}

export async function updateBank(orgId: any, data: any) {
    const response = await api.put(`/organization/${orgId}/bank`, data);
    return response.data;
}

export async function createBank(orgId: any, data: any) {
    const response = await api.put(`/organization/${orgId}/bank`, data);
    return response.data;
}