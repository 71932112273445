import { IoIosAdd } from "react-icons/io";
import styled from "styled-components";

export const Container = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    max-height: 400px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--primary-blue);

        border-radius: 20px;
    }
`;
export const PTable = styled.table`
    width: 100%;
    min-width: 800px;
    border-collapse: collapse;

    th {
        text-align: left;
        padding: 10px;
        background-color: var(--primary-white);
        /* position: absolute; */
    }

    tbody {
        tr {
            text-align: left;
            border-top: 1px solid rgba(21, 21, 21, 0.3);
            :hover {
                background-color: rgba(21, 21, 21, 0.1);
            }
            .description-limit {
                max-width: 10ch;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            td {
                height: 72px;
                text-align: left;
                img {
                    max-width: 7vh;
                }
            }
        }
    }

    @media (max-width: 1100px) {
        min-width: 500px;
    }
`;

export const Add = styled(IoIosAdd)`
    font-size: 18px;
    cursor: pointer;
`;
