import { Box } from "@mui/material";
import { Graphic } from "../Graphic";
import { batch } from "react-redux";

type Props = {
    data: any[];
};

export function FinancialSale(props: Props) {
    const { data } = props;
    let _category = [];
    let _seriesArray = [];

    const values = () => {
        data.forEach((resp) => {
            resp._id.forEach((result) =>
            {
                _category.push(result)
                _seriesArray.push(resp.qtdVendas);
            });
        });
    };

    const series = [
        {
            name: "quantidade",
            data: _seriesArray,
        },
    ];

    const categories = _category;

    batch(() => {
        values();
    });
    return (
        <Box sx={{ backgroundColor: "#ffffff", flex: 1, padding: "15px" }}>
            <div>
                <h1 style={{ fontSize: "25px", margin: "5px 0" }}>Receita</h1>
                <h4>Venda por categoria</h4>
            </div>
            <div>
                <Graphic
                    categories={categories}
                    series={series}
                    type="bar"
                    width="100%"
                    heigth="300"
                />
            </div>
        </Box>
    );
}
