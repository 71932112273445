import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Column, Container, Row } from "./styles";
import { Button } from "../../StyledComponents";
import { PropsCompany } from "../../../store/user";
import { listBanks } from "../../../services/bank";
import { handleToast } from "../../HandleToast";
import { MenuItem, TextField } from "@mui/material";

import {
	createBank,
	updateBank,
} from "../../../services/organization-services";
import { Input } from "../../Input";
import { useAppSelector } from "../../../store/hooks";

type Props = {
	cancel?: () => void;
	onUpdate?: () => void;
	company?: PropsCompany;
	backStep?: () => void;
	edit?: boolean;
};

export function FormBank(props: Props) {
	const { onUpdate, cancel, company, backStep, edit } = props;
	const { data } = useAppSelector((state) => state.user);
	const [loading, setLoading] = useState(false);

	const [listBank, setListBank] = useState({
		items: [],
	});

	const [validationForm, setValidationForm] = React.useState<boolean>(true);

	const [values, setValues] = useState({
		accountType: company?.organization?.bank?.accountType || "",
		compe: company?.organization?.bank?.compe || "",
		accountDigity: company?.organization?.bank?.accountDigity || "",
		account: company?.organization?.bank?.account || "",
		agency: company?.organization?.bank?.agency || "",
		agencyDigity: company?.organization?.bank?.agencyDigity || "",
	});

	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	const handleBank = useCallback(async () => {
		const response = await listBanks();
		setListBank(response);
	}, []);

	useEffect(() => {
		const getBanks = async () => {
			await handleBank();
		};

		getBanks();
	}, [handleBank]);

	async function handleSubmitForm() {
		setLoading(true);

		if (
			!values.accountType ||
			!values.agency ||
			!values.accountDigity ||
			!values.account ||
			!values.compe ||
			!values.agencyDigity
		) {
			return handleToast(true, "Dados bancários incompletos");
		}

		let obj = {
			bank: {
				accountType: values.accountType,
				agency: values.agency,
				accountDigity: values.accountDigity,
				account: values.account,
				compe: values.compe,
				agencyDigity: values.agencyDigity,
			},
		};

		if (
			company?.organization?.bank?.agency ||
			company?.organization?.bank?.account
		) {
			const response = await updateBank(company?.organization?._id, obj);
			if (!response.error) {
				setLoading(false);
				return onUpdate();
			} else {
				setLoading(false);
				return handleToast(response.error, response.message);
			}
		} else {
			const response = await createBank(data.organization?._id, obj);
			if (!response.error) {
				handleToast(false, "Banco Cadastrado");
				setLoading(false);
				return onUpdate();
			} else {
				setLoading(false);
				return handleToast(response.error, response.message);
			}
		}
	}

	const handleChange = useCallback(
		(property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({
				...values,
				[property]: (event.target as HTMLInputElement).value,
			});
			setValue(property, (event.target as HTMLInputElement).value);
		},
		[setValue, values]
	);

	useEffect(() => {
		if (
			company?.organization?.bank?.account &&
			company?.organization?.bank?.accountDigity &&
			company?.organization?.bank?.accountType &&
			company?.organization?.bank?.agency &&
			company?.organization?.bank?.agencyDigity &&
			company?.organization?.bank?.compe
		) {
			setValidationForm(false);
		}
	}, [company]);

	return (
		<>
			<Container className="container">
				<form
					onSubmit={handleSubmit(handleSubmitForm)}
					style={{ width: "100%", padding: "0", margin: "0" }}
				>
					<Row>
						<TextField
							select
							label="Tipo de conta"
							helperText={errors.accountType?.message?.toString()}
							onChange={handleChange("accountType")}
							value={values?.accountType}
							fullWidth
							size="small"
							InputLabelProps={{ shrink: true }}
						>
							<MenuItem value="legalPerson">Pessoa Jurídica</MenuItem>
							<MenuItem value="physicalPerson">Pessoa Física</MenuItem>
						</TextField>
						<TextField
							select
							label="Banco"
							helperText={errors.compe?.message?.toString()}
							onChange={handleChange("compe")}
							value={values?.compe}
							InputLabelProps={{ shrink: true }}
							fullWidth
							size="small"
						>
							{listBank.items.map((bank) => (
								<MenuItem value={bank.compe} key={bank.compe}>
									{`${bank.compe} ${bank.shortName}`}
								</MenuItem>
							))}
						</TextField>
					</Row>
					<Column>
						<Row>
							<Input
								label="Agência"
								mask="number"
								onChange={handleChange("agency")}
								value={values?.agency}
								sx={{
									display: "flex",
									flex: 1,
								}}
								size="small"
								shrink
							/>

							<Input
								label="Dígito"
								mask="number"
								onChange={handleChange("agencyDigity")}
								value={values?.agencyDigity}
								sx={{
									display: "flex",
									flex: 0.2,
								}}
								maxLength={2}
								shrink
							/>
						</Row>
						<Row>
							<TextField
								label="Conta"
								helperText={errors.account?.message?.toString()}
								onChange={handleChange("account")}
								value={values?.account}
								sx={{
									display: "flex",
									flex: 1,
								}}
								size="small"
							/>
							<Input
								label="Dígito"
								mask="number"
								onChange={handleChange("accountDigity")}
								value={values?.accountDigity}
								sx={{
									display: "flex",
									flex: 0.2,
								}}
								size="small"
								maxLength={2}
								shrink
							/>
						</Row>
						{validationForm ? (
							<div
								style={{
									display: "flex",
									gap: "10px",
									justifyContent: "flex-end",
								}}
							>
								<Button type="button" className="back" onClick={() => cancel()}>
									{" "}
									Voltar
								</Button>
								<Button type="submit">Próximo</Button>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									gap: "15px",
									justifyContent: "flex-end",
								}}
							>
								{backStep && (
									<Button
										type="button"
										outline
										onClick={() => backStep()}
										disabled={loading}
									>
										Voltar
									</Button>
								)}
								<Button type="submit" disabled={loading}>
									{loading ? "Enviando..." : edit ? "Enviar" : "Próximo"}
								</Button>
							</div>
						)}
					</Column>
				</form>
			</Container>
		</>
	);
}
