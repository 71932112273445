import api from ".";

//Criação de produtos

export async function createPromotion(data: any) {
    let obj = {
        data,
    };
    const response = await api.post("/promotion", obj);
    return response.data;
}

export async function imageUpload(data: any) {
    const response = await api.post("/promotion/imageupload", data);
    return response.data;
}

//Listagem de todos os produtos

export async function listPromotions(params: object = null) {
    const response = await api.get(`/promotion`, { params });
    return response.data;
}

//Pegar um único produto

export async function listPromotion(id: any) {
    const response = await api.get(`/promotion/promotionid/${id}`);
    return response.data;
}

//editar produto

export async function updatePromotion(id: string, orgId: string, data: any) {
    let obj = {
        data,
    };

    const response = await api.put(`/promotion/${id}/seller/${orgId}`, obj);
    return response.data;
}
export async function deletePromotion(id: string,orgId:string) {
    const response = await api.delete(`/promotion/${id}/seller/${orgId}`);
    return response.data;
}

/**
 * list
 * getById
 * update
 *
 */
