import api from ".";

export async function createWorkDay(data: any, orgId: string) {
    const response = await api.post(`/work-day/${orgId}`, { data: data });
    return response.data;
}
export async function updateWorkDay(data: any, orgId: string) {
    const response = await api.put(`/work-day/${orgId}`, { data: data });
    return response.data;
}
