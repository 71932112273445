import React, { useCallback, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Box, TextField } from "@mui/material";

import { Container, Content } from "./styles";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Button } from "../StyledComponents";
import { PropsUser } from "../../store/user";
import { updateUserAsync } from "../../store/user/model";
import { Loading } from "../Loading";

export function UserProfile() {
	const dispatch = useAppDispatch();
	const { data, loading } = useAppSelector((state) => state.user);

	const [values, setValues] = useState<PropsUser>(null);

	useEffect(() => {
		if (data) {
			setValues(data.user);
		}
	}, [data]);

	const handleUpdateUser = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		let data = values;

		if (values.cel && values.celDDD) {
			data.celDDD = values.celDDD.replace(/([^\d])+/gim, "");
			data.cel = values.cel.replace(/([^\d])+/gim, "");
		}

		if (values.tel && values.telDDD) {
			data.telDDD = values.telDDD.replace(/([^\d])+/gim, "");
			data.tel = values.tel.replace(/([^\d])+/gim, "");
		}

		dispatch(updateUserAsync(values));
	};

	const handleChange = useCallback(
		(property: string) =>
			(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				const value = event.target.value;
				setValues({
					...values,
					[property]: value,
				});
			},
		[values]
	);

	return (
		<Container>
			<Content>
				<form onSubmit={handleUpdateUser}>
					<TextField
						fullWidth
						size="small"
						label="Nome"
						variant="outlined"
						value={values?.name}
						InputLabelProps={{
							shrink: !!values?.name,
						}}
						onChange={handleChange("name")}
					/>
					<TextField
						fullWidth
						size="small"
						label="Sobrenome"
						variant="outlined"
						value={values?.lastName}
						InputLabelProps={{
							shrink: !!values?.lastName,
						}}
						onChange={handleChange("lastName")}
					/>
					<TextField
						fullWidth
						size="small"
						label="E-mail"
						variant="outlined"
						value={values?.email}
						InputLabelProps={{
							shrink: !!values?.email,
						}}
						onChange={handleChange("email")}
					/>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "15px",
						}}
					>
						<TextField
							size="small"
							label="DDD"
							variant="outlined"
							placeholder="00"
							value={values?.telDDD}
							InputProps={{
								inputComponent: InputMask,
								inputProps: {
									mask: "99",
									maskChar: "",
								},
							}}
							InputLabelProps={{
								shrink: !!values?.telDDD,
							}}
							onChange={handleChange("telDDD")}
						/>
						<TextField
							fullWidth
							size="small"
							label="Telefone"
							variant="outlined"
							placeholder="8654-2151"
							value={values?.tel}
							InputProps={{
								inputComponent: InputMask,
								inputProps: {
									mask: "9999-9999",
									maskChar: "",
								},
							}}
							InputLabelProps={{
								shrink: !!values?.tel,
							}}
							onChange={handleChange("tel")}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "15px",
						}}
					>
						<TextField
							size="small"
							label="DDD"
							variant="outlined"
							placeholder="00"
							value={values?.celDDD}
							InputProps={{
								inputComponent: InputMask,
								inputProps: {
									mask: "99",
									maskChar: "",
								},
							}}
							InputLabelProps={{
								shrink: !!values?.celDDD,
							}}
							onChange={handleChange("celDDD")}
						/>
						<TextField
							fullWidth
							size="small"
							label="Celular"
							variant="outlined"
							placeholder="98654-2151"
							value={values?.cel}
							InputProps={{
								inputComponent: InputMask,
								inputProps: {
									mask: "99999-9999",
									maskChar: "",
								},
							}}
							InputLabelProps={{
								shrink: !!values?.cel,
							}}
							onChange={handleChange("cel")}
						/>
					</Box>
					<div className="row-button">
						<Button type="submit">
							{loading ? (<Loading />) : 'Atualizar'}
						</Button>
					</div>
				</form>
			</Content>
		</Container>
	);
}
