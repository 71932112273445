import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    justify-content: center;

    .back {
        background: white;
        border: 1px solid #1ea418;
        color: #1ea418;
    }

    /* form {
        /* width: 45%; */

    /* @media (max-width: 500px) {
        form {
            width: 80%;
        }
    }; */

    /* .form-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    } */
    /* .button-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    } */
    /* .column {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-bottom: 0.5rem;
        gap: 1rem;
    } */
    /* .equal-column > * {
        flex: 1;
    } */
    /* .row {
        display: flex;
        flex-direction: row !important;
        gap: 1rem;
    } */
    /* .account {
        max-width: 320px;
        display: grid;
        grid-template-columns: 60fr 40fr !important;
    } */
    /* .input {
        min-width: 20rem;
        max-width: 20rem;
        width: 100%;
        padding: 0.6rem;
        border-radius: 10px;
        outline: 0;
        border: 1px solid rgba(28, 28, 28, 0.25);

        font: var(--text-body);

        ::placeholder {
            font: var(--text-body);
        }
    } */
`;

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;

    button {
        width: 100%;
    }
`;

export const Column = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 0.5rem; */
    /* gap: 1rem; */
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;
