import styled from "styled-components";

export const Container = styled.section`
    background-color: #ffffff;
    border: 1px solid var(--border);
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: row;

    height: 100%;
    width: 100%;
`;
export const FormContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 100%;
    width: 100%;
    height: 100%;
    .header-form {
        display: flex;
        align-items: center;
        gap: 2rem;
    }
    .input-select {
        width: 250px;
        padding: 10px;
        border-radius: 10px;

        border: 1px solid rgba(28, 28, 28, 0.25);

        outline: none;

        font: var(--text-body);
    }
    h1 {
        font: var(--text-medium);
    }
`;

export const Divider = styled.div`
    border: 1px solid rgba(21, 21, 21, 0.5);
    width: 100%;
    height: 1px;
    margin-top: 10px;
`;
