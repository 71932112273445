import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 900px;

    .rdw-editor-main {
        height: 150px;
        padding: 0 10px;
    }

    .rdw-editor-wrapper {
        border: 1px solid #f1f1f1;
    }

    .rdw-editor-toolbar {
        border: none;
        border-bottom: 1px solid #f1f1f1;
    }

    form {
        display: flex;
        flex-direction: column;
    }

    ul {
        list-style: decoration;
    }

    .button-content {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
    }
    .ck {
        max-width: 900px;
        max-height: 500px;
    }
    .ck-reset {
        max-height: 600px;
    }

    .ck-sticky-panel__content_sticky {
        margin-top: 4rem;
    }
`;
