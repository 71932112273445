import React from 'react'
import { Container } from './styles';

interface ICardsWrapper {
    children: JSX.Element | React.ReactNode;
    className: string
}

export function CardsWrapper(props: ICardsWrapper) {
    const { 
        children,
        className,
        ...others
    } = props

    return (
        <Container
            className={`cards-wrapper ${className}`}
            {...others}
        >
            {children}
        </Container>
    )
}

