import React from "react";
import {
	BiCalendar,
	BiDollar,
	BiDoughnutChart,
	BiRevision,
} from "react-icons/bi";
import { CardInfo } from "../../components/CardInfo";
import { AuthContainer } from "../Containers";
import {
	ButtonContainer,
	CardContent,
	Container,
	Content,
	Row,
	Select,
	SelectContainer,
	Wrapper,
} from "./styles";
import { CardsWrapper } from "../../components/CardsWrapper";
import { GraphicMonth } from "../../components/GraphicFinancialMonth";
import { FinancialSale } from "../../components/GraphicFinancialSale";
import { getSales, getGraphic } from "../../services/financial-service";
import { handleToast } from "../../components/HandleToast";
import { Table } from "../../components/Table";
import { getBalance, getReceiptExtract } from "../../services/finance-service";
import { getCSV } from "../../services/sales-service";
import { Button } from "../../components/StyledComponents";
import {
	formatDateMax,
	formatDateMin,
} from "../../utils/functions/FormatDates";
import moment from "moment";
import { useAppSelector } from "../../store/hooks";

interface IProps {}

const header = [
	"#",
	"Produtos",
	"Quantidade",
	"Criado em:",
	"Parcelas (R$)",
	"Valores das parcelas",
	"Usuário",
	"Status",
	"Total",
];

const columns = [
	"products",
	"amount",
	"createdAt",
	"installments",
	"installmentValue",
	"user",
	"status",
	"total",
];

export function Financial(props: IProps) {
	const { data } = useAppSelector((state) => state.user);

	const [loading, setLoading] = React.useState(true);
	const [sales, setSales] = React.useState([]);
	const [balance, setBalance] = React.useState(null);
	const [extract, setExtract] = React.useState(null);

	const [page, setPage] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [isDisabled, setDisabled] = React.useState(false);
	const [buttonColor, setButtonColor] = React.useState("#1ea418");
	const [endDate, setEndDate] = React.useState(formatDateMax());
	const [startDate, setStartDate] = React.useState(formatDateMax());
	const [monthGraphic, setMonthGraphic] = React.useState([]);
	const [categoryGraphic, setCategoryGraphic] = React.useState([]);
	const [somaTotal, setSomaTotal] = React.useState(0);
	const [somaSaleValue, setSomaSaleValue] = React.useState(0);
	const _id = data.organization?._id;
	let total = [];
	let salesValue = [];
	let somaValue = 0;
	let somaSale = 0;

	const graphic = async () => {
		let data = {
			startDate: moment(startDate).format("YYYY-MM-DD"),
			endDate: moment(endDate).format("YYYY-MM-DD"),
		};
		let response = await getGraphic(_id, data);
		setCategoryGraphic(response.data.items?.salesByCategory);
		setMonthGraphic(response.data.items?.salesByDate);
	};

	const getSalesData = async () => {
		setLoading(true);
		const response = await getSales(_id, {
			page,
			rowsPerPage,
			startDate,
			endDate,
		});
		if (!response.error) {
			let _sales = response.data?.items ? response.data?.items : [];
			setSales(_sales);
			_sales?.forEach((resp) => {
				total.push(resp.amount);
				salesValue.push(resp.total);
			});

			for (let i = 0; i < total.length; i++) {
				somaValue += total[i];
			}
			for (let i = 0; i < salesValue.length; i++) {
				somaSale += salesValue[i];
			}
			setSomaTotal(somaValue);
			setSomaSaleValue(somaSale);
			return setLoading(false);
		}
		setLoading(false);
		return handleToast(response.error, response.message);
	};

	const getBalanceData = async () => {
		setLoading(true);
		const response = await getBalance();
		if (!response.error) {
			setBalance(response.data.saldo);
			return setLoading(false);
		} else {
			handleToast(response.error, response.message);
		}
		setLoading(false);
		return handleToast(response.error, response.message);
	};

	const getExtract = async () => {
		setLoading(true);
		const response = await getReceiptExtract();
		if (!response.error) {
			setExtract(response.data.extrato);
			return setLoading(false);
		}
		setLoading(false);
		return handleToast(response.error, response.message);
	};

	const handleChangeRowsPerPage = (rows: string, page: number) => {
		setRowsPerPage(parseInt(rows));
		setPage(page);
	};

	const onStartDateChange = (e) => {
		try {
			if (startDate > endDate) {
				setDisabled(true);
				setButtonColor("#807e7e");
				handleToast(
					true,
					`A data selecionada é inválida. Data final não pode ser maior ou igual que a inicial.`
				);
			} else {
				setStartDate(e.target.value);
				setButtonColor("#1ea418");
			}
			setStartDate(e.target.value);
		} finally {
			graphic();
		}
	};

	const onEndDateChange = (e) => {
		try {
			if (e.target.value <= startDate) {
				setDisabled(true);
				setButtonColor("#807e7e");
				handleToast(
					true,
					`A data selecionada é inválida. Data final não pode ser maior ou igual que a inicial.`
				);
			} else {
				setEndDate(e.target.value);
				setButtonColor("#1ea418");
			}
		} finally {
			graphic();
		}
	};

	async function postCSV() {
		const orgId = data.organization?._id;

		const response = await getCSV(orgId, { startDate, endDate });
		if (!response.error) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			a.href = window.URL.createObjectURL(
				new Blob([response], { type: "application/vnd.ms-excel" })
			);
			a.download = `${orgId}.csv`;
			a.target = "_blank";
			a.click();
		}
		return handleToast(response.error, response.message);
	}

	React.useEffect(() => {
		getExtract();
		getBalanceData();
		getSalesData();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthContainer pageTitle="Financeiro" loading={loading}>
			<Content>
				<CardContent>
					<CardsWrapper className={"financial"}>
						<CardInfo
							color="blue"
							info="Total"
							value={somaTotal}
							label="Vendas do mês"
							subtitle="Atualizado agora"
							icon={<BiDoughnutChart />}
							subtitleIcon={<BiRevision />}
						/>
						<CardInfo
							color="green"
							info="Transferencias"
							value={somaSaleValue}
							label="Vendas"
							subtitle="Saldo total das vendas"
							money
							icon={<BiDollar />}
							subtitleIcon={<BiCalendar />}
						/>
						<CardInfo
							color="green"
							info="Transferencias"
							value={balance?.balance || 0}
							label="Saldo"
							subtitle="Saldo liberado"
							money
							icon={<BiDollar />}
							subtitleIcon={<BiCalendar />}
						/>
						<CardInfo
							color="orange"
							info="Transferencias"
							value={extract?.income || 0}
							label="Saldo"
							subtitle="Saldo a receber"
							money
							icon={<BiDollar />}
							subtitleIcon={<BiCalendar />}
						/>
					</CardsWrapper>
				</CardContent>
				<SelectContainer>
					<Select>
						<label>Data inicial:</label>
						<input
							type="date"
							value={startDate}
							onChange={onStartDateChange}
							max={endDate}
							min={formatDateMin(endDate)}
						/>
					</Select>
					<Select>
						<label>Data final:</label>
						<input
							type="date"
							value={endDate}
							onChange={onEndDateChange}
							max={formatDateMax()}
							min={startDate}
						/>
					</Select>
					<ButtonContainer>
						<Button
							onClick={postCSV}
							disabled={isDisabled || !startDate || !endDate}
							backgroundColor={buttonColor}
						>
							Exportar .xlsx
						</Button>
					</ButtonContainer>
				</SelectContainer>
				<Wrapper>
					<Container>
						<Row>
							<GraphicMonth data={monthGraphic} />
						</Row>
					</Container>
					<Container>
						<Row>
							<FinancialSale data={categoryGraphic} />
						</Row>
					</Container>
				</Wrapper>
				<Table
					heading="Vendas"
					columns={columns}
					header={header}
					items={sales}
					onPageChange={(page) => setPage(page)}
					onRowsPerPageChange={(rows, page) =>
						handleChangeRowsPerPage(rows, page)
					}
				></Table>
			</Content>
		</AuthContainer>
	);
}
