import { TextField } from "@mui/material";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { organizationUpdate } from "../../../services/organizationUpload-service";
import { PropsCompany } from "../../../store/user";
import validateCPF from "../../../utils/functions/ValidateCPF";
import { handleToast } from "../../HandleToast";
import { Button } from "../../StyledComponents";
import { FormContainer } from "../Product/styles";
import { FormStep } from "../User/FormUserSteps/styles";

type Props = {
    nextStep: () => void;
    backStep?: () => void;
    company?: PropsCompany;
    loading?: boolean;
};

interface CustomProps {
    mask: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { mask, ...other } = props;

        return <ReactInputMask mask={mask} {...other} />;
    }
);

export default function FormResponsible(props: Props) {
    const { nextStep, backStep, company } = props;

    const [loading, setLoading] = React.useState(false);

    const [values, setValues] = React.useState({
        responsable: company?.organization?.responsable || "",
        responsableCpf:
            company?.organization?.responsableCpf?.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4"
            ) || "",
        responsableRG:
            company?.organization?.responsableRG?.replace(
                /(\d{2})(\d{3})(\d{3})(\d{1})$/,
                "$1.$2.$3-$4"
            ) || "",
        registrationStep: "",
    });

    const {
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleChange =
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: (event.target as HTMLInputElement).value,
            });
        };

    const registerResponsible = useCallback(async () => {
        setLoading(true);

        if (!validateCPF(values.responsableCpf)) {
            setLoading(false);
            return handleToast(true, "CPF inválido");
        }
        if (!values.responsableRG) {
            setLoading(true);
            return handleToast(true, "Entre com um RG");
        }
        if (!values.responsable) {
            setLoading(true);
            return handleToast(true, "Entre com um responsável");
        }

        try {
            const response = await organizationUpdate(
                company?.organization?._id,
                values
            );
            if (!response?.error) {
                return nextStep();
            } else {
                return handleToast(true, "Ocorreu um erro");
            }
        } catch (e) {
            return handleToast(true, "Ocorreu um erro");
        } finally {
            setLoading(false);
        }
    }, [company?.organization?._id, nextStep, values]);

    return (
        <FormContainer>
            <FormStep onSubmit={handleSubmit(registerResponsible)}>
                <div className="column fullwidth">
                    <TextField
                        label="Nome do responsável"
                        value={values?.responsable}
                        required
                        onChange={handleChange("responsable")}
                        sx={{
                            display: "flex",
                            flex: 1,
                            marginBottom: "1rem",
                        }}
                        size="small"
                    />
                    <TextField
                        label="CPF"
                        value={values?.responsableCpf}
                        onChange={handleChange("responsableCpf")}
                        required
                        error={!!errors?.responsableCpf?.message}
                        InputProps={{
                            inputComponent: TextMaskCustom as any,
                            inputProps: {
                                mask: "999.999.999-99",
                                alwaysShowMask: false,
                                maskChar: "",
                                onChange: handleChange("responsableCpf"),
                                value: values?.responsableCpf,
                            },
                        }}
                        helperText={errors?.responsableCpf?.message?.toString()}
                        sx={{
                            display: "flex",
                            flex: 1,
                            width: "100%",
                            marginBottom: "1rem",
                        }}
                        size="small"
                    />
                    <TextField
                        label="RG"
                        value={values?.responsableRG}
                        required
                        onChange={handleChange("responsableRG")}
                        InputProps={{
                            inputComponent: TextMaskCustom as any,
                            inputProps: {
                                mask: "99.999.999-9",
                                alwaysShowMask: false,
                                maskChar: "",
                                value: values?.responsableRG,
                                onChange: handleChange("responsableRG"),
                            },
                        }}
                        sx={{
                            display: "flex",
                            flex: 1,
                            marginBottom: "1rem",
                        }}
                        size="small"
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "15px",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        onClick={() => backStep()}
                        disabled={loading}
                        outline
                    >
                        Voltar
                    </Button>
                    <Button disabled={loading} type="submit">
                        {loading ? "Enviando..." : "Próximo"}
                    </Button>
                </div>
            </FormStep>
        </FormContainer>
    );
}
