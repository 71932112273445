import React from "react";

import { AsideStyle } from "./styles";
import { Background, Divider } from "../../styles/global";
import "./styles.css";
import logo from "../../assets/images/logo.png";
import { Container, Content } from "./styles";

interface Props {
	children: React.ReactNode | JSX.Element;
}

export default function UnAuthContainer(props: Props) {
	var { children } = props;

	return (
		<Container className="container">
			<AsideStyle>
				<Background>
					<img src={logo} alt="Logo da empresa" />
				</Background>
				<Divider className="divider" />
			</AsideStyle>
			<Content className="children-container">{children}</Content>
		</Container>
	);
}
