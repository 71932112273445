import api from '.'

export async function getBalance() {
    const response = await api.get(`/admin/get-balance`)
    return response.data
}

export async function getExtract() {
    const response = await api.get(`/admin/get-extract`)
    return response.data
}

export async function getReceiptExtract() {
    const response = await api.get(`/admin/get-split`)
    return response.data
}