import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Left = styled.div`
	.user {
		display: flex;
		gap: 10px;
		margin-bottom: 5px;

		span {
			font-size: 14px;
			font-weight: 700;
		}
	}
`;
