import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`;
export const FormProduct = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .row {
        display: flex;
        flex-direction: row;
    }

    .input-div {
        display: flex;
        flex-direction: column;
        margin: 0.5rem;
    }
    .input {
        padding: 10px;
        border-radius: 10px;
        border: none;
        outline: none;
        background-color: rgba(21, 21, 21, 0.25);
        font: var(--text-body);
    }
    .content-button {
        display: flex;
        gap: 1rem;
        justify-content: end;
        align-items: center;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;
