import {
	TableCell,
	TextField,
	ButtonGroup,
	TableBody,
	TableContainer,
	Typography,
} from "@mui/material";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	/* gap: 2rem; */
	background-color: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const ProductDelete = styled(AiFillDelete)`
	margin: 2px;
	cursor: pointer;
	color: red;
`;

export const ProductEdit = styled(FaEdit)`
	margin: 2px;
	cursor: pointer;
	color: green;
`;

export const Add = styled(IoMdAdd)`
	color: #fff;
	font-size: 16px;
`;

export const Divider = styled.div`
	width: 1px;
	height: 30px;
	border: 1px solid rgba(255, 255, 255, 0.6);
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;

	.button-header {
		display: flex;
		gap: 20px;

		button {
			display: flex;
			border-radius: 2rem;
			align-items: center;
			border: 0;
			padding: 10px;
			background-color: var(--primary-green);
			color: var(--primary-white);
			cursor: pointer;
		}
	}

	h1 {
		padding: 0;
		margin: 0;
	}
`;

export const PTable = styled.table`
	/* max-width: 100%; */
	min-width: 800px;
	border-collapse: collapse;

	th {
		text-align: center;
		padding: 10px;
		background-color: var(--primary-white);
		/* position: absolute; */
	}

	tbody {
		tr {
			text-align: left;
			border-top: 1px solid rgba(21, 21, 21, 0.3);
			:hover {
				background-color: rgba(21, 21, 21, 0.1);
			}
			td {
				height: 72px;
				text-align: left;
				img {
					max-width: 7vh;
				}
			}
		}
	}

	@media (max-width: 1100px) {
		min-width: 500px;
	}
`;

export const ButtonGroupWrapper = styled(ButtonGroup)`
	display: flex;
	flex-direction: row;
	gap: 0.8rem;
`;

export const InputField = styled(TextField)`
	.MuiInputBase-root {
		border: none !important;
		background-color: transparent !important;
	}
`;

export const TableImageCell = styled(TableCell)`
	object-fit: fill;
	width: 10vw;
	img {
		max-width: 15vw;
	}
`;

export const TBody = styled(TableBody)`
	max-width: 100%;
`;

export const TContainer = styled(TableContainer)`
	max-width: 100%;
	height: 500px;
`;

export const TableWrapper = styled.section`
	display: flex;
	flex-direction: column;
	max-width: 100%;
	gap: 2rem;
	background-color: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const Row = styled.section`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;
	gap: 10px;
`;

export const InputContainer = styled.section``;

export const Column = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
`;
export const Title = styled(Typography)`
	font-size: 22px !important;
	font-weight: 300 !important;
	color: #333 !important;
	line-height: 30px !important;
`;
