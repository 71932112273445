import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";

import {
	Container,
	ContainerButton,
	Content,
	PrivacityContainer,
	UserDefault,
	ContentWrapper,
	ContentProfilerWrapper,
	ImageWrapper,
	RatingWrapper,
	ImageButton,
	ModalWrapper,
} from "./styles";
import { updateSeller } from "../../services/user-services";
import { UpdatePassword } from "../../components/UpdatePassword";
import { UserProfile } from "../../components/UserProfile";
import { imageUpload } from "../../services/user-services";
import { AuthContainer } from "../Containers";
import { Modal } from "../../components/Modal";
import { Button } from "../../components/StyledComponents";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { handleToast } from "../../components/HandleToast";
import { DeleteAccount } from "../../components/Tabs/DeleteAccount";
import TextRating from "../../components/TextRating";
import { getUserDataAsync } from "../../store/user/model";
import { Loading } from "../../components/Loading";
import { CompanyProfile } from "../../components/CompanyProfile";

export function Profile() {
	const dispatch = useAppDispatch();

	const { data, loading } = useAppSelector((state) => state.user);

	const [imagem, setImagem] = useState<any>("");
	const [modal, setModal] = useState(false);
	const [tab, setTab] = useState<string>("personal");

	const id = "modal";

	const handleGetUserData = useCallback(() => {
		dispatch(getUserDataAsync());
	}, [dispatch]);

	useEffect(() => {
		handleGetUserData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (modal === false) {
			setImagem("");
		}
	}, [modal]);

	function handleOutSideClick(e: any) {
		if (e.target.id === id) setModal(false);
	}

	function onFileChange(e: any) {
		if (e.target && e.target.files[0]) {
			setImagem(e.target.files[0]);
		}
	}

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue);
	};

	async function handleChange(_data: any) {
		_data.preventDefault();
		try {
			const formData = new FormData();
			formData.append("file", imagem);
			const response = await imageUpload(formData);

			if (imagem) {
				if (!response.error) {
					const obj = {
						...response.data,
					};

					const update = await updateSeller(data.user._id, obj);

					if (!update.error) {
						handleToast(response.error, response.message);
						return setModal(false);
					}
					return handleToast(true, "Erro ao carregar imagem");
				}

				return handleToast(response.error, response.message);
			}
		} catch (error) {
			handleToast(true, error);
		}
	}

	const renderImage = useMemo(() => {
		if (data?.user?.image64)
			return (
				<img
					src={`data:${data?.user?.mimetype};base64,${data?.user?.image64}`}
					alt=""
				/>
			);

		return <UserDefault />;
	}, [data]);

	const renderFormImage = useMemo(() => {
		if (imagem) return <img src={URL.createObjectURL(imagem)} alt="" />;

		if (data?.user?.image64)
			return (
				<img
					src={`data:${data?.user?.mimetype};base64,${data?.user?.image64}`}
					alt=""
				/>
			);

		return <UserDefault />;
	}, [data, imagem]);

	return (
		<AuthContainer pageTitle="Perfil">
			<Container>
				<Content>
					<ContentProfilerWrapper>
						<ImageWrapper>{renderImage}</ImageWrapper>
						<RatingWrapper>
							<h3>Nível do perfil:</h3>
							<TextRating value={(data?.organization as any)?.rating || 0} />
						</RatingWrapper>
						<div className="button-column">
							<Button onClick={() => setModal(true)}>Alterar foto</Button>
							<UpdatePassword user={data} />
						</div>
					</ContentProfilerWrapper>

					<TabContext value={tab}>
						<ContentWrapper>
							<Box
								sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
							>
								<TabList
									onChange={handleChangeTab}
									aria-label="dados do seller"
								>
									<Tab label="Dados pessoais" value="personal" />
									<Tab label="Loja" value="store" />
								</TabList>
							</Box>
							<TabPanel value="personal" sx={{ width: "100% " }}>
								<UserProfile />
							</TabPanel>
							<TabPanel value="store" sx={{ width: "100% " }}>
								<CompanyProfile />
								<PrivacityContainer>
									<h3>Privacidade</h3>
									<ContainerButton>
										<DeleteAccount />
									</ContainerButton>
								</PrivacityContainer>
							</TabPanel>
						</ContentWrapper>
					</TabContext>
				</Content>
			</Container>
			{modal && (
				<Modal
					id={id}
					onClick={handleOutSideClick}
					setState={setModal}
					title="Alterar imagem"
					width="500px"
				>
					<ModalWrapper>
						<form onSubmit={handleChange} className="form">
							<ImageWrapper className="form-image-wrapper">
								{renderFormImage}
							</ImageWrapper>
							<ImageButton htmlFor="image" className="button-image">
								<p>{imagem ? "Trocar imagem" : "Carregar imagem"}</p>
							</ImageButton>
							<input id="image" type="file" onChange={onFileChange} hidden />
							<div className="content-button">
								<Button type="button" onClick={() => setModal(false)} outline>
									Cancelar
								</Button>
								<Button type="submit">Enviar</Button>
							</div>
						</form>
					</ModalWrapper>
				</Modal>
			)}
			{loading && <Loading />}
		</AuthContainer>
	);
}
