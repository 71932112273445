import styled from "styled-components";

interface ComponentProps {
    className?: string;
}

export const Content = styled.section<ComponentProps>`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 100%;
    width: 100%;
    @media (max-width: 1000px) {
        .sales-products-cards-wrapper{
            max-width: 100%;
        }
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

export const Divider = styled.div`
    border-bottom: 1px solid var(--primary-blue);
    margin-bottom: 1rem;
    /* margin-top: 1rem; */
    h1 {
        font: var(--text-title);
        color: var(--primary-blue);
    }
`;

export const DrawerContent = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 450px;
    padding: 20px;
    overflow-x:hidden;

    gap: 15px;

    li {
        width: 100%;
        list-style-type: none;
    }
`;

export const LowCard = styled.section`
    display: flex;
    /* flex-direction: column; */
    max-width: 100%;

    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 100%;
    }
`;