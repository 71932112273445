import { Autocomplete, TextField } from "@mui/material";
import React from "react";

interface IOption {
	label: string;
	value: string | number | Date | boolean;
}

interface Props {
	options?: IOption[];
	label?: string;
	size?: "small" | "medium";
	variant?: "outlined" | "filled";
	fullWidth?: boolean;
	autoComplete?: boolean;
	autoSelect?: boolean;
	filter?: any;
	value?: any;
	className?: string;
	onChange?: (event: React.SyntheticEvent, value: any | Array<any>) => void;
	onInputChange?: (
		event: React.SyntheticEvent,
		value: any | Array<any>
	) => void;
}

export const SelectCustom = React.forwardRef<HTMLElement, Props>(
	function SelectCustom(props, ref) {
		const {
			options,
			label,
			size,
			fullWidth,
			variant,
			autoComplete,
			autoSelect,
			className,
			value,
			onChange,
			onInputChange,
		} = props;

		function handleIsOptionEqualToValue(option, value) {
			return option.label === value;
		}

		const handleChange = React.useCallback(
			(event: React.SyntheticEvent, value: any) => {
				if (onChange) {
					return onChange(event, value);
				} else {
					return null;
				}
			},
			[onChange]
		);

		const handleInputChange = React.useCallback(
			(event: React.SyntheticEvent, value: any) => {
				if (onInputChange) {
					return onInputChange(event, value);
				} else {
					return null;
				}
			},
			[onInputChange]
		);

		return (
			<Autocomplete
				value={value}
				className={className}
				autoSelect={autoSelect}
				autoComplete={autoComplete}
				options={options}
				size={size ? size : "medium"}
				fullWidth={fullWidth}
				onInputChange={(e, v) => handleInputChange(e, v)}
				// disableClearable
				onChange={(e, v) => handleChange(e, v)}
				getOptionLabel={(option) => option?.label || ""}
				isOptionEqualToValue={handleIsOptionEqualToValue}
				renderInput={(params) => (
					<TextField {...params} label={label} variant={variant} />
				)}
			/>
		);
	}
);
