import styled from "styled-components";

interface ContainerProps {
	primary?: boolean;
	backgroundColor?: string;
	color?: string;
	outline?: boolean;
	type?: string;
	onClick?: () => void;
	width?: string;
	height?: string;
}

export const Container = styled.button<ContainerProps>`
	width: ${(props) => (props.width ? props.width : "130px")};
	height: ${(props) => (props.height ? props.height : "40px")};
	font: var(--text-body);
	background-color: ${(props) =>
		props.outline
			? "#FFF"
			: props.backgroundColor
			? props.backgroundColor
			: "#1ea418"};
	color: ${(props) =>
		props.outline ? "#1ea418" : props.color ? props.color : "#FFFFFF"};

	cursor: pointer;
	outline: 0;
	border: ${(props) => (props.outline ? "1px solid #1ea418" : 0)};
	opacity: 0.85;
	border-radius: 4px;
	transition: opacity 0.3s;

	:disabled {
		opacity: 0.5;
	}
`;
