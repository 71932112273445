import React, { ReactNode, memo, useCallback, useEffect } from "react";
import ReactLoading from "react-loading";
import { FooterC } from "../../components/Footer";
import { Header } from "../../components/Header";
import { SideNav } from "../../components/SideNav";
import { Content, MainContainer } from "../../components/StyledComponents";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { LoadingWrapper } from "./styles";
import { useNavigate } from "react-router";
import { getUserDataAsync } from "../../store/user/model";

type Props = {
	children: ReactNode | null;
	pageTitle?: string | "";
	loading?: true | false;
};

function AuthContainer(props: Props) {
	const dispatch = useAppDispatch();
	const { data } = useAppSelector((state) => state.user);
	const navigate = useNavigate();

	var { children, pageTitle, loading } = props;

	const [styleHeader] = React.useState<boolean>(false);

	const handleUser = useCallback(async () => {
		dispatch(getUserDataAsync());
	}, [dispatch]);

	useEffect(() => {
		handleUser();
	}, [handleUser]);

	useEffect(() => {
		if (data?.organization?.status === "awaiting") {
			navigate("/dashboard");
		}
	}, [data, navigate]);

	return (
		<MainContainer>
			{data && <SideNav data={data} />}
			<Content className="content">
				<Header scroll={styleHeader} title={pageTitle} user={data?.user} />
				{children}
				<FooterC />
			</Content>
			{loading && (
				<LoadingWrapper className="loading-wrapper">
					<ReactLoading
						type="bars"
						width="10%"
						height="10%"
						color="var(--primary-green)"
						className="loading"
					/>
				</LoadingWrapper>
			)}
		</MainContainer>
	);
}

export default memo(AuthContainer);
