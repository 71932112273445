import React from "react";
import * as dateFns from "date-fns";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { ptBR } from "date-fns/esm/locale";
import moment from "moment";

import { CalendarContent } from "./styles";

moment.locale("pt-Br");

interface Props {
    onCellClick?: (date: string) => void;
}

function Calendar(props: Props) {
    const { onCellClick } = props;

    const [currentMonth, setCurrentMonth] = React.useState(new Date());
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    React.useEffect(() => {
        setCurrentMonth(new Date());
    }, []);

    const renderHeader = () => {
        const monthFormat = "MMMM";
        const yearFormat = "yyyy";

        return (
            <div className="header row row-middle">
                <div className="col col-start">
                    <div className="icon" onClick={prevMonth}>
                        <MdChevronLeft />
                    </div>
                </div>
                <div className="col col-center-header">
                    <div>
                        {dateFns.format(currentMonth, monthFormat, {
                            locale: ptBR,
                        })}
                    </div>
                    <div>
                        {dateFns.format(currentMonth, yearFormat, {
                            locale: ptBR,
                        })}
                    </div>
                </div>
                <div className="col col-end">
                    <div className="icon" onClick={nextMonth}>
                        <MdChevronRight />
                    </div>
                </div>
            </div>
        );
    };

    const renderDays = () => {
        const days = [];

        let startDate = dateFns.startOfWeek(currentMonth, { locale: ptBR });

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {/* {dateFns.format(dateFns.addDays(startDate, i), dateFormat, { locale: ptBR })} */}
                    {moment(startDate).add(i, "day").format("ddd")}
                </div>
            );
        }

        return <div className="days row">{days}</div>;
    };

    const renderCells = () => {
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart);
        const endDate = dateFns.endOfWeek(monthEnd);

        const dateFormat = "dd";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`col cell ${
                            !dateFns.isSameMonth(day, monthStart)
                                ? "disabled"
                                : dateFns.isSameDay(day, selectedDate)
                                ? "selected"
                                : ""
                        }`}
                        key={day.toString()}
                        onClick={() => onDateClicked(cloneDay)}
                    >
                        <span className="number">{formattedDate}</span>
                        {/* <span className='bg'>{8}</span> */}
                    </div>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day.toString()}>
                    {days}
                </div>
            );
            days = [];
        }

        return <div className="body">{rows}</div>;
    };

    const onDateClicked = (date: Date) => {
        setSelectedDate(date);
        let returnDate = dateFns.format(date, "yyyy-MM-dd", { locale: ptBR });
        onCellClick(returnDate);
    };

    const nextMonth = () => {
        setCurrentMonth(dateFns.addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(dateFns.subMonths(currentMonth, 1));
    };

    return (
        <CalendarContent>
            <div className="calendar">
                {renderHeader()}
                {renderDays()}
                {renderCells()}
            </div>
        </CalendarContent>
    );
}

export default Calendar;
