import { Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    /* max-width: 100%; */
    width: 100%;
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid var(--border);
`;

export const Content = styled.section`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 100%;
    width: 100%;
    @media (max-width: 1000px) {
        .financial{
            max-width: 100%;
        }
    }
`;

export const CardContent = styled.div`
    display: block;
    width: 100%;
`;

export const Row = styled.section`
    display: flex;
    /* flex: 1; */
    flex-direction: row;
    /* flex-wrap: wrap; */
    min-width: 100%;
    /* gap: 5px; */
`;

export const Column = styled.section`
    flex-direction: column;
`;

export const Title = styled(Typography)`
    font-size: 22px !important;
    font-weight: 300 !important;
    color: #333 !important;
    line-height: 30px !important;
`;

export const Subtitle = styled(Typography)`
    font-size: 14px !important;
    color: #a9a9a9;
    font-weight: 400 !important;
    line-height: 30px !important;
`;

export const Wrapper = styled.section`
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-bottom: 1rem;
    gap: 10px;

    @media (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
`;

export const SelectContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
    gap: 1rem;
    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
`;
export const Select = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 15px;

    input {
        width: 120px;
        border: none;
        height: 45px;
        padding: 5px;
        box-sizing: border-box;
        outline: none;
        cursor: pointer;
    }
    label {
        width: 100%;
        text-align: left;
        font-size: 12px;
        gap: 1rem;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
