import api from ".";

export async function listCategory(params: object = null) {
    const response = await api.get(`/category`, { params });
    return response.data;
}
export async function listServices() {
    const response = await api.get("/service");
    return response.data;
}

export async function updateCategorie(id: any, data: any) {
    const response = await api.put(`/category/${id}`, data);
    return response.data;
}
