import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Container, ContainerWrapper } from "./styles";
import ReactInputMask from "react-input-mask";
import { Button } from "../../StyledComponents";

import { PropsSettings } from "../../../utils/types/settings";
import { handleToast } from "../../HandleToast";
import {
	createWorkDay,
	updateWorkDay,
} from "../../../services/work-days.services";
import { createOrganizationSettings } from "../../../services/organization-settings.service";
import { TextField } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";

type Props = {
	settings?: PropsSettings[];
	onUpdate: () => void;
};

export function WorkDays({ settings }: Props) {
	const { handleSubmit, setValue } = useForm();
	const { data } = useAppSelector((state) => state.user);
	const [config] = settings;
	const [values, setValues] = useState<any>({
		hourlySchedules: "",
		startTimeMonday: "",
		endTimeMonday: "",
		startTimeTuesday: "",
		endTimeTuesday: "",
		startTimeWednesday: "",
		endTimeWednesday: "",
		startTimeThursday: "",
		endTimeThursday: "",
		startTimeFriday: "",
		endTimeFriday: "",
		startTimeSaturday: "",
		endTimeSaturday: "",
		startTimeSunday: "",
		endTimeSunday: "",
	});

	useEffect(() => {
		if (config) {
			setValues((prevState) => ({
				...prevState,
				hourlySchedules: config?.simultaneousAppointments.toString(),
				startTimeMonday: config?.open[0]?.openClock,
				endTimeMonday: config?.open[0]?.closeClock,
				startTimeTuesday: config?.open[1]?.openClock,
				endTimeTuesday: config?.open[1]?.closeClock,
				startTimeWednesday: config?.open[2]?.openClock,
				endTimeWednesday: config?.open[2]?.closeClock,
				startTimeThursday: config?.open[3]?.openClock,
				endTimeThursday: config?.open[3]?.closeClock,
				startTimeFriday: config?.open[4]?.openClock,
				endTimeFriday: config?.open[4]?.closeClock,
				startTimeSaturday: config?.open[5]?.openClock,
				endTimeSaturday: config?.open[5]?.closeClock,
				startTimeSunday: config?.open[6]?.openClock,
				endTimeSunday: config?.open[6]?.closeClock,
			}));
		}
	}, [config]);

	const handleChange = (property: string) => (event: any) => {
		setValues({ ...values, [property]: event.target.value });
	};

	const handleValues = useCallback(() => {
		config?.open.map((item) => {
			let d = capitalizeFirstLetter(item.dayOfWeek);

			setValue(`startTime${d}`, item.openClock);
			setValue(`endTime${d}`, item.closeClock);
			return setValue(d, item.dayOfWeek);
		});
	}, [config?.open, setValue]);

	useEffect(() => {
		if (!config) {
			handleValues();
		}
	}, [handleValues, config]);

	function capitalizeFirstLetter(string) {
		return string?.charAt(0)?.toUpperCase() + string?.slice(1);
	}

	const testTime = (str: string) => str.match(/([01]?[0-9]|2[0-3]):[0-5][0-9]/);

	async function handleValidate(_data) {
		_data = { ...values };

		if (_data.startTimeMonday) {
			if (
				_data.startTimeMonday &&
				_data.endTimeMonday &&
				testTime(_data.startTimeMonday) &&
				testTime(_data.endTimeMonday)
			) {
				let objMonday = {
					dayOfWeek: "monday",
					openClock: _data.startTimeMonday,
					closeClock: _data.endTimeMonday,
					_id: config?.open[0]?._id,
				};

				_data.monday = objMonday;
			} else {
				return handleToast(true, "Informe o horário de começo e término");
			}
		} else {
			let objMonday = {
				dayOfWeek: "monday",
				openClock: "00:00",
				closeClock: "00:00",
				_id: config?.open[0]?._id,
			};

			_data.monday = objMonday;
		}
		if (_data.startTimeTuesday) {
			if (
				_data.startTimeTuesday &&
				_data.endTimeTuesday !== "" &&
				testTime(_data.startTimeTuesday) &&
				testTime(_data.endTimeTuesday)
			) {
				let objTuesday = {
					dayOfWeek: "tuesday",
					openClock: _data.startTimeTuesday,
					closeClock: _data.endTimeTuesday,
					_id: config?.open[1]?._id,
				};
				_data.tuesday = objTuesday;
			} else {
				return handleToast(true, "Informe o horário de começo e término");
			}
		} else {
			let objTuesday = {
				dayOfWeek: "tuesday",
				openClock: "00:00",
				closeClock: "00:00",
				_id: config?.open[1]?._id,
			};

			_data.tuesday = objTuesday;
		}
		if (_data.startTimeWednesday) {
			if (
				_data.startTimeWednesday &&
				_data.endTimeWednesday !== "" &&
				testTime(_data.startTimeWednesday) &&
				testTime(_data.endTimeWednesday)
			) {
				let objWednesday = {
					dayOfWeek: "wednesday",
					openClock: _data.startTimeWednesday,
					closeClock: _data.endTimeWednesday,
					_id: config?.open[2]?._id,
				};
				_data.wednesday = objWednesday;
			} else {
				return handleToast(true, "Informe o horário de começo e término");
			}
		} else {
			let objWednesday = {
				dayOfWeek: "wednesday",
				openClock: "00:00",
				closeClock: "00:00",
				_id: config?.open[2]?._id,
			};
			_data.wednesday = objWednesday;
		}
		if (_data.startTimeThursday) {
			if (
				_data.startTimeThursday &&
				_data.endTimeThursday !== "" &&
				testTime(_data.startTimeThursday) &&
				testTime(_data.endTimeThursday)
			) {
				let objThursday = {
					dayOfWeek: "thursday",
					openClock: _data.startTimeThursday,
					closeClock: _data.endTimeThursday,
					_id: config?.open[3]?._id,
				};
				_data.thursday = objThursday;
			} else {
				return handleToast(true, "Informe o horário de começo e término");
			}
		} else {
			let objThursday = {
				dayOfWeek: "thursday",
				openClock: "00:00",
				closeClock: "00:00",
				_id: config?.open[3]?._id,
			};
			_data.thursday = objThursday;
		}
		if (_data.startTimeFriday) {
			if (
				_data.startTimeFriday &&
				_data.endTimeFriday !== "" &&
				testTime(_data.startTimeFriday) &&
				testTime(_data.endTimeFriday)
			) {
				let objFriday = {
					dayOfWeek: "friday",
					openClock: _data.startTimeFriday,
					closeClock: _data.endTimeFriday,
					_id: config?.open[4]?._id,
				};
				_data.friday = objFriday;
			} else {
				return handleToast(true, "Informe o horário de começo e término");
			}
		} else {
			let objFriday = {
				dayOfWeek: "friday",
				openClock: "00:00",
				closeClock: "00:00",
				_id: config?.open[4]?._id,
			};
			_data.friday = objFriday;
		}
		if (_data.startTimeSaturday) {
			if (
				_data.startTimeSaturday &&
				_data.endTimeSaturday !== "" &&
				testTime(_data.startTimeSaturday) &&
				testTime(_data.endTimeSaturday)
			) {
				let objSaturday = {
					dayOfWeek: "saturday",
					openClock: _data.startTimeSaturday,
					closeClock: _data.endTimeSaturday,
					_id: config?.open[5]?._id,
				};
				_data.saturday = objSaturday;
			} else {
				return handleToast(true, "Informe o horário de começo e término");
			}
		} else {
			let objSaturday = {
				dayOfWeek: "saturday",
				openClock: "00:00",
				closeClock: "00:00",
				_id: config?.open[5]?._id,
			};
			_data.saturday = objSaturday;
		}
		if (_data.startTimeSunday) {
			if (
				_data.startTimeSunday &&
				_data.endTimeSunday !== "" &&
				testTime(_data.startTimeSunday) &&
				testTime(_data.endTimeSunday)
			) {
				let objSunday = {
					dayOfWeek: "sunday",
					openClock: _data.startTimeSunday,
					closeClock: _data.endTimeSunday,
					_id: config?.open[6]?._id,
				};
				_data.sunday = objSunday;
			} else {
				return handleToast(true, "Informe o horário de começo e término");
			}
		} else {
			let objSunday = {
				dayOfWeek: "sunday",
				openClock: "00:00",
				closeClock: "00:00",
				_id: config?.open[6]?._id,
			};
			_data.sunday = objSunday;
		}
		if (_data.hourlySchedules !== "") {
			let value = _data.hourlySchedules;
			value = value.replace(/\D/g, "");
			value = parseInt(value);
			_data.hourlySchedules = value;
		} else {
			return handleToast(
				true,
				"Informe a quantidade de atendimento simultâneos"
			);
		}
		if (
			_data.monday.dayOfWeek === "monday" ||
			_data.tuesday.dayOfWeek === "tuesday" ||
			_data.wednesday.dayOfWeek === "wednesday" ||
			_data.thursday.dayOfWeek === "thursday" ||
			_data.friday.dayOfWeek === "friday" ||
			_data.saturday.dayOfWeek === "saturday" ||
			_data.sunday.dayOfWeek === "sunday"
		) {
			const objData = {
				open: [
					_data.monday,
					_data.tuesday,
					_data.wednesday,
					_data.thursday,
					_data.friday,
					_data.saturday,
					_data.sunday,
				],
			};
			return objData;
		} else {
			return handleToast(true, "Informe pelo menos 1 dia de trabalho");
		}
	}
	async function submit() {
		const days = await handleValidate({});
		if (days) {
			try {
				if (config?.open?.length) {
					await updateWorkDay(days, data?.organization?._id);
					handleToast(false, "Horário atualizado");
				} else {
					await createWorkDay(days, data?.organization?._id);
					handleToast(false, "Horário cadastrado");
				}
			} catch (e) {
				handleToast(true, "Não foi possível cadastrar.");
			}
		}
	}

	const submitHourlySchedules = async () => {
		if (values.hourlySchedules) {
			const simultaneousAppointments = values.hourlySchedules.replace(/_/g, "");
			const docs = {
				simultaneousAppointments: parseInt(simultaneousAppointments),
			};
			try {
				await createOrganizationSettings(docs, data?.organization?._id);
				handleToast(false, "Agendamentos simultâneos atualizados.");
			} catch (e) {
				handleToast(
					true,
					"Não foi possível atualizar agendamentos simultâneos."
				);
			}
		}
	};

	return (
		<ContainerWrapper>
			<Container>
				<div className="schedules">
					<form
						className="form-container-schedule"
						onSubmit={handleSubmit(submitHourlySchedules)}
					>
						<div>
							<h3>Agendamentos</h3>
						</div>
						<div className="row-simultaneous">
							<label>Agendamentos simultâneos</label>
							<TextField
								type="text"
								size="small"
								placeholder="0"
								onChange={handleChange("hourlySchedules")}
								value={values.hourlySchedules}
								InputProps={{
									inputComponent: ReactInputMask,
									inputProps: {
										mask: "999",
										maskChar: "",
									},
								}}
							/>
						</div>
						<div className="content-button-work-days">
							<Button type="submit">{config ? "Editar" : "Enviar"}</Button>
						</div>
					</form>
				</div>
			</Container>
			<Container>
				<form className="form-container" onSubmit={handleSubmit(submit)}>
					<div className="column">
						<div className="card">
							<h3
								style={{
									textAlign: "center",
									marginBottom: "20px",
								}}
							>
								Horários de funcionamento
							</h3>
							<div className="column">
								<div className="row">
									<div className="center">
										<label>Segunda</label>
										<div className="hours-row">
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Início"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("startTimeMonday")}
													value={values.startTimeMonday}
												/>
											</div>
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Término"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("endTimeMonday")}
													value={values.endTimeMonday}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="center">
										<label>Terça</label>
										<div className="hours-row">
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Início"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("startTimeTuesday")}
													value={values.startTimeTuesday}
												/>
											</div>
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Término"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("endTimeTuesday")}
													value={values.endTimeTuesday}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="center">
										<label>Quarta</label>
										<div className="hours-row">
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Início"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("startTimeWednesday")}
													value={values.startTimeWednesday}
												/>
											</div>
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Término"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("endTimeWednesday")}
													value={values.endTimeWednesday}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="center">
										<label>Quinta</label>
										<div className="hours-row">
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Início"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("startTimeThursday")}
													value={values.startTimeThursday}
												/>
											</div>
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Término"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("endTimeThursday")}
													value={values.endTimeThursday}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="center">
										<label>Sexta</label>
										<div className="hours-row">
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Início"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("startTimeFriday")}
													value={values.startTimeFriday}
												/>
											</div>
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Término"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("endTimeFriday")}
													value={values.endTimeFriday}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="center">
										<label>Sábado</label>
										<div className="hours-row">
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Início"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("startTimeSaturday")}
													value={values.startTimeSaturday}
												/>
											</div>
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Término"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("endTimeSaturday")}
													value={values.endTimeSaturday}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="center">
										<label>Domingo</label>
										<div className="hours-row">
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Início"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("startTimeSunday")}
													value={values.startTimeSunday}
												/>
											</div>
											<div>
												<TextField
													InputLabelProps={{ shrink: true }}
													type="text"
													placeholder="00:00"
													label="Término"
													size="small"
													variant="outlined"
													InputProps={{
														inputComponent: ReactInputMask,
														inputProps: {
															mask: "99:99",
														},
													}}
													onChange={handleChange("endTimeSunday")}
													value={values.endTimeSunday}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="column"></div>
							</div>
							<div className="content-button-work-days">
								<Button type="submit">{config ? "Editar" : "Enviar"}</Button>
							</div>
						</div>
					</div>
				</form>
			</Container>
		</ContainerWrapper>
	);
}
