import { useState } from "react";

import { HeaderStyle } from "./styles";
import { IoMdNotificationsOutline } from "react-icons/io";

import { useAppSelector } from "../../store/hooks";

import { PropsUser } from "../../store/user";
import api from "../../services";
import { handleToast } from "../HandleToast";

type UserProps = PropsUser;

type PropsScroll = {
	scroll?: boolean;
	title?: string;
	user: UserProps;
};

export function Header({ scroll = false, title = "Lubbri |" }: PropsScroll) {
	const [showNotifications, setShowNotifications] = useState<boolean>(false);
	const [readNotifications, setReadNotifications] = useState([]);
	const { data } = useAppSelector((state) => state.user);

	const id = "notification";

	function handleOutSideClick(e: any) {
		if (e.target.id === id) setShowNotifications(false);
	}

	const returnPageTitle = (title: string) => (
		<div className="content-title">
			<h1 className="title">Lubbri | {title}</h1>
		</div>
	);

	const dismissNotification = async (notificationId: string) => {
		try {
			await api.patch(
				`/organization/${data?.organization?._id}/change-status/${notificationId}`,
				{}
			);
			setReadNotifications([...readNotifications, notificationId]);
			return handleToast(false, "Notificação lida.");
		} catch (e) {
			handleToast(true, "Não foi possível marcar notificação como lida.");
		}
	};

	return (
		<HeaderStyle>
			{scroll === true ? (
				<>
					<div>
						<div>{returnPageTitle(title)}</div>

						<div className="icons">
							<div
								className="notification"
								onClick={() => setShowNotifications(true)}
							>
								{data?.organization?.notification?.filter(
									(item) => item?.isActive
								).length < 1 ? null : (
									<div className="notify">
										{data?.organization?.notification?.filter(
											(item) => item?.isActive
										).length > 9
											? "+9"
											: data?.organization?.notification?.filter(
													(item) => item?.isActive
											  ).length}
									</div>
								)}
								<IoMdNotificationsOutline />
							</div>
						</div>
					</div>
					{showNotifications &&
						!!data?.organization?.notification?.filter(
							(item) => item?.isActive && !readNotifications.includes(item._id)
						).length && (
							<div
								className="outSideNotification"
								id={id}
								onClick={handleOutSideClick}
							>
								<div className="newNotifications">
									{data?.organization?.notification?.map((notify) => {
										if (
											notify?.isActive &&
											!readNotifications.includes(notify._id)
										) {
											return (
												<div key={notify?._id} className="contentNotify">
													<h3>{notify?.title}</h3>
													<p>{notify?.content || "-"}</p>
												</div>
											);
										}
										return null;
									})}
								</div>
							</div>
						)}
				</>
			) : (
				<>
					<div>{returnPageTitle(title)}</div>
					<div>
						<div className="icons">
							<div
								className="notification"
								onClick={() => setShowNotifications(true)}
							>
								{data?.organization?.notification?.filter(
									(item) => item?.isActive
								).length < 1 ? null : (
									<div className="notify">
										{data?.organization?.notification?.filter(
											(item) => item?.isActive
										).length > 9
											? "+9"
											: data?.organization?.notification?.filter(
													(item) => item?.isActive
											  ).length}
									</div>
								)}
								<IoMdNotificationsOutline />
							</div>
						</div>
					</div>
					{showNotifications &&
						!!data?.organization?.notification?.filter(
							(item) => item?.isActive && !readNotifications.includes(item._id)
						)?.length && (
							<div
								className="outSideNotification"
								id={id}
								onClick={handleOutSideClick}
							>
								<div className="newNotifications">
									{data?.organization?.notification?.map((notify) => {
										if (
											notify?.isActive &&
											!readNotifications.includes(notify._id)
										) {
											return (
												<div
													key={notify?._id}
													className="contentNotify"
													onClick={() => dismissNotification(notify?._id)}
												>
													<h3>{notify?.title}</h3>
													<p>{notify?.content || "-"}</p>
												</div>
											);
										}
										return null;
									})}
								</div>
							</div>
						)}
				</>
			)}
		</HeaderStyle>
	);
}
