import styled from "styled-components";

export const Container = styled.section`
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 1rem;
    @media (max-width: 1100px) {
        max-width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
    }
`;
