import { Typography, Divider } from "@material-ui/core";
import styled from "styled-components";

interface IconWrapperProps {
	size?: number;
	color?: string;
}

export const Container = styled.aside`
	display: flex;
	flex-direction: column;
	/* flex: 1; */
	/* align-itens: center; */
	/* max-width: 50%; */
	max-width: 220px;
	width: 100%;
	// padding: 10px 15px;
	border: 1px solid var(--border);
	background-color: #ffffff;

	@media (max-width: 600px) {
		width: 100%;
		max-width: initial;
	}

	/* @media (min-width: 992px) {
        flex: 0 0 25%;
        max-width: 25%;
    } */
`;

export const Content = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 15px 0;
	min-height: 1rem;
`;

export const Footer = styled.section`
	padding: 0 15px 10px;
`;

export const IconWrapper = styled.aside<IconWrapperProps>`
	flex: 0 0 40%;
	max-width: 10%;
	min-height: 64px;
	text-align: center !important;

	svg {
		font-size: ${(props) => (props.size ? props.size : 3)}em;
		color: ${(props) => (props.color ? props.color : "#333")};
		line-height: 59px;
	}
`;

export const SubtitleIconWrapper = styled.aside`
	svg {
		font-size: 14px;
		color: #a9a9a9;
		line-height: 30px;
	}
`;

export const Subtitle = styled(Typography)`
	font-size: 14px !important;
	color: #a9a9a9;
	font-weight: 400 !important;
	line-height: 30px !important;
`;

export const Row = styled.aside`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	gap: 5px;
`;

export const Column = styled.aside`
	display: flex;
	flex-direction: column;
	flex: 0 0 58.333333%;
	align-items: flex-end;
	justify-content: flex-start;
	max-width: 58.333333%;
`;

export const CardLabel = styled(Typography)`
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #9a9a9a !important;
`;

export const CardValue = styled(Typography)`
	font-size: 22px !important;
	font-weight: 300 !important;
	color: #333 !important;
`;

export const Divisor = styled(Divider)`
	margin-top: 5px;
	margin-bottom: 5px;
	/* bargin-block-start: 0.5rem,
    bargin-block-end: 0.5rem, */
`;

export const ContainerOld = styled.div`
	font: var(--text-body);
	font-size: 20px;
	color: #fff;
	// width: 250px;
	// min-width: 250px;
	object-fit: fill;
	/* height: 164px; */
	span {
		font-size: 24px;
	}
	.icon {
		min-height: 100px;
		max-height: 100px;
		font-size: 100px;
		color: rgba(21, 21, 21, 0.5);
		margin: 0;
	}
	.row {
		min-height: 100px;
		max-height: 100px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		div {
			max-width: 100px;
			max-height: 100px;
			img {
				width: 100%;
				height: 100%;
				object-fit: fill;
			}
		}
	}
	.blue {
		background-color: #00c0ef;
		max-width: 300px;
		min-width: 250px;
		height: 154px;
	}
	.green {
		background-color: #00a65a;
		max-width: 300px;
		min-width: 250px;
		height: 154px;
	}
	.orange {
		background-color: #f39c11;
		max-width: 300px;
		min-width: 250px;
		height: 154px;
	}
	.red {
		background-color: #dd4c39;
		max-width: 300px;
		min-width: 250px;
		height: 154px;
	}
	/* @media (max-width: 1150px) {
    font-size: 18px;
    .icon {
      min-height: 70px;
      max-height: 70px;
      font-size: 70px;
    }
    .row {
      min-height: 70px;
      max-height: 70px;
      div {
        max-width: 70px;
        max-height: 70px;
      }
    }
    .blue {
      min-width: 203px;
    }
    .green {
      min-width: 203px;
    }
    .orange {
      min-width: 203px;
    }
    .red {
      min-width: 203px;
    }
  } */
`;
