import styled from "styled-components";

export const Container = styled.section`
    width: 100%;
    height: 100%;
`;

export const Cards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 1rem;
`;

export const CalendarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    max-height: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    gap: 1rem;

    .column {
        display: flex;
        flex-direction: flex;
        @media (max-width: 1100px) {
            flex-direction: column;
        }
    }

    /* .row {
        display: flex;
        flex-direction: column;
        @media (max-width: 1100px) {
            display: flex;
            flex-direction: row;
        }
    } */

    .low-card {
        display: flex;
        /* flex-direction: column; */
        /* padding-right: 1rem; */

        @media (max-width: 1100px) {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    }
`;

export const TopSalesTable = styled.div`
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 600px;
`;

export const TopSales = styled.div`
    width: 100%;
    display: flex;
    gap: 70px;
`;

export const TopSaleItem = styled.div`
    display: flex;
    background: white;
    padding: 20px;
    align-items: center;

    :not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 3px;
        overflow: hidden;
        margin-right: 20px;
        display: block;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    p {
        font-size: 16px;
    }

    span {
        display: inline-block;
        margin-left: auto;
        font-weight: 600;
        font-size: 16px;
    }
`;
