import React from "react";
import { Container } from "./style";

interface ContentProps {
	children?: any;
	onClick?: () => void;
	className?: string;
	others?: React.ReactNode | JSX.Element;
}

export default function Content(props: ContentProps) {
	var { children, onClick, className, ...others } = props;

	return (
		<Container className={className} {...others}>
			{children}
		</Container>
	);
}
