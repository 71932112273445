import {
	createBrowserRouter,
	RouterProvider,
	redirect,
} from "react-router-dom";

import { SignIn } from "./views/SignIn";
import { Register } from "./views/Register";
import { RecoverPassword } from "./views/RecoverPassword";
import { Home } from "./views/Home";

import { Profile } from "./views/Profile";
import { AddProduct } from "./views/AddProduct";
import { Promotions } from "./views/Promotions";
import { Financial } from "./views/Financial";
import { Collaborator } from "./views/Collaborator";
import { AddCollaborator } from "./views/AddCollaborator";
import { Sales } from "./views/Sales";

import { Scheduling } from "./views/Scheduling";
import { Bank } from "./components/Tabs/Bank";
import { WorkDaysPage } from "./views/Settings/WorkDaysPage";
import { DeleteAccountPage } from "./views/Settings/DeleteAccountPage";
import { AddressPage } from "./views/Settings/AddressPage";
import { DocumentsPage } from "./views/Settings/DocumentsPage";
import { ErrorPage } from "./views/Error";

import { store } from "./store";

const loader = async () => {
	try {
		const state = store.getState();
		const { token } = state;

		if (!token?.data) return redirect("/");

		return null;
	} catch (error) {}
};

const router = createBrowserRouter([
	{
		path: "/",
		element: <SignIn />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/cadastro",
		element: <Register />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/agendamentos",
		element: <Scheduling />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/colaborador",
		element: <Collaborator />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/colaborador/adicionar",
		element: <AddCollaborator />,
		loader: loader,
		errorElement: <ErrorPage />,
	},

	{
		path: "/configuracoes/bancos",
		element: <Bank />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/configuracoes/documentos",
		element: <DocumentsPage />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/configuracoes/enderecos",
		element: <AddressPage />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/configuracoes/horario",
		element: <WorkDaysPage />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/configuracoes/privacidade",
		element: <DeleteAccountPage />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/dashboard",
		element: <Home />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/financeiro",
		element: <Financial />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/minhas-vendas",
		element: <Sales />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/perfil",
		element: <Profile />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/produtos/venda",
		element: <Promotions />,
		loader: loader,
		errorElement: <ErrorPage />,
	},
	{
		path: "/produtos/adicionar",
		element: <AddProduct />,
		loader: loader,
		errorElement: <ErrorPage />,
	},

	{
		path: "/recuperarsenha",
		element: <RecoverPassword />,
		errorElement: <ErrorPage />,
	},
]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
