import { useEffect, useState } from "react";
import {
	PropsProducts,
	PropsProductSale,
} from "../../../utils/types/product/ProductTypes";
import "react-toastify/dist/ReactToastify.css";
import { Container, ModalContainer, RowDiv } from "./styles";
import { Button } from "../../StyledComponents";
import { Modal } from "../../Modal";
import { TableAddProductSale } from "../../TableAddProductSale";
import { FormAddProducSale } from "./AddProducSale";
import { PropsCategory } from "../../../utils/types/categories/CategoriesType";
import { FormProduct } from "../Product";

type Props = {
	item?: PropsProductSale;
	onUpdate?: () => void;
	cancel?: () => void;
	products?: PropsProducts[];
	category: PropsCategory[];
	setFilter?: Function;
	setGetTypeFilter?: Function;
	getTypeFilter: string;
	page: number;
	setPage: Function;
	paginate: number;
};

export function FormProductSale(props: Props) {
	const {
		item,
		onUpdate,
		cancel,
		products,
		setFilter,
		setGetTypeFilter,
		getTypeFilter,
		page,
		setPage,
		paginate,
	} = props;

	const [totalValue, setTotalValue] = useState<any>(0);
	const [sales, setSales] = useState<PropsProducts[]>();
	const [modal, setModal] = useState<boolean>(false);
	const [modalProduct, setModalProduct] = useState<boolean>(false);
	const id = "modal";
	const idProduct = "modalProduct";

	useEffect(() => {
		if (item) {
			setSales(item.products);
		}
	}, [item]);

	useEffect(() => {
		if (sales) {
			let price = sales.map((item) => item.salePrice * parseFloat(item.amount));

			if (sales.length < 1) {
				setTotalValue(0);
			}
			if (sales.length >= 1) {
				let totalPrice =
					price.reduce((soma, i) => {
						return soma + i;
					}) || 0;
				let formatPrice = new Intl.NumberFormat("pt-BR", {
					style: "currency",
					currency: "BRL",
				}).format(totalPrice);

				setTotalValue(formatPrice);
			}
		}
	}, [sales]);

	function handleOutSideClick(e: any) {
		if (e.target.id === id) setModal(false);
	}
	function handleOutSideClickProduct(e: any) {
		if (e.target.id === idProduct) setModalProduct(false);
	}

	function addItem(id) {
		var copyDoState = [...sales];
		let a = parseFloat(copyDoState[id].amount);
		a += 1;
		copyDoState[id].amount = a;
		setSales(copyDoState);
	}
	function subItem(id) {
		var copyDoState = [...sales];
		copyDoState[id].amount -= 1;
		setSales(copyDoState);
	}

	const handleItem = (item) => {
		let product = null;
		products.find((t) => (t._id === item._id ? (product = t) : null));

		if (product !== null) {
			product.amount = 1;
			product.productId = product._id;
			let include = sales?.map((value) => value._id).includes(product._id);

			if (!include) {
				if (sales) {
					setSales([...sales, product]);
				} else {
					let arr = [];
					arr = [product];
					setSales(arr);
				}
			}
		}
	};

	function removeItem(id) {
		if (sales.length < 1) {
			setTotalValue(0);
		}
		let copyDoState = [...sales];

		copyDoState.splice(id, 1);

		setSales(copyDoState);
	}

	return (
		<Container>
			<RowDiv style={{ padding: 0 }}>
				<FormAddProducSale
					onUpdate={onUpdate}
					products={sales}
					sugestPrice={totalValue}
					item={item}
					cancel={cancel}
					add={addItem}
					sub={subItem}
					del={removeItem}
					addProduct={() => setModal(true)}
				/>
			</RowDiv>

			{modal && (
				<Modal
					setState={setModal}
					title="Adicionar produto do estoque"
					id={id}
					onClick={handleOutSideClick}
				>
					<ModalContainer>
						<div className="row-table">
							<div className="input-container">
								<input
									type="text"
									placeholder="Buscar"
									onChange={(e) => setFilter(e.target.value)}
								/>
							</div>
							<div className="select-container">
								<select
									value={getTypeFilter}
									onChange={(e) => setGetTypeFilter(e.target.value)}
								>
									<option value="brand">Marca</option>
									<option value="oil">Óleos</option>
									<option value="filter">Filtros</option>

									<option value=""></option>
									<option value=""></option>
								</select>
							</div>
						</div>

						<Button
							type="button"
							width="120px"
							onClick={() => setModalProduct(true)}
						>
							Novo produto
						</Button>
					</ModalContainer>
					<TableAddProductSale
						products={products}
						onSelectItem={handleItem}
						page={page}
						setPage={(newPage) => setPage(newPage)}
						paginate={paginate}
					/>
				</Modal>
			)}
			{modalProduct && (
				<Modal
					setState={setModalProduct}
					title="Adicionar novo produto"
					id={idProduct}
					onClick={handleOutSideClickProduct}
					width="800px"
				>
					<FormProduct update={() => setModalProduct(false)} />
				</Modal>
			)}
		</Container>
	);
}
