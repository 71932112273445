import React from 'react';
import PropTypes from 'prop-types';

import { Button } from './styles';

type Props = {
    handlePress: () => void;
    children: React.ReactNode;
    others?: any;
}

IconButton.propTypes = {
    handlePress: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    others: PropTypes.any
}

export function IconButton(props: Props) {
    const { handlePress, children, ...others } = props;

    const handleClick = () => {
        handlePress();
    };

    return (
        <Button onClick={handleClick} {...others}>
            {children}
        </Button>
    );
}
