import Button from "../../components/StyledComponents/Button";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";

export function ErrorPage() {
	const navigate = useNavigate();

	function goToHome() {
		navigate("/");
	}

	return (
		<Container>
			<h1 style={{ marginBottom: 15 }}>Oops! Página não encontrada...</h1>
			<Button onClick={goToHome}>Voltar</Button>
		</Container>
	);
}
