import React, { useEffect, useState } from "react";
import {
    listCarBrand,
    listCarModel,
    listCarYear,
} from "../../../services/cars-services";

import {
    PropsCarBrand,
    PropsCarModel,
    PropsCarYear,
} from "../../../utils/types/InfoCar";
import { PropsProducts } from "../../../utils/types/product/ProductTypes";
import { FormOil } from "./Oil";

import { Container } from "./styles";

interface IPropsCarBrand {
    items: PropsCarBrand[];
}
interface IPropsCarYear {
    items: PropsCarYear[];
}
interface IPropsCarModel {
    items: PropsCarModel[];
}

type Props = {
    product?: PropsProducts;
    update?: () => void
};

export function FormProduct({ product, update }: Props) {
    const [, setValuesCarModel] = useState<IPropsCarModel>({
        items: [],
    });
    const [, setValuesCarYear] = useState<IPropsCarYear>({
        items: [],
    });
    const [, setValuesCarBrand] = useState<IPropsCarBrand>({
        items: [],
    });

    const [, setSelectForm] = useState<string>(
        product ? product.category : "produto"
    );

    useEffect(() => {
        if (product) {
            setSelectForm(product.category);
        }
    }, [product]);

    
    async function handleLoadCarModel() {
        const response = await listCarModel({ filter: "all" });
        if (!response.error) {
            setValuesCarModel(response.data);
        }
    }
    async function handleLoadCarBrand() {
        const response = await listCarBrand({ filter: "all" });
        if (!response.error) {
            setValuesCarBrand(response.data);
        }
    }
    
    async function handleLoadCarYear() {
        const response = await listCarYear({ filter: "all" });
        if (!response.error) {
            setValuesCarYear(response.data);
        }
    }

    useEffect(() => {
        handleLoadCarModel();
        handleLoadCarBrand();
        handleLoadCarYear();
    }, []);
    
    return (
        <Container>
            <FormOil />
        </Container>
    );
}
