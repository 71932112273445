import React from "react";

import { RegisterStyled } from "./styles";

import { UnAuthContainer } from "../Containers";
import { FormUser } from "../../components/Forms/User";

export function Register() {
	return (
		<UnAuthContainer>
			<RegisterStyled>
				<FormUser />
			</RegisterStyled>
		</UnAuthContainer>
	);
}
