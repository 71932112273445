import TextRating from "../TextRating";
import { Container, Left } from "./styles";

interface IProps {
	comments: {
		rating?: number;
		message?: string;
		user?: any;
		_id?: string;
	}[];
}

const Comments = ({ comments }: IProps) => {
	return (
		<>
			{comments.map((item) => (
				<Container>
					<Left>
						<div className="user">
							<span>{item?.user?.name || "Nome"}</span>
							<TextRating value={item.rating} />
						</div>
						<p>{item.message || "Nenhuma mensagem."}</p>
					</Left>
				</Container>
			))}
		</>
	);
};

export default Comments;
