import React, { useEffect } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { MenuItem, Tab, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import validator from "validator";
import cep from "cep-promise";
import { useDebouncedCallback } from "use-debounce";
import { countrys } from "../../../consult/Icountry";
import { ufs } from "../../../consult/Iufs";
import {
	addressPost,
	addressUpdateRegistration,
} from "../../../services/organizationUpload-service";
import { handleToast } from "../../HandleToast";
import {
	FormButtonContainer,
	FormContainer,
	FormStep,
} from "../User/FormUserSteps/styles";
import { PropsCompany } from "../../../store/user";
import { Button } from "../../StyledComponents";

interface CustomProps {
	mask: string;
}

type Props = {
	nextStep: () => void;
	backStep?: () => void;
	company?: PropsCompany;
	loading?: boolean;
};

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
	function TextMaskCustom(props, ref) {
		const { mask, ...other } = props;

		return <ReactInputMask mask={mask} {...other} />;
	}
);

export default function FormCompany(props: Props) {
	let { nextStep, company } = props;
	const [submitted, setSubmitted] = React.useState(false);

	const [values, setValues] = React.useState({
		city: "",
		state: "",
		district: "",
		country: "",
		street: "",
		number: "",
		complement: "",
		zipcode: "",
		companyName: "",
		storeName: "",
		document: "",
	});

	const [tab, setTab] = React.useState("1");

	const { handleSubmit, clearErrors } = useForm();

	const [loadingAddress, setLoadingAddress] = React.useState(false);

	async function registerStore() {
		let unMaskCep = values?.zipcode;
		unMaskCep = unMaskCep?.replace(/\D/g, "");
		let unMaskDoc = values?.document;
		unMaskDoc = unMaskDoc?.replace(/\D/g, "");

		if (!values?.storeName) {
			return handleToast(true, "Entre com a razão social da empresa.");
		}

		if (!values?.companyName) {
			return handleToast(true, "Entre com o nome da loja.");
		}

		if (
			!values.zipcode ||
			!values.city ||
			!values.state ||
			!values.district ||
			!values.street ||
			values.number === ""
		) {
			return handleToast(true, "Endereço incompleto.");
		}

		let orgData = {
			name: values.storeName,
			document: unMaskDoc,
			companyName: values.companyName,
			address: {
				zipcode: unMaskCep,
				city: values.city,
				state: values.state,
				district: values.district,
				street: values.street,
				number: values.number,
				complement: values.complement,
				country: values.country,
			},
		};

		setSubmitted(true);

		try {
			if (company?.organization?.address[0]) {
				const response = await addressUpdateRegistration(
					company.organization?._id,
					orgData,
					company?.organization?.address[0]?._id
				);

				if (response?.error) {
					handleToast(true, response?.message);
				} else {
					nextStep();
				}
			} else {
				const response = await addressPost(company.organization?._id, orgData);
				if (!response.error) {
					nextStep();
					handleToast(false, "Dados cadastrados com sucesso");
				} else {
					handleToast(true, response?.message);
				}
			}
		} catch (e) {
			handleToast(true, "Ocorreu um erro");
		} finally {
			return setSubmitted(false);
		}
	}

	const onBlurCep = useDebouncedCallback(async (zipcode: any) => {
		setLoadingAddress(true);

		try {
			const res = await cep(zipcode);

			setValues({
				...values,
				city: res?.city,
				state: res?.state,
				district: res?.neighborhood,
				street: res?.street,
				zipcode,
				country: "BR",
			});
		} catch (e) {
			handleToast(true, "CEP inválido");
		} finally {
			setLoadingAddress(false);
		}
	}, 1000);

	function validateCNPJ(value) {
		if (validator.isEmpty(value)) {
			handleToast(true, "CNPJ deve ser preenchido");
			return false;
		}
	}

	const handleChange = (property: string) => (event: any) => {
		setValues({
			...values,
			[property]: event.target?.value,
		});

		if (property === "zipcode") {
			if (event?.target?.value) {
				const value = event?.target?.value;

				const replaceCep = value.replace(/[^0-9]/g, "");

				if (replaceCep?.length === 8) {
					onBlurCep(replaceCep);
				}
			}
		}
	};

	useEffect(() => {
		if (company) {
			setValues((prevState) => ({
				...prevState,
				document: company?.organization?.document?.replace(
					/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
					"$1.$2.$3/$4-$5"
				),
				companyName: company?.organization?.companyName,
				storeName: company?.organization?.name,
			}));

			if (company?.organization?.address?.length) {
				setValues((prevState) => ({
					...prevState,
					...company?.organization?.address[0],
					country: company?.organization?.address[0]?.country || "BR",
					zipcode: company?.organization?.address[0]?.zipcode
						?.replace(/\D/g, "")
						.replace(/(\d{5})(\d)/, "$1-$2")
						.replace(/(-\d{3})\d+?$/, "$1"),
				}));
			}
		}
	}, [company]);

	return (
		<FormContainer>
			<TabContext value={tab}>
				<TabList
					onChange={(event: React.SyntheticEvent, value: string) =>
						setTab(value)
					}
					scrollButtons
					allowScrollButtonsMobile
				>
					<Tab value={"1"} label={"Dados da Empresa"} />
					<Tab value={"2"} label={"Endereço da Empresa"} />
				</TabList>

				<FormStep onSubmit={handleSubmit(registerStore)}>
					<TabPanel
						value={"1"}
						sx={{
							width: "100%",
						}}
					>
						<div className="row input-div">
							<TextField
								fullWidth
								label="CNPJ"
								variant="outlined"
								required
								aria-required
								size="small"
								value={values.document}
								onBlur={(e) => validateCNPJ(e.target.value)}
								onFocus={() => clearErrors("document")}
								InputProps={{
									inputComponent: TextMaskCustom as any,
									inputProps: {
										mask: "99.999.999/9999-99",
										maskChar: "",
										value: values.document,
									},
								}}
								onChange={handleChange("document")}
								InputLabelProps={{
									shrink: !!values?.document,
								}}
								sx={{
									display: "flex",
									flex: 0.4,
								}}
							/>
							<TextField
								label="Razão Social"
								variant="outlined"
								required
								aria-required
								size="small"
								onChange={handleChange("companyName")}
								onFocus={() => clearErrors("companyName")}
								value={values.companyName}
								InputLabelProps={{
									shrink: !!values?.companyName,
								}}
								sx={{
									display: "flex",
									flex: 1,
								}}
							/>
						</div>
						<div className="input-div">
							<TextField
								label="Nome da Loja"
								variant="outlined"
								required
								size="small"
								aria-required
								value={values.storeName}
								onChange={handleChange("storeName")}
								onFocus={() => clearErrors("storeName")}
								InputLabelProps={{
									shrink: !!values?.storeName,
								}}
								sx={{
									display: "flex",
									flex: 1,
								}}
							/>
						</div>
					</TabPanel>
					<TabPanel
						value={"2"}
						sx={{
							width: "100%",
						}}
					>
						<section className="row input-div">
							<TextField
								size="small"
								label="CEP"
								variant="outlined"
								required
								aria-required
								onChange={handleChange("zipcode")}
								value={values.zipcode}
								InputProps={{
									inputComponent: TextMaskCustom as any,
									inputProps: {
										mask: "99999-999",
										alwaysShowMask: false,
										maskChar: "",
										onChange: handleChange("zipcode"),
										value: values.zipcode,
									},
								}}
								InputLabelProps={{
									shrink: !!values?.zipcode,
								}}
								sx={{
									width: "50%",
								}}
							/>
							<TextField
								size="small"
								label="Pais"
								variant="outlined"
								required
								select
								aria-required
								value={values.country}
								onChange={handleChange("country")}
								InputLabelProps={{
									shrink: !!values?.country,
								}}
								onFocus={() => clearErrors("country")}
								sx={{
									width: "50%",
								}}
							>
								{countrys.map((country, index) => (
									<MenuItem key={index} value={country?.value}>
										{country?.label}
									</MenuItem>
								))}
							</TextField>
						</section>
						<div className="row input-div">
							<TextField
								size="small"
								label="Cidade"
								variant="outlined"
								required
								disabled={loadingAddress}
								aria-required
								value={values.city}
								InputLabelProps={{
									shrink: !!values?.city,
								}}
								onChange={handleChange("city")}
								onFocus={() => clearErrors("city")}
								sx={{
									width: "100%",
								}}
							/>
							<TextField
								size="small"
								label="UF"
								variant="outlined"
								required
								disabled={loadingAddress}
								select
								value={values.state}
								onChange={handleChange("state")}
								onFocus={() => clearErrors("state")}
								InputLabelProps={{
									shrink: !!values?.state,
								}}
								sx={{
									width: "100%",
									// marginLeft: '15px',
								}}
							>
								{ufs.map((state) => (
									<MenuItem value={state?.value} key={state?.value}>
										{state?.label}
									</MenuItem>
								))}
							</TextField>
						</div>
						<div className="row input-div">
							<TextField
								size="small"
								label="Bairro"
								variant="outlined"
								required
								disabled={loadingAddress}
								aria-required
								value={values.district}
								onChange={handleChange("district")}
								onFocus={() => clearErrors("district")}
								InputLabelProps={{
									shrink: !!values?.district,
								}}
								sx={{
									width: "100%",
								}}
							/>
							<TextField
								size="small"
								label="Rua"
								variant="outlined"
								required
								disabled={loadingAddress}
								aria-required
								value={values.street}
								onChange={handleChange("street")}
								onFocus={() => clearErrors("street")}
								InputLabelProps={{
									shrink: !!values?.street,
								}}
								sx={{
									width: "100%",
								}}
							/>
						</div>
						<div className="row input-div">
							<TextField
								size="small"
								label="Complemento"
								variant="outlined"
								aria-required
								disabled={loadingAddress}
								value={values.complement}
								onChange={handleChange("complement")}
								onFocus={() => clearErrors("complement")}
								InputLabelProps={{
									shrink: !!values?.complement,
								}}
								sx={{
									display: "flex",
									flex: 1,
								}}
							/>
							<TextField
								size="small"
								label="Número"
								variant="outlined"
								required
								disabled={loadingAddress}
								aria-required
								value={values.number}
								onChange={handleChange("number")}
								onFocus={() => clearErrors("number")}
								InputProps={{
									inputComponent: TextMaskCustom as any,
									inputProps: {
										mask: "9999999",
										alwaysShowMask: false,
										maskChar: "",
										onChange: handleChange("number"),
										value: values?.number,
									},
								}}
								InputLabelProps={{
									shrink:
										values?.number !== "" &&
										values?.number !== undefined &&
										values?.number !== null,
								}}
								sx={{
									display: "flex",
									flex: 0.2,
								}}
							/>
						</div>
					</TabPanel>
					<FormButtonContainer className="form-button-container">
						{tab === "1" && (
							<Button
								type="button"
								disabled={submitted}
								onClick={() => {
									setTab("2");
								}}
							>
								{submitted ? "Enviando..." : "Próximo"}
							</Button>
						)}
						{tab === "2" && (
							<Button type="submit" disabled={submitted}>
								{submitted ? "Enviando..." : "Próximo"}
							</Button>
						)}
					</FormButtonContainer>
				</FormStep>
			</TabContext>
		</FormContainer>
	);
}
