import styled from "styled-components";

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    outline: 0;
    border: 0;
    background-color: transparent;
    border-radius: 50%;
`;
