export const countrys = [
    // { value: "AF", label: "Afeganistão" },
    // { value: "CL", label: "Chile" },
    // { value: "ZA", label: "Africa do Sul" },
    // { value: "CN", label: "China" },
    // { value: "AL", label: "Albânia" },
    // { value: "CY", label: "Chipre" },
    // { value: "DE", label: "Alemanha" },
    // { value: "CO", label: "Colômbia" },
    // { value: "AD", label: "Andorra" },
    // { value: "KM", label: "Comores" },
    // { value: "AO", label: "Angola" },
    // { value: "CG", label: "Congo" },
    // { value: "AI", label: "Anguilla" },
    // { value: "CI", label: "Costa do Marfim" },
    // { value: "AQ", label: "Antartica" },
    // { value: "CR", label: "Costa Rica" },
    // { value: "AG", label: "Antígua e Barbuda" },
    // { value: "HR", label: "Croácia" },
    // { value: "AN", label: "Antilhas Holandesas" },
    // { value: "CU", label: "Cuba" },
    // { value: "SA", label: "Arábia Saudita" },
    // { value: "DK", label: "Dinamarca" },
    // { value: "DZ", label: "Argélia" },
    // { value: "DJ", label: "Djibuti" },
    // { value: "AR", label: "Argentina" },
    // { value: "DM", label: "Dominica" },
    // { value: "AM", label: "	Armênia	" },
    // { value: "EG", label: "Egito" },
    // AW	Aruba	SV	El Salvador
    // AU	Austrália	AE	Emirados Árabes Unidos
    // AT	Áustria	EC	Equador
    // AZ	Azerbaidjão	ER	Eritréia
    // BS	Bahamas	EM	Escritório para Harmonização no Mercado Interno
    // BD	Bangladesh	SK	Eslováquia
    // BB	Barbados	SI	Eslovenia
    // BH	Bareine	ES	Espanha
    // BY	Belarus	US	Estados Unidos da América
    // BE	Bélgica	EE	Estônia
    // BZ	Belize	ET	Etiópia
    // BX	Benelux	RU	Federação Russa
    // BJ	Benin	FJ	Fiji
    // BM	Bermudas	PH	Filipinas
    // BO	Bolívia	FI	Finlândia
    // BA	Bósnia e Herzegóvina	FR	França
    // BW	Botswana	GA	Gabão
    { value: "BR", label: "	Brasil" },
    // GM	Gambia
    // BG	Bulgária	GE	Geórgia
    // BN	Brunei Darussalam	GH	Gana
    // BF	Burkina Faso	GS	Geórgia do Sul e Ilhas Sandwich do Sul
    // BI	Burundi	GI	Gibraltar
    // BT	Butão	GD	Granada
    // CV	Cabo Verde	GR	Grécia
    // CM	Camarões	GL	Groenlândia
    // KH	Camboja	GP	Guadalupe
    // CA	Canadá	GU	Guam
    // QA	Catar	GT	Guatemala
    // KZ	Cazaquistão	GY	Guiana
    // TD	Chade	GN	Guine
    // GW	Guiné Bissau	JO	Jordânia
    // GQ	Guine Equatorial	KI	Kiribati
    // HT	Haiti	KW	Kuwait
    // NL	Holanda	LA	Laos
    // HN	Honduras	LS	Lesoto
    // HK	Hong-Kong	LV	Letônia
    // HU	Hungria	LB	Líbano
    // YE	Iêmen	LR	Libéria
    // BV	Ilha Bouvet	LY	Líbia
    // IM	Ilha do Homem	LI	Liechtenstein
    // CX	Ilha Natal	LT	Lituânia
    // NF	Ilha Norfalk	LU	Luxemburgo
    // KY	Ilhas Cayman	MO	Macau
    // CC	Ilhas Cocos	MG	Madagascar
    // CK	Ilhas Cook	MY	Malásia
    // GG	Ilhas do Canal	MW	Malawi
    // FO	Ilhas Faroe	MV	Maldivas
    // HM	Ilhas Heard e McDonald	ML	Mali
    // FK	Ilhas Malvinas	MT	Malta
    // MP	Ilhas Marianas do Norte	MA	Marrocos
    // MH	Ilhas Marshall	MQ	Martinica
    // UM	Ilhas Menores	MU	Maurício
    // SB	Ilhas Salomão	MR	Mauritânia
    // TC	Ilhas Turks e Caicos	MX	México
    // VG	Ilhas Virgens (Britânicas)	MM	Mianmá
    // VI	Ilhas Virgens (U.S.)	FM	Micronésia
    // WF	Ilhas Wallis e Futura	MZ	Moçambique
    // IN	India	MC	Mônaco
    // ID	Indonésia	MN	Mongólia
    // IR	Irã	MS	Mont Serrat
    // IQ	Iraque	ME	Montenegro
    // IE	Irlanda	NA	Namíbia
    // IS	Islândia	NR	Nauru
    // IL	Israel	NP	Nepal
    // ID	Indonésia	NI	Nicarágua
    // IR	Irã	NE	Níger
    // IQ	Iraque	NG	Nigéria
    // IE	Irlanda	NO	Noruega
    // IS	Islândia	NC	Nova Caledônia
    // IL	Israel	NZ	Nova Zelândia
    // IT	Itália	OM	Omã
    // JM	Jamaica	OA	Organização Africana de Propriedade Intelectual (OAPI)
    // JP	Japão	WO	Organização Mundial de Propriedade Intelectual – OMPI (WIPO)
    // JE	Jersey	AP	Organização Regional de Propriedade Industrial Africana
    // PW	Palau	SE	Suécia
    // PA	Panamá	CH	Suíça
    // PG	Papua Nova Guiné	SR	Suriname
    // PK	Paquistão	SJ	Svalbard e Jan Mayen
    // PY	Paraguai	TJ	Tadjiquistão
    // PE	Peru	TH	Tailândia
    // PN	Pitcairn	TW	Taiwan
    // PF	Polinésia Francesa	TF	Terras Austrais Francesas
    // PL	Polônia	IO	Territ.Britan.Oceano Índico
    // PR	Porto Rico	PS	Território Ocupado Palestino
    // PT	Portugal	TL	Timor-Leste
    // KE	Quênia	TG	Togo
    // KG	Quirguistão	TK	Tokelau
    // GB	Reino Unido	TO	Tonga
    // CF	República Centro Africana	TT	Trinidad e Tobago
    // KR	República da Coréia	TN	Tunísia
    // MK	República da Macedonia	TM	Turcomenistão
    // MD	República da Moldova	TR	Turquia
    // CD	República Dem. Do Congo	TV	Tuvalu
    // DO	República Dominicana	UA	Ucrânia
    // KP	República Pop. Dem. da Coreia	UG	Uganda
    // CZ	República Tcheca	UY	Uruguai
    // TZ	República Unida da Tanzânia	UZ	Uzbequistão
    // RE	Reunião	VU	Vanuatu
    // RO	Romênia	VA	Vaticano
    // RW	Ruanda	VE	Venezuela
    // { value: "EH", label: "	Saara Ocidental" },
    // { value: "VN", label: "Vietnã" },
    // { value: "PM", label: "Saint Pierre e Miquelon" },
    // { value: "YU", label: "	Yugoslávia" },
    // { value: "AS", label: "	Samoa Americana" },
    // { value: "ZR", label: "	Zaire" },
    // { value: "WS", label: "	Samoa Ocidental" },
    // { value: "ZM", label: "	Zâmbia" },
    // { value: "SH", label: "	Santa Helena" },
    // { value: "ZW", label: "	Zimbábue" },
    // { value: "LC", label: "	Santa Lúcia" },
    // { value: "KN", label: "	São Cristovão e Nevis" },
    // { value: "SM", label: "	São Marino" },
    // { value: "ST", label: "São Tomé e Príncipe" },
    // { value: "VC", label: "	São Vicente e Granadinas" },
    // { label: "SN", value: "Senegal" },
    // { value: "SL", label: "Serra Leoa" },
    // { value: "RS", label: "Sérvia" },
    // { value: "SC", label: "Seychelles" },
    // { value: "SG", label: "Singapura" },
    // { value: "SY", label: "Síria" },
    // { value: "SO", label: "Somália" },
    // { value: "LK", label: "Sri Lanka" },
    // { value: "SZ", label: "Suazilândia" },
    // { value: "SD", label: "Sudão" },
];