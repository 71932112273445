import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import api from "../../services";

export interface TokenState {
	data: string | null;
	loading: boolean;
	error: string;
}

const initialState: TokenState = {
	data: null,
	loading: false,
	error: "",
};

export const authUser = createAsyncThunk<
	{ error: boolean; data: string; message: string },
	{ email: string; password: string }
>("user/auth", async (data, apiThunk) => {
	const response = await api.post<{
		error: boolean;
		data: string;
		message: string;
	}>("/seller/auth", data);
	return response.data;
});

export const tokenSlice = createSlice({
	name: "token",
	initialState,
	reducers: {
		saveToken: (state, action: PayloadAction<string>) => {
			state.data = action.payload;
		},
		clearToken: (state) => {
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(authUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(authUser.rejected, (state, action) => {
				state.loading = false;
			})
			.addCase(authUser.fulfilled, (state, action) => {
				state.loading = false;
				state.data = action.payload.data;
			});
	},
});

export const { saveToken, clearToken } = tokenSlice.actions;

export const tokenSelector = (state: RootState) => state.token.data;
export const tokenLoadingSelector = (state: RootState) => state.token.loading;

export default tokenSlice.reducer;
