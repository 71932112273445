import {
	CardLabel,
	CardValue,
	Column,
	Container,
	Content,
	Divisor,
	Footer,
	IconWrapper,
	Row,
	Subtitle,
	SubtitleIconWrapper,
} from "./styles";

type Props = {
	color?: "blue" | "green" | "orange" | "red";
	image?: any;
	icon?: JSX.Element;
	subtitleIcon?: JSX.Element;
	value?: string | number;
	money?: boolean | false;
	info?: string;
	label?: string;
	subtitle?: string;
	redirect?: () => void;
	component?: JSX.Element;
	className?: string;
	onClick?: () => void;
};

export function CardInfo(props: Props) {
	var {
		color,
		image,
		value,
		money,
		info,
		icon,
		redirect,
		component,
		label,
		subtitle,
		subtitleIcon,
		className,
		...others
	} = props;

	const formatMoney = (value: number | string) => {
		if (typeof value === "string") {
			value = parseFloat(value);
		}

		return new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
		}).format(value);
	};

	return (
		<Container
			onClick={() => (redirect ? redirect : null)}
			className={className}
			{...others}
		>
			<Content className="card-content">
				<IconWrapper className="card-icon-wrapper" color={color}>
					{icon}
				</IconWrapper>
				<Column className="card-content-column">
					<CardLabel className="card-content-label">{label}</CardLabel>
					<CardValue className="card-content-value">
						{money ? formatMoney(value) : value}
					</CardValue>
				</Column>
			</Content>
			<Footer>
				<Divisor className="divider" />
				<Row>
					{subtitleIcon && (
						<SubtitleIconWrapper>{subtitleIcon}</SubtitleIconWrapper>
					)}
					<Subtitle>{subtitle}</Subtitle>
				</Row>
			</Footer>
		</Container>
	);
}
