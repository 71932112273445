import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const RegisterStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-height: 40rem;

    width: 100%;
    margin: auto 0;
    /* overflow-y: scroll; */
`;
