import React from "react";
import { PropsCollaborator } from "../../../utils/types/collaborator";
import { Button } from "../../StyledComponents";
import { Column, Container, FormProduct } from "./styles";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-toastify/dist/ReactToastify.css";
import { handleToast } from "../../HandleToast";

type Props = {
    collaborator?: PropsCollaborator;
    onUpdate?: () => void;
    cancel?: () => void;
};

export function FormCollaborator(props: Props) {
    var { collaborator, onUpdate, cancel } = props;

    const schema = yup.object().shape({
        name: yup.string().required("Informe o nome do colaborador"),
        password: yup
            .string()
            .required("Informe uma senha")
            .min(6, "Mínimo 6 caractere ")
            .max(32, "Máximo de 32 caractere "),
        email: yup
            .string()
            .email("E-mail inválido")
            .required("E-mail obrigatório"),
        permission: yup.string().required("Informe as permissões"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    function formSubmit(e: any) {
        handleToast(false, "cadastrado com sucesso");
        return onUpdate();
    }
    return (
        <Container>
            <FormProduct
                onSubmit={handleSubmit(formSubmit)}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
            >
                <div className="row">
                    <Column>
                        <div className="input-div">
                            <label>Nome</label>
                            <input
                                className="input"
                                type="text"
                                placeholder="Nome do colaborador"
                                defaultValue={
                                    collaborator ? collaborator.name : null
                                }
                                {...register("name")}
                            />
                            <span>{errors.name?.message?.toString()}</span>
                        </div>
                        {collaborator ? null : (
                            <div className="input-div">
                                <label>Senha</label>
                                <input
                                    className="input"
                                    type="password"
                                    placeholder="Senha do colaborador"
                                    {...register("password")}
                                />
                                <span>
                                    {errors.password?.message?.toString()}
                                </span>
                            </div>
                        )}
                    </Column>

                    <Column>
                        <div className="input-div">
                            <label>Email</label>
                            <input
                                type="email"
                                placeholder="Email"
                                className="input"
                                defaultValue={
                                    collaborator ? collaborator.email : null
                                }
                                {...register("email")}
                            />
                            <span>{errors.email?.message?.toString()}</span>
                        </div>
                        <div className="input-div">
                            <label>Permissão</label>
                            <select
                                className="input"
                                defaultValue={
                                    collaborator ? collaborator.permission : ""
                                }
                                {...register("permission")}
                            >
                                <option value="" disabled>
                                    Selecione à permissão
                                </option>
                                <option value="permission1">permissão1</option>
                                <option value="permission2">permissão2</option>
                                <option value="permission3">permissão3</option>
                                <option value="permission4">permissão4</option>
                                <option value="permission5">permissão5</option>
                            </select>
                            <span>
                                {errors.permission?.message?.toString()}
                            </span>
                        </div>
                    </Column>
                </div>
                <div className="content-button">
                    <Button type="button" outline onClick={cancel}>
                        Cancelar
                    </Button>

                    <Button type="submit">Enviar</Button>
                </div>
            </FormProduct>
        </Container>
    );
}
