import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import {
	FiHome,
	FiSettings,
	FiDollarSign,
	FiCalendar,
	FiShoppingCart,
	FiShoppingBag,
	FiArrowLeftCircle,
	FiArrowRightCircle,
} from "react-icons/fi";

import { CollapseButton, ImageWrapper } from "./styles";

import { UserProps } from "../../utils/types/user/UserTypes";

import logo from "../../assets/images/logo (2).png";
import msLogo from "../../assets/images/ms-icon.png";
import SidebarFooter from "./SidebarFooter";

type Props = {
	data: UserProps;
};

export function SideNav({ data }: Props) {
	const [status, setStatus] = useState<string>("awaiting");
	const [menuCollapse, setMenuCollapse] = useState(window.innerWidth <= 550);
	const [windowWidth] = useState(window.innerWidth);
	const [logoImage, setLogoImage] = useState<string>(logo);

	const menuIconClick = useCallback(() => {
		setMenuCollapse(!menuCollapse);
		menuCollapse ? setLogoImage(logo) : setLogoImage(msLogo);
	}, [menuCollapse]);

	const resizeWindow = useCallback(() => {
		if (windowWidth <= 550) {
			setMenuCollapse(true);
		}
	}, [windowWidth, setMenuCollapse]);

	useEffect(() => {
		window.addEventListener("resize", resizeWindow);
		return () => window.removeEventListener("resize", resizeWindow);
	}, [resizeWindow]);

	useEffect(() => {
		if (data) {
			setStatus(data?.organization?.status);
		}
	}, [data]);

	const imageStyle = useMemo(() => {
		if (menuCollapse) return { width: "50px" };

		return { width: "120px" };
	}, [menuCollapse]);

	const list = [
		{
			status,
			path: "/dashboard",
			icon: <FiHome />,
			name: "Home",
		},
		{
			status,
			path: "/minhas-vendas",
			icon: <FiShoppingBag />,
			name: "Minhas Vendas",
		},
		{
			status,
			path: "/produtos/venda",
			icon: <FiShoppingCart />,
			name: "Produtos à venda",
		},
		{
			status,
			path: "/agendamentos",
			icon: <FiCalendar />,
			name: "Agendamentos",
		},
		{
			status,
			path: "/financeiro",
			icon: <FiDollarSign />,
			name: "Financeiro",
		},
		{
			status,
			path: "/configuracoes",
			icon: <FiSettings />,
			name: "Configurações",
			submenu: [
				{
					path: "/configuracoes/bancos",
					name: "Bancos",
				},
				{
					path: "/configuracoes/horario",
					name: "Horário de funcionamento",
				},
				{
					path: "/configuracoes/enderecos",
					name: "Endereços",
				},
				{
					path: "/configuracoes/documentos",
					name: "Documentos",
				},
			],
		},
	];

	return (
		<Sidebar width="250px" backgroundColor="#FFF" collapsed={menuCollapse}>
			<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
				<ImageWrapper>
					<img src={logoImage} alt="logo lubbri" style={imageStyle} />
				</ImageWrapper>
				<Menu
					menuItemStyles={{
						button: {
							color: "#040473",
							[`&.active`]: {
								backgroundColor: "#E1E1E1",
								color: "#040473",
							},
							[`&.hover`]: {
								backgroundColor: "#E1E1E1",
							},
						},
					}}
				>
					<CollapseButton onClick={menuIconClick}>
						{menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
					</CollapseButton>

					{list.map((item) =>
						item.submenu ? (
							<SubMenu key={item.name} label={item.name} icon={item.icon}>
								{item.submenu.map((submenu) => (
									<MenuItem
										style={{
											backgroundColor: "#FFF",
										}}
										key={submenu.name}
										component={<Link to={submenu.path} />}
									>
										{submenu.name}
									</MenuItem>
								))}
							</SubMenu>
						) : (
							<MenuItem
								key={item.name}
								component={<Link to={item.path} />}
								icon={item.icon}
							>
								{item.name}
							</MenuItem>
						)
					)}
				</Menu>
				<SidebarFooter user={data.user} collapsed={menuCollapse} />
			</div>
		</Sidebar>
	);
}
