import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    .form-container {
        display: flex;
        width: 100%;
        padding: 1rem;
    }

    .form-container-schedule {
        display: flex;
        width: 100%;
        padding: 1rem;
        flex-direction:column;
        align-items:center;
    }

    .schedules {
        display:flex;
        background: #fff;
        width:100%;
        margin: 16px;
    }

    .column {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }
    .row {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 1rem;
        padding: 10px 20px;
        border-radius: 2px;

        border: 1px solid rgba(0, 0, 0, 0.1);

        @media (max-width: 830px) {
            display: flex;
            flex-direction: column;
        }
    }
    .center {
        width: 100%;
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
    }
    .hours-row {
        display: flex;
        gap: 20px;
        justify-content: flex-end;

        div {
            display: flex;
            gap: 5px;
            align-items: center;
        }
    }
    .row-simultaneous {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        gap: 10px;
    }
    .input {
        width: 100%;
        padding: 0.6rem;
        max-width: 70px;
        text-align: center;
        border-radius: 5px;
        margin-left: 3px;
        outline: 0;
        border: 1px solid rgba(28, 28, 28, 0.25);
        font: var(--text-body);

        &.top {
            max-width: 50px;
            text-align: center;
        }

        ::placeholder {
            font: var(--text-body);
        }

        @media (max-width: 830px) {
            display: flex;
            flex-direction: column;
            margin-left: 0px;
        }
    }
    .card {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 20px;
        justify-content: space-between;
        background-color: white;
        border: 1px solid rgba(28, 28, 28, 0.25);

        p {
            font: var(--text-medium);
            font-size: 16px;
            margin-bottom: 10px;
            text-align: center;
        }
        .divider {
            width: 100%;
            height: 1px;
            background-color: rgba(21, 21, 21, 0.3);
            margin-bottom: 20px;
        }
        .content-button-work-days {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
            margin-top: 20px;
        }
    }
`;


export const ContainerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`