import React, { useEffect, useState } from "react";
import { AuthContainer } from "../../Containers";
import { ButtonContainer, Container, Content } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { Button } from "../../../components/StyledComponents";
import { addressUpdate } from "../../../services/organizationUpload-service";
import { handleToast } from "../../../components/HandleToast";
import { useAppSelector } from "../../../store/hooks";

export function AddressPage() {
	const { data } = useAppSelector((state) => state.user);

	const [values, setValues] = useState({
		street: "",
		number: "",
		district: "",
		city: "",
		state: "",
	});

	const schema = yup.object().shape({
		street: yup.string().required("Informe o endereço"),
		number: yup.string().required("Informe o número"),
		district: yup.string().required("Informe o bairro"),
		city: yup.string().required("Informe a cidade"),
		state: yup.string().required("Informe o estado"),
	});

	const { register, handleSubmit, setValue } = useForm({
		resolver: yupResolver(schema),
	});

	async function handleSubmitForm(_data) {
		const orgId = data.organization?._id;
		const id = data.organization?.address[0]?._id;

		const response = await addressUpdate(orgId, id, _data);
		if (!response.error) {
			return handleToast(false, "Endereço cadastrado");
		} else {
			return handleToast(response.error, response.message);
		}
	}

	const handleChange =
		(property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({
				...values,
				[property]: (event.target as HTMLInputElement).value,
			});
			if (property === "street") setValue("street", event.target.value);
			if (property === "number") setValue("number", event.target.value);
			if (property === "district") setValue("district", event.target.value);
			if (property === "city") setValue("city", event.target.value);
			if (property === "state") setValue("state", event.target.value);
		};

	useEffect(() => {}, []);

	return (
		<AuthContainer pageTitle="Configurações | Endereços">
			<Container>
				{data && data.organization ? (
					data.organization?.address.map((item, index) => {
						return (
							<Content onSubmit={handleSubmit(handleSubmitForm)}>
								<TextField
									label="Rua"
									onChange={handleChange("street")}
									{...register("street")}
									defaultValue={item.street}
									size="small"
									sx={{
										display: "flex",
										flex: 0.6,
										width: "100%",
									}}
								/>
								<TextField
									label="Número"
									onChange={handleChange("number")}
									{...register("number")}
									defaultValue={item.number}
									size="small"
									sx={{
										display: "flex",
										flex: 0.6,
										width: "100%",
									}}
								/>
								<TextField
									label="Bairro"
									onChange={handleChange("district")}
									{...register("district")}
									defaultValue={item.district}
									size="small"
									sx={{
										display: "flex",
										flex: 0.6,
										width: "100%",
									}}
								/>
								<TextField
									label="Cidade"
									onChange={handleChange("city")}
									{...register("city")}
									defaultValue={item.city}
									size="small"
									sx={{
										display: "flex",
										flex: 0.6,
										width: "100%",
									}}
								/>
								<TextField
									label="Estado"
									onChange={handleChange("state")}
									{...register("state")}
									defaultValue={item.state}
									size="small"
									sx={{
										display: "flex",
										flex: 0.6,
										width: "100%",
									}}
								/>
								<ButtonContainer>
									<Button type="submit">Atualizar</Button>
								</ButtonContainer>
							</Content>
						);
					})
				) : (
					<Content></Content>
				)}
			</Container>
		</AuthContainer>
	);
}
