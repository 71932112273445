import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Switch from "react-switch";
import { MenuItem, TextField, Typography } from "@mui/material";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Container, ImageUpladComponent, ContainerD } from "./styles";
import SunEditor from "suneditor-react";
import { Button } from "../../../StyledComponents";
import { createProduct } from "../../../../services/product-service";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";

export interface IProduct {
	_id?: string;
	name: string;
	description: string;
	category: string;
	sku: string;
	price: number;
	amount: number;
	unityType: string;
	image: string;
	granulation: string;
	sae: string;
	anp: string;
	homologation: string;
	brand: string;
	carName: string;
	carCategory: string;
	carBrand: string;
	carModel: string;
	carYear: string;
	isActive: boolean;
	costPrice: number;
	salePrice: number;
	filterType: string;
	year: {
		from: string;
		to: string;
	};
	motorization: string;
	composition: string;
}

type Props = {
	product?: IProduct;
	update?: () => void;
};

export function FormOil(props: Props): any {
	const { data } = useAppSelector((state) => state.user);
	const [values, setValues] = useState<IProduct>();
	const [image, setImage] = useState<any>();
	const [updateImage, setUpdateImage] = useState<any>();
	const [isActive, setIsActive] = useState<boolean>(true);
	const navigate = useNavigate();
	const { product } = props;

	const schema = yup.object().shape({
		brand: yup.string().required("Informe a marca do veículo"),
		sae: yup.string().required("Informe o SAE do produto"),
		homologation: yup.string().required("Homologação necessária"),
		anp: yup.string().required("ANP necessário"),
		description: yup.string().required("Informe a descrição do produto"),
		composition: yup.string().required("Informe o tipo de óleo"),
		amount: yup.number().required("Informe a quantia de produtos para estoque"),
		costPrice: yup
			.number()
			.required("Informe o valor de custo do produtos para estoque"),
		salePrice: yup
			.number()
			.required("Informe o valor de venda do produtos para estoque"),
	});

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (product) {
			setValues(product);
			setUpdateImage(product.image);
			setValue("name", product.name);
			setValue("homologation", product.homologation);
			setValue("composition", product.composition);
			setValue("anp", product.anp);
			setValue("brand", product.brand);
			setValue("sae", product.sae);
			setValue("description", product.description);
			setValue("Carname", product.carName);
			setValue("carCategory", product.carCategory);
			setValue("carBrand", product.carBrand);
			setValue("carYear", product.carYear);
			setValue("filterType", product.filterType);
			setValue("year.from", product.year.from);
			setValue("year.to", product.year.to);
			setValue("amount", product.amount);
			setValue("costPrice", product.costPrice);
			setValue("salePrice", product.salePrice);
			setIsActive(product.isActive);
		}
	}, [product, setValue]);

	const uploadImage = React.useCallback(async (image: File) => {
		let formData = new FormData();
		formData.append("file", image);
	}, []);

	async function handleSubmitForm(docs) {
		if (image) {
			const imagePath = await uploadImage(image);
			docs.image = imagePath;
		} else if (updateImage) {
			docs.image = updateImage;
		}

		if (product) {
		} else {
			await createProduct(data?.organization?._id, docs);
			return navigate("/produtos");
		}
	}
	function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target && e.target.files[0]) {
			setImage(e.target.files[0]);
		}
	}

	const handleChange =
		(property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({
				...values,
				[property]: (event.target as HTMLInputElement).value,
			});
		};

	return (
		<div>
			<Container className="oil-container">
				<form
					className="form-content"
					onSubmit={handleSubmit(handleSubmitForm)}
					autoComplete="off"
				>
					<div className="row">
						<div className="column">
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									label="Nome Produto"
									size="small"
									{...register("name")}
									onChange={handleChange("name")}
									defaultValue={values?.name}
									error={!!errors.name?.message}
									helperText={errors.name?.message?.toString()}
								/>
							</div>
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									label="Quantidade do produto"
									size="small"
									{...register("amount")}
									onChange={handleChange("amount")}
									defaultValue={values?.amount}
									error={!!errors.amount?.message}
									helperText={errors.amount?.message?.toString()}
								/>
							</div>
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									label="Preço de custo do produto"
									size="small"
									{...register("costPrice")}
									onChange={handleChange("costPrice")}
									defaultValue={values?.costPrice}
									error={!!errors.costPrice?.message}
									helperText={errors.costPrice?.message?.toString()}
								/>
							</div>
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									label="Preço de vendo do produto"
									size="small"
									{...register("salePrice")}
									onChange={handleChange("salePrice")}
									defaultValue={values?.salePrice}
									error={!!errors.salePrice?.message}
									helperText={errors.salePrice?.message?.toString()}
								/>
							</div>
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									select
									label="Categoria"
									size="small"
									{...register("category")}
									onChange={handleChange("category")}
									defaultValue={values?.category}
									error={!!errors.category?.message}
									helperText={errors.category?.message?.toString()}
								>
									<MenuItem value="Oleo">Óleo</MenuItem>
									<MenuItem value="Filtro">Filtro</MenuItem>
								</TextField>
							</div>
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									select
									label="Tipo de filtro"
									size="small"
									{...register("filterType")}
									onChange={handleChange("filterType")}
									defaultValue={values?.filterType}
									error={!!errors.filterType?.message}
									helperText={errors.filterType?.message?.toString()}
								>
									<MenuItem value="Oleo">Óleo</MenuItem>
									<MenuItem value="Ar Condicionado">Ar Condicionado</MenuItem>
									<MenuItem value="Cabine">Cabine</MenuItem>
									<MenuItem value="Ar do Motor">Ar do Motor</MenuItem>
									<MenuItem value="Combustível">Combustível</MenuItem>
								</TextField>
							</div>
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									select
									label="Composição"
									size="small"
									{...register("composition")}
									onChange={handleChange("composition")}
									defaultValue={values?.composition}
									error={!!errors.composition?.message}
									helperText={errors.composition?.message?.toString()}
								>
									<MenuItem value="Mineral">Mineral</MenuItem>
									<MenuItem value="Sintetico">Sintético</MenuItem>
									<MenuItem value="Semissintetico">Semissintético</MenuItem>
								</TextField>
							</div>
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									select
									label="Granulação"
									size="small"
									{...register("granulation")}
									onChange={handleChange("granulation")}
									defaultValue={values?.granulation}
									error={!!errors.granulation?.message}
									helperText={errors.granulation?.message?.toString()}
								>
									<MenuItem value="true">Presente</MenuItem>
									<MenuItem value="false">Não Presente</MenuItem>
								</TextField>
							</div>
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									select
									label="Tipo de Unidade"
									size="small"
									{...register("unityType")}
									onChange={handleChange("unityType")}
									defaultValue={values?.unityType}
									error={!!errors.unityType?.message}
									helperText={errors.unityType?.message?.toString()}
								>
									<MenuItem value="unidade">Unidade</MenuItem>
								</TextField>
							</div>
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									label="Viscosidade SAE"
									size="small"
									onChange={handleChange("sae")}
									{...register("sae")}
									defaultValue={values?.sae}
									error={!!errors.sae?.message}
									helperText={errors.sae?.message?.toString()}
								/>
							</div>
							<div className="column input-div ">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									label="ANP óleo Lubrificante"
									size="small"
									onChange={handleChange("anp")}
									{...register("anp")}
									defaultValue={values?.anp}
									error={!!errors.anp?.message}
									helperText={errors.anp?.message?.toString()}
								/>
							</div>
							<div className="column input-div">
								<TextField
									InputLabelProps={{ shrink: true }}
									fullWidth
									label="Homologação"
									size="small"
									onChange={handleChange("homologation")}
									{...register("homologation")}
									defaultValue={values?.homologation}
									error={!!errors.homologation?.message}
									helperText={errors.homologation?.message?.toString()}
								/>
							</div>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: "10em 150px",
									justifyContent: "flex-start",
									gap: "10px",
								}}
							>
								<div className="column input-div ">
									<TextField
										InputLabelProps={{ shrink: true }}
										style={{background: '#fff'}}
										fullWidth
										label="Marca"
										size="small"
										onChange={handleChange("brand")}
										{...register("brand")}
										defaultValue={values?.brand}
										error={!!errors.brand?.message}
										helperText={errors.brand?.message?.toString()}
									/>
								</div>
								<div className="column input-div ">
									<TextField
										InputLabelProps={{ shrink: true }}
										fullWidth
										label="Motorização"
										size="small"
										onChange={handleChange("motorization")}
										{...register("motorization")}
										defaultValue={values?.motorization}
										error={!!errors.motorization?.message}
										helperText={errors.motorization?.message?.toString()}
									/>
								</div>
							</div>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: "10em 150px",
									justifyContent: "flex-start",
									gap: "10px",
								}}
							>
								<div className="column input-div ">
									<TextField
										InputLabelProps={{ shrink: true }}
										fullWidth
										label="Modelo do veículo"
										style={{ margin: "3px 0" }}
										size="small"
										{...register("carName")}
										onChange={handleChange("carName")}
										defaultValue={values?.carName}
										error={!!errors.name?.message}
										helperText={errors.name?.message?.toString()}
									/>
								</div>
								<div className="column input-div ">
									<TextField
										InputLabelProps={{ shrink: true }}
										fullWidth
										label="Categoria do carro"
										style={{ margin: "3px 0" }}
										size="small"
										{...register("carCategory")}
										onChange={handleChange("carCategory")}
										defaultValue={values?.carCategory}
										error={!!errors.carCategory?.message}
										helperText={errors.carCategory?.message?.toString()}
									/>
								</div>
							</div>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: "10em 150px",
									justifyContent: "flex-start",
									gap: "10px",
								}}
							>
								<div className="column input-div ">
									<TextField
										InputLabelProps={{ shrink: true }}
										fullWidth
										label="Montadora"
										style={{ margin: "3px 0" }}
										size="small"
										{...register("carBrand")}
										onChange={handleChange("carBrand")}
										defaultValue={values?.carBrand}
										error={!!errors.carBrand?.message}
										helperText={errors.carBrand?.message?.toString()}
									/>
								</div>
								<div className="column input-div ">
									<TextField
										InputLabelProps={{ shrink: true }}
										fullWidth
										label="Ano do veiculo"
										style={{ margin: "3px 0" }}
										size="small"
										{...register("carYear")}
										onChange={handleChange("carYear")}
										defaultValue={values?.carYear}
										error={!!errors.carYear?.message}
										helperText={errors.carYear?.message?.toString()}
									/>
								</div>
							</div>
							<h4 style={{ fontSize: "15px" }}>Ano do veículo</h4>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: "10em 150px",
									justifyContent: "flex-start",
									gap: "10px",
								}}
							>
								<div className="column input-div ">
									<TextField
										InputLabelProps={{ shrink: true }}
										fullWidth
										label="Desde"
										size="small"
										onChange={handleChange("year")}
										{...register("year.from")}
										defaultValue={values?.year?.from}
										error={!!errors.year?.message}
										helperText={errors.year?.message?.toString()}
									/>
								</div>
								<div className="column input-div ">
									<TextField
										InputLabelProps={{ shrink: true }}
										fullWidth
										label="à"
										size="small"
										onChange={handleChange("year.to")}
										{...register("year.to")}
										defaultValue={values?.year?.to}
										error={!!errors.year?.message}
										helperText={errors.year?.message?.toString()}
									/>
								</div>
							</div>
							<div className="column input-div ">
								<label>
									<Typography>Ativar/desativar</Typography>
									<Switch
										onChange={() => setIsActive(!isActive)}
										checked={isActive}
									/>
								</label>
							</div>
						</div>
						<div className="column">
							<div className="row">
								<div className="upload-wrapper">
									<ImageUpladComponent
										htmlFor="oil-image-upload"
										image={image ? image : updateImage ? updateImage : null}
									>
										<span>
											<AiOutlineCloudUpload />
											Carregar imagem
										</span>
										<input
											type="file"
											id="oil-image-upload"
											accept="image/*"
											hidden
											onChange={onFileChange}
										/>
									</ImageUpladComponent>
									<div className="image-wrapper">
										{image ? (
											<img src={URL.createObjectURL(image)} alt="nova imagem" />
										) : updateImage ? (
											<img src={updateImage} alt="nova imagem" />
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
					<section className="row">
						<ContainerD>
							<SunEditor lang="pt_br" height="50px" width="50px" />
						</ContainerD>
					</section>
					<div className="content-button">
						<Button type="submit">{product ? "Editar" : "Cadastrar"}</Button>
					</div>
				</form>
			</Container>
		</div>
	);
}
