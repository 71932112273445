import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 300px;
    padding-top: 30px;
    margin-top: auto;

    button {
        height: 40px;
        background: #2020e6;
        color: white;
        width: 40px;
        border: 1px solid #2020e6;
        display: flex;
        align-items: center;
        justify-content: center;

        :disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }

        :first-of-type {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        :last-of-type {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    input {
        border: 1px solid #ececec;
        height: 40px;
        text-align: center;
        width: 100%;
        min-width: 100px;
        outline: none;

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        [type="number"] {
            appearance: textfield;
        }
    }
`;
