import api from ".";

export async function createSettings(orgId: string, data: any) {
	let obj = {
		data,
	};
	const response = await api.post(`/organization/${orgId}/settings`, obj);

	return response.data;
}
export async function updateSettings(
	orgId: string,
	settingsId: string,
	data: any
) {
	let obj = {
		data,
	};
	const response = await api.put(
		`/organization/${orgId}/settings/${settingsId}`,
		obj
	);

	return response.data;
}
export async function listSettings(orgId: string) {
	const response = await api.get(`/organization/${orgId}/settings`);

	return response.data;
}
