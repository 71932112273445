import styled from "styled-components";

interface ContainerProps {
    className?: string;
}

export const Container = styled.section<ContainerProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #e1e1e1;
`;
