import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	iframe {
		z-index: -1 !important;
	}

	body, html {
		min-height: 100vh;
		height: 100%;
		width: 100%;
		font-family: "Roboto", sans-serif;
	}

	#root {
		width: 100%;
		height: 100%;
	}

	ul {
		list-style: none;
	}

	a {
		color: #1ea418;
		text-decoration: none;

		&:visited {
			color: #1ea418;
			text-decoration: none;
		}
	}

	.omega {
		padding: 20px;

		a {
			display: flex;
			flex-flow: row wrap;
			font-size: 10px!important;
		}
	}

	:root{
		--primary-blue:#2020e6;
		--primary-white:#FFF;
		--primary-gray:#1c1c1c;
		--primary-green:#1ea418;

		--text-description: #333;

		--alert-primary:#FF0000;
		--alert-secundary:#ff8400;
		--alert-tertiary:#fbff00;

		--border:rgba(0,0,0,.2);

		--text-body:  400 16px 'Roboto', sans-serif;
		--text-medium: 500 20px 'Roboto', sans-serif;
		--text-title:  700 24px 'Roboto', sans-serif;
	}

	nav ul li a {
		:hover{
			color: var(--primary-blue) !important;
		}
	}

`;

export const Background = styled.section`
	display: flex;
	flex: 1 1 100%;
	height: 100%;
	width: 100%;

	img {
		margin: auto auto;
		width: 400px;
		height: 400px;
		object-fit: fill;
	}
`;

export const Divider = styled.div`
	border: 1px solid rgba(21, 21, 21, 0.6);
	height: 50%;
	width: 1px;
	margin: auto auto;
`;
