import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 20px;

    @media (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
`;
export const ContainerData = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
`;

export const Data = styled.div`
    display: flex;
    width: 100%;
    padding: 20px;
    align-items: center;
    gap: 40px;

    h4 {
        text-align: start;
        font-weight: 400;
        color: #999999;
        width: 100%;
        max-width: 150px;
    }
    p {
        text-align: start;
    }

    @media (max-width: 850px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        padding: 10px;
    }
`;

export const ContainerImage = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        object-fit: cover;
        height: 100px;
    }
`;
