import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Add, Container, Content, Header, PTable } from "./styles";
import { Loading } from "../../components/Loading";
import { Modal } from "../../components/Modal";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { FiMoreVertical, FiRefreshCw } from "react-icons/fi";
import { FormCollaborator } from "../../components/Forms/Collaborator";
import { PropsCollaborator } from "../../utils/types/collaborator";

type Props = {
    collaborator: PropsCollaborator[];
    paginate?: number;
    page?: number;
    setPage?: Function;
    perPage?: number;
    setFilter: Function;
    update?: () => void;
    id?: string;
    stateLoading?: boolean;
};

export function TableCollaborator(props: Props) {
    var {
        id = "modal",
        collaborator,
        page,
        setPage,
        paginate,
        // perPage,
        update,
        stateLoading,
        setFilter,
    } = props;

    const [collab, setCollab] = useState<PropsCollaborator>();
    const [loading, setLoading] = useState<boolean>(stateLoading);
    const [modal, setModal] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(stateLoading);
    }, [stateLoading]);

    function handleOutSideClick(e: any) {
        if (e.target.id === id) setModal(false);
    }
    function handleUpdate() {
        if (update) {
            update();
            setModal(false);
        }
    }
    function handleCancel() {
        setModal(false);
    }

    const handleChange = (item: PropsCollaborator) => {
        setCollab(item);
        setModal(true);
    };

    const renderItems = (data: PropsCollaborator[]) => {
        let arr = [] as any;

        data &&
            // eslint-disable-next-line array-callback-return
            data.map((item, index) => {
                arr.push(
                    <tr key={item?._id}>
                        <td>{index + 1}</td>

                        <td>{item?.name}</td>
                        <td>{item?.permission}</td>

                        <td>
                            <i>
                                <FiMoreVertical
                                    onClick={() => handleChange(item)}
                                />
                            </i>
                        </td>
                    </tr>
                );
            });
        return arr;
    };
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <Container>
            <Content>
                <Header>
                    <div className="title">
                        <h1>Colaboradores</h1>
                    </div>
                    <div className="button-content">
                        <div>
                            <input
                                type="text"
                                className="input"
                                placeholder="Buscar"
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </div>
                        <button onClick={update}>
                            <FiRefreshCw />
                        </button>

                        <button
                            onClick={() => navigate("/colaborador/adicionar")}
                            title="Adicionar novo colaborador"
                        >
                            <Add />
                        </button>
                        {/* <button>Adicionar em lote</button> */}
                    </div>
                </Header>
                {loading ? (
                    <Loading
                        type="spin"
                        width="10%"
                        height="10%"
                        color="var(--primary-green)"
                    />
                ) : (
                    <>
                        <PTable>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Permissão</th>
                                </tr>
                            </thead>
                            <tbody>{renderItems(collaborator)}</tbody>
                        </PTable>
                        <footer>
                            <Stack spacing={2} style={{ alignItems: "center" }}>
                                <Pagination
                                    count={paginate}
                                    showFirstButton
                                    showLastButton
                                    page={page}
                                    onChange={handlePageChange}
                                />
                            </Stack>
                        </footer>
                    </>
                )}
                {modal && (
                    <Modal
                        id={id}
                        onClick={handleOutSideClick}
                        setState={setModal}
                        title="Atualizar colaborador"
                    >
                        <FormCollaborator
                            collaborator={collab}
                            onUpdate={handleUpdate}
                            cancel={handleCancel}
                        />
                    </Modal>
                )}
            </Content>
        </Container>
    );
}
