import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { organizationUpdate } from "../../../services/organizationUpload-service";
import { PropsCompany } from "../../../store/user";
import { Button } from "../../StyledComponents";
import {
    FormButtonContainer,
    FormContainer,
    FormStep,
} from "../User/FormUserSteps/styles";

type Props = {
    nextStep: () => void;
    backStep?: () => void;
    company?: PropsCompany;
    loading?: boolean;
};

export default function FormMarketplace(props: Props) {
    const { nextStep, backStep, company } = props;
    const [loading, setLoading] = React.useState(false);

    const [values, setValues] = React.useState({
        category: company?.organization?.category || "",
        qtdProdServ: company?.organization?.qtdProdServ || "",
        useErp: company?.organization?.useErp || false,
        usePdv: company?.organization?.usePdv || false,
    });

    const {
        handleSubmit,
        clearErrors,
        formState: { errors },
    } = useForm();

    async function registerMarketplace() {
        setLoading(true);

        const doc = {
            category: values.category,
            qtdProdServ: values.qtdProdServ,
            useErp: values.useErp,
            usePdv: values.usePdv,
        };

        const response = await organizationUpdate(
            company.organization._id,
            doc
        );

        if (!response.error) {
            nextStep();
            return response;
        }

        setLoading(false);
    }

    const handleChange =
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: (event.target as HTMLInputElement).value,
            });
        };

    return (
        <FormContainer>
            <FormStep onSubmit={handleSubmit(registerMarketplace)}>
                <div className="column equal-column">
                    <FormControl
                        sx={{
                            marginBottom: "1rem",
                        }}
                    >
                        <Typography>
                            Selecione o tipo de seu comércio
                        </Typography>
                        <TextField
                            variant="outlined"
                            required
                            select
                            aria-required
                            value={values?.category}
                            onChange={handleChange("category")}
                            onFocus={() => clearErrors("category")}
                            error={!!errors?.category?.message}
                            helperText={errors?.category?.message?.toString()}
                            sx={{
                                display: "flex",
                                flex: 0.6,
                            }}
                            size="small"
                        >
                            <MenuItem value="oilChange">Troca de óleo</MenuItem>
                            <MenuItem value="gasStation">
                                Posto de gasolina
                            </MenuItem>
                            <MenuItem value="carWash">Lava rápido</MenuItem>
                            <MenuItem value="automotiveCenter">
                                Centro automotivo
                            </MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl
                        sx={{
                            marginBottom: "1rem",
                        }}
                    >
                        <Typography>
                            Quantos produtos/serviços vende em sua loja?
                        </Typography>
                        <TextField
                            variant="outlined"
                            required
                            select
                            aria-required
                            value={values?.qtdProdServ}
                            onChange={handleChange("qtdProdServ")}
                            onFocus={() => clearErrors("qtdProdServ")}
                            error={!!errors?.qtdProdServ?.message}
                            helperText={errors?.qtdProdServ?.message?.toString()}
                            sx={{
                                display: "flex",
                                flex: 0.6,
                            }}
                            size="small"
                        >
                            <MenuItem value="1-50">1 a 50</MenuItem>
                            <MenuItem value="51-200">51 a 200</MenuItem>
                            <MenuItem value="201-500">201 a 500</MenuItem>
                            <MenuItem value="500+">acima de 500</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div className="column equal-column">
                    <div className="input-div">
                        <FormControl>
                            <Typography>
                                Você utiliza sistema para controle de estoque?
                            </Typography>
                            <RadioGroup
                                row
                                value={values?.useErp}
                                onChange={handleChange("useErp")}
                            >
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="Não"
                                />
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Sim"
                                />
                            </RadioGroup>
                        </FormControl>
                        {errors?.useErp?.message && (
                            <FormHelperText error>
                                {errors?.useErp?.message?.toString()}
                            </FormHelperText>
                        )}
                    </div>
                    <div className="input-div">
                        <FormControl>
                            <Typography>
                                Você utiliza sistema de caixa em sua loja(PDV)?
                            </Typography>
                            <RadioGroup
                                row
                                value={values?.usePdv}
                                onChange={handleChange("usePdv")}
                            >
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="Não"
                                />
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Sim"
                                />
                            </RadioGroup>
                        </FormControl>
                        {errors?.usePdv?.message && (
                            <FormHelperText error>
                                {errors?.usePdv?.message?.toString()}
                            </FormHelperText>
                        )}
                    </div>
                </div>

                <FormButtonContainer className="form-button-container">
                    <Button
                        disabled={loading}
                        type="button"
                        onClick={backStep}
                        outline
                    >
                        Voltar
                    </Button>
                    <Button type="submit" disabled={loading}>
                        {loading ? "Enviando..." : "Próximo"}
                    </Button>
                </FormButtonContainer>
            </FormStep>
        </FormContainer>
    );
}
