import React, { useCallback, useEffect } from "react";
import {
	BiCalendar,
	BiDoughnutChart,
	BiRevision,
	BiWalletAlt,
} from "react-icons/bi";
import { AuthContainer } from "../Containers";

import { Content } from "./styles";

import { CardInfo } from "../../components/CardInfo";
import { AiOutlineSchedule } from "react-icons/ai";

import { FormUserSteps } from "../../components/Forms/User/FormUserSteps";
import { CardsWrapper } from "../../components/CardsWrapper";
import { listSchedules } from "../../services/schedule-service";
import moment from "moment";
import { getBalance } from "../../services/finance-service";
import { useAppSelector } from "../../store/hooks";

interface ISchedules {
	currentPage: number;
	filteredItems: number;
	items: [];
	itemsTotal: number;
	page: number;
	paginate: null;
	transactionCode: string;
}

interface IBalance {
	balance: number;
}

export function Home() {
	const { data } = useAppSelector((state) => state.user);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [schedules, setSchedules] = React.useState<ISchedules>({
		currentPage: 0,
		filteredItems: 0,
		items: [],
		itemsTotal: 0,
		page: 0,
		paginate: null,
		transactionCode: "",
	});
	const [balance, setBalance] = React.useState<IBalance>({
		balance: 0,
	});

	const handleSchedules = useCallback(
		async (date: string) => {
			setLoading(true);
			const orgId = data?.organization?._id;
			const response = await listSchedules(orgId, {
				startDate: date,
			});
			if (!response.error) {
				setSchedules(response.data);
				return setLoading(false);
			}
			setLoading(false);
		},
		[data?.organization?._id]
	);

	const handleBalance = async () => {
		setLoading(true);
		const response = await getBalance();
		if (!response?.error) {
			setBalance(response?.data?.saldo);
			return setLoading(false);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (data?.organization?.status === "accepted") {
			const today = moment().format("YYYY-MM-DD");
			handleSchedules(today);
			handleBalance();
			if (data) {
				setLoading(false);
			}
		}
		setLoading(false);
	}, [data, handleSchedules]);

	return (
		<AuthContainer
			pageTitle={
				data?.organization?.status === "awaiting" ? "Cadastro" : "Dashboard"
			}
			loading={loading}
		>
			<Content>
				{data?.organization?.status === "awaiting" && (
					<FormUserSteps
						setCompany={data}
						stepLocation={data?.organization?.registrationStep || 0}
					/>
				)}
				{data?.organization?.status === "accepted" && (
					<>
						<CardsWrapper className={"dashboard"}>
							<CardInfo
								color="blue"
								label="Total"
								value={balance?.balance}
								money
								subtitle="Saldo do mês"
								icon={<BiDoughnutChart />}
								subtitleIcon={<BiRevision />}
							/>
							<CardInfo
								color="green"
								label="Total"
								value={balance?.balance}
								money
								subtitle="Saque disponível"
								icon={<BiWalletAlt />}
							/>
							<CardInfo
								color="orange"
								value={schedules?.itemsTotal}
								subtitle="Agendamentos do dia"
								icon={<AiOutlineSchedule />}
								subtitleIcon={<BiCalendar />}
							/>
						</CardsWrapper>
					</>
				)}
			</Content>
		</AuthContainer>
	);
}
