import api from ".";
//Criação de produtos

export async function createProduct(orgId: string, data: any) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let obj = {
        data,
    };
    const response = await api.post(`/seller/${orgId}/product`, obj);
    return response.data;
}

export async function imageUpload(data: any) {
    const response = await api.post("/product/imageupload", data);
    return response.data;
}

//Listagem de todos os produtos

export async function listProducts(orgId: any, params: object = null) {
    const response = await api.get(`/seller/${orgId}/product`, { params });
    return response.data;
}

export async function listAdminProduct(params: object = null) {
    const response = await api.get(`/product`, { params });
    return response.data;
}

export async function listMinStockProducts(orgId: string) {
    const response = await api.get(`/seller/${orgId}/minimumstock`);
    return response.data;
}

//Pegar um único produto

export async function getProduct(id: any) {
    const response = await api.get(`/product/${id}`);
    return response.data;
}

//editar produto

export async function updateProduct(orgId: string, id: string, data: any) {
    let obj = {
        data,
    };
    const response = await api.put(`/seller/${orgId}/product/${id}`, obj);
    return response.data;
}

//deletar
export async function deleteProduct(orgId: string, id: string) {
    const response = await api.delete(`/seller/${orgId}/product/${id}`);

    return response.data;
}

/**
 * list
 * getById
 * update
 *
 */
