import api from '.'

export async function listSales(orgId, params: object = null){
    const response = await api.get(`/sales/organization/${orgId}`, { params })
    return response.data    
}

export async function updateSale(orgId, params) {
    const data = params
    const response = await api.patch(`/sales/organization/${orgId}/sale/${params.id}`, { data })
    return response.data 
}


export async function getCSV(orgId, params) {
    const response = await api.get(`/sales/${orgId}/report-xlsx`, { params })
    return response.data 
}

