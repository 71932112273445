import { Drawer } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FaBoxOpen } from "react-icons/fa";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { CardInfo } from "../../components/CardInfo";
import { CardsWrapper } from "../../components/CardsWrapper";

import { FormProductSale } from "../../components/Forms/ProductSale";
import { handleToast } from "../../components/HandleToast";
import { Table } from "../../components/Table";
import { listCategory } from "../../services/category-service";
import {
	deleteProductSale,
	getProductsSales,
} from "../../services/product-sales";
import { listProducts } from "../../services/product-service";
import { listPromotions } from "../../services/promotion-service";
import { PropsCategory } from "../../utils/types/categories/CategoriesType";
import { PropsProductSale } from "../../utils/types/product/ProductTypes";
import { PropsPromotions } from "../../utils/types/promotion/PromotionType";
import { AuthContainer } from "../Containers";
import { Content, DrawerContent, LowCard } from "./styles";
import { useAppSelector } from "../../store/hooks";

type PropsValues = {
	items: PropsPromotions[];
	page: number;
	paginate: number;
	perPage: number;
	itemsTotal: number;
};

type PropsSaleProducts = {
	items: PropsProductSale[];
	page: number;
	paginate: number;
	perPage: number;
	itemsTotal: number;
	itemsSold: number;
	stockValueTotal: number;
	stockValueTotalPerPage: number;
};

export function Promotions() {
	const { data } = useAppSelector((state) => state.user);

	const [loading, setLoading] = useState<boolean>(false);
	const [, setLoadingActive] = useState<boolean>(false);
	const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
	const [page, setPage] = useState<number>(1);
	const [promotionPage] = useState<number>(1);
	const [promotionActivePage] = useState<number>(1);
	const [, setFilter] = useState(null);
	const [filterProduct] = useState<string>();
	const [filterPromotion] = useState<string>();
	const [filterPomotionActive] = useState<string>();
	const [filterProductForm] = useState<string>();
	const [filterProductFormTable, setFilterProductFormTable] =
		useState<string>("produto");
	const [filterPromotionForm] = useState<string>();
	const [filterPromotionActiveForm] = useState<string>();
	const [getTypeFilterProductSale] = useState<string>("name");
	const [getTypeFilterPromotion] = useState<string>("name");
	const [pageAddProduct, setPageAddProduct] = useState<number>(1);
	const [, setRowsPerPage] = React.useState(10);

	const [, setValues] = useState<PropsValues>({
		items: [],
		page: 1,
		paginate: 0,
		perPage: 0,
		itemsTotal: 0,
	});
	const [, setPromotionsActive] = useState<PropsValues>({
		items: [],
		page: 1,
		paginate: 0,
		perPage: 0,
		itemsTotal: 0,
	});
	const [productsSale, setProductsSale] = useState<PropsSaleProducts>({
		items: [],
		page: 1,
		paginate: 0,
		perPage: 0,
		itemsTotal: 0,
		itemsSold: 0,
		stockValueTotal: 0,
		stockValueTotalPerPage: 0,
	});
	const [valuesProducts, setValuesProducts] = useState({
		items: [],
		itemsTotal: 0,
		stockValueTotal: 0,
		stockValueTotalPerPage: 0,
		perPage: 0,
		paginate: 0,
		page: 1,
	});
	const [categories, setCategories] = useState<PropsCategory[]>([]);
	const [edit, setEdit] = useState(null);
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const [cardSale, setCardSale] = useState(0);

	useEffect(() => {
		if (filterPomotionActive) {
			loadPromotionActive({
				title: filterPomotionActive,
				isActive: true,
			});
		} else {
			loadPromotionActive({ page: promotionActivePage, isActive: true });
		}
	}, [promotionActivePage, filterPomotionActive]);

	useEffect(() => {
		if (!openDrawer && edit) {
			setEdit(null);
		}
	}, [openDrawer, edit]);

	useEffect(() => {
		if (getTypeFilterPromotion === "isActiveTrue") {
			if (filterPromotion) {
				loadPromotion({ isActive: true, title: filterPromotion });
			} else {
				loadPromotion({ isActive: true });
			}
		} else if (getTypeFilterPromotion === "isActiveFalse") {
			if (filterPromotion) {
				loadPromotion({ isActive: false, title: filterPromotion });
			} else {
				loadPromotion({ isActive: false });
			}
		} else {
			if (filterPromotion) {
				if (getTypeFilterPromotion === "name") {
					loadPromotion({ title: filterPromotion });
				}
			} else {
				loadPromotion({ page: promotionPage });
			}
		}
	}, [promotionPage, filterPromotion, getTypeFilterPromotion]);

	useEffect(() => {
		handleCategories({ filter: "all" });
		loadProducts({ filter: "all" });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (filterProduct) {
			if (getTypeFilterProductSale === "name") {
				loadProductsSale({ name: filterProduct });
			} else if (getTypeFilterProductSale === "category") {
				loadProductsSale({ category: filterProduct });
			}
		} else {
			loadProductsSale({ page });
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, filterProduct, getTypeFilterProductSale]);

	const loadProducts = useCallback(
		async (_data) => {
			setLoading(true);
			const response = (await listProducts(
				data?.organization?._id,
				_data
			)) as any;
			if (!response.error) {
				setValuesProducts(response.data);
				return setLoading(false);
			}
			setLoading(false);
		},
		[data?.organization?._id]
	);
	async function handleCategories(data) {
		const response = await listCategory(data);
		if (!response.error) {
			setCategories(response.data.items);
		}
	}

	async function loadPromotion(data) {
		setLoading(true);
		const response = await listPromotions(data);

		if (!response.error) {
			setValues(response.data);
			return setLoading(false);
		}
		setLoading(false);
	}

	async function loadPromotionActive(data) {
		setLoadingActive(true);
		const response = await listPromotions(data);

		if (!response.error) {
			setPromotionsActive(response.data);
			setLoadingActive(false);
			return;
		}

		return setLoadingActive(false);
	}

	async function loadProductsSale({ page, filterProduct }: any) {
		setLoadingProduct(true);
		const response = await getProductsSales(data.organization?._id, {
			page,
			filterProduct,
		});
		if (!response.error) {
			setCardSale(response?.data?.itemsTotal);
			setProductsSale(response.data);
			setLoadingProduct(false);
		}
	}

	function handleUpdate() {
		loadProductsSale({ page });
	}

	const handleChangeRowsPerPage = (rows: string, page: number) => {
		setRowsPerPage(parseInt(rows));
		setPage(page);
	};

	const handleRefresh = async () => {
		try {
			setLoading(true);
			await loadProductsSale({ page });
		} catch (error) {
			handleToast(true, error.message);
		} finally {
			setLoading(false);
		}
	};

	async function handleDelete(id: string) {
		try {
			setLoading(true);
			await deleteProductSale(data?.organization?._id, id);
			setLoading(false);
		} catch (error) {
			setLoading(false);
		} finally {
			await handleRefresh();
		}
	}

	const handleChangeFilter = (object: {}): void => {
		Object.keys(object).forEach((item) => {
			if (item.toLocaleLowerCase() === "Produto") {
				setFilterProductFormTable("name");
			}
			if (item.toLocaleLowerCase() === "marca") {
				setFilterProductFormTable("brand");
			}
			if (item.toLocaleLowerCase() === "categoria") {
				setFilterProductFormTable("category");
			}
			let params = {
				[getTypeFilterProductSale]: object[item],
			};
			setFilter(params);
		});
	};

	useEffect(() => {
		if (filterProductForm) {
			if (filterProductFormTable === "brand") {
				loadProducts({ brand: filterProductForm });
			} else if (filterProductFormTable === "composition") {
				loadProducts({ composition: filterProductForm });
			}
		} else if (filterPromotionForm) {
			loadProducts({ name: filterPromotionForm });
		} else if (filterPromotionActiveForm) {
			loadProducts({ name: filterPromotionActiveForm });
		} else {
			loadProducts({ page: pageAddProduct });
		}
	}, [
		pageAddProduct,
		filterProductForm,
		filterPromotionForm,
		filterPromotionActiveForm,
		filterProductFormTable,
		loadProducts,
	]);

	return (
		<AuthContainer pageTitle="Produtos à venda" loading={loading}>
			<Content>
				<CardsWrapper className="sales-products-cards-wrapper">
					<CardInfo
						color="green"
						value={productsSale?.itemsSold || "0"}
						label="Total"
						subtitle="Produtos vendidos"
						icon={<HiOutlineShoppingCart />}
						className="card"
					/>
					<CardInfo
						color="blue"
						value={cardSale}
						label="Cadastros"
						subtitle="Produtos à venda"
						icon={<FaBoxOpen />}
						className="card"
					/>
				</CardsWrapper>
				<LowCard>
					{!loadingProduct && (
						<Table
							add
							columns={[
								"title",
								"description",
								"qtdServices",
								"price",
								"actions",
							]}
							header={[
								"#",
								"Produto",
								"Descrição",
								"Quantidade",
								"Preço",
								"Ações",
							]}
							options={["Produto", "Quantidade", "Preço"]}
							items={productsSale?.items}
							handleDelete={handleDelete}
							handleChange={(value) => handleChangeFilter(value)}
							handleEdit={(id, item) => {
								setEdit(item);
								setOpenDrawer(true);
							}}
							onPageChange={(page) => setPage(page)}
							onRowsPerPageChange={(rows, page) =>
								handleChangeRowsPerPage(rows, page)
							}
							buttonTitle="Novo produto"
							onClickAdd={() => setOpenDrawer(true)}
						/>
					)}
				</LowCard>
			</Content>
			<Drawer
				anchor="right"
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
			>
				<DrawerContent>
					<FormProductSale
						products={valuesProducts?.items}
						item={edit}
						onUpdate={handleUpdate}
						cancel={() => {
							setOpenDrawer(false);
							setEdit(null);
						}}
						category={categories}
						getTypeFilter={getTypeFilterProductSale}
						page={pageAddProduct}
						setPage={(newPage) => setPageAddProduct(newPage)}
						paginate={pageAddProduct}
					/>
				</DrawerContent>
			</Drawer>
		</AuthContainer>
	);
}
