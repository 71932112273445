import { AuthContainer } from "../../Containers";
import {
	Container,
	ContainerData,
	ContainerImage,
	Content,
	Data,
} from "./styles";
import moment from "moment";
import { useAppSelector } from "../../../store/hooks";

export function DocumentsPage() {
	const { data } = useAppSelector((state) => state.user);

	return (
		<AuthContainer pageTitle="Configurações | Documentos">
			<Container>
				{data.organization?.docs?.map((item) => {
					return (
						<Content key={item._id}>
							<ContainerData>
								<Data>
									<h4>Nome:</h4>
									<p>{item.title}</p>
								</Data>
								<Data>
									<h4>Tipo do documento:</h4>
									<p>{item.docType}</p>
								</Data>
								<Data>
									<h4>Criado em:</h4>
									<p>{moment(item.createdAt).format("LLL")}</p>
								</Data>
								<Data>
									<h4>Atualizado em:</h4>
									<p>{moment(item.updatedAt).format("LLL")}</p>
								</Data>
							</ContainerData>
							<ContainerImage>
								<img
									src={`data:${item.mimetype};base64,${item.image64}`}
									alt="Imagem do documento"
								/>
							</ContainerImage>
						</Content>
					);
				})}
			</Container>
		</AuthContainer>
	);
}
