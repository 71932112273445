import { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";

import { AuthForm, SignInStyled } from "./styles";

import { Loading } from "../../components/Loading";
import { UnAuthContainer } from "../Containers";

import { authUser } from "../../store/token";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { handleToast } from "../../components/HandleToast";
import { Button } from "../../components/StyledComponents";

const schema = yup.object().shape({
	email: yup.string().email("E-mail inválido").required("E-mail obrigatório"),
	password: yup.string().required("Senha obrigatória"),
});

export function SignIn() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { loading, data, error } = useAppSelector((state) => state.token);

	useEffect(() => {
		if (data) navigate("/dashboard");
	}, [data, navigate]);

	useEffect(() => {
		if (error) handleToast(true, error);
	}, [error]);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		reValidateMode: "onBlur",
	});

	const handleSignIn = useCallback(
		async (values: { email: string; password: string }) => {
			if (values) {
				const response: any = await dispatch(authUser(values));

				handleToast(
					response?.payload?.error,
					response?.payload.message || "Ocorreu um erro"
				);
			} else {
				handleToast(true, "Por favor, insira suas credênciais");
			}
		},
		[dispatch]
	);

	return (
		<UnAuthContainer>
			<SignInStyled>
				<AuthForm onSubmit={handleSubmit(handleSignIn)}>
					<h1>Faça seu login</h1>
					<TextField
						fullWidth
						type="email"
						label="Email"
						size="small"
						variant="outlined"
						{...register("email")}
						helperText={errors?.email?.message?.toString()}
					/>

					<TextField
						fullWidth
						type="password"
						label="Senha"
						size="small"
						variant="outlined"
						{...register("password")}
						helperText={errors.password?.message?.toString()}
					/>

					<Link to="/recuperarsenha" className="recorver-link">
						Esqueceu a senha?
					</Link>

					<Button type="submit" width="100%">
						{loading ? (
							<Loading
								type={"bars"}
								color={"#fff"}
								width="50px"
								height="40px"
							/>
						) : (
							<>Entrar</>
						)}
					</Button>

					<p>
						Ainda não tem uma conta? &nbsp;
						<Link to="/cadastro" className="link-register">
							Clique aqui
						</Link>
					</p>
				</AuthForm>
			</SignInStyled>
		</UnAuthContainer>
	);
}
