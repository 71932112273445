import styled from "styled-components";

export const CalendarContent = styled.div`
    display: flex;
    max-width: 100%;

    .calendar-content {
        display: flex;
        max-width: 100%;
        /* margin: 0 auto; */
        /* margin-top: 5em; */
    }

    .icon {
        font-family: "Material Icons", serif;
        font-style: normal;
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;

        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: "liga";
    }

    /** Grid **/

    .row {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }

    .row-middle {
        align-items: center;
    }

    .col {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
        text-align: center;
    }

    .col-start {
        justify-content: center;
        text-align: left;
    }

    .col-center {
        justify-content: center;
        text-align: center;
    }

    .col-center-header {
        display: flex;
        flex-direction: column;
        width: 30px;
        height: 50px;
        justify-content: center;
        text-align: center;
    }

    .col-end {
        justify-content: center;
        text-align: right;
    }

    /** Calendar **/

    .calendar {
        display: block;
        position: relative;
        width: 100%;
        background: var(--neutral-color);
        border-color: var(--border-color);
    }

    .calendar .header {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 115%;
        padding: 1.5em 0;
        border-bottom: 1px solid var(--border-color);
    }

    .calendar .header .icon {
        cursor: pointer;
        transition: 0.15s ease-out;
    }

    .calendar .header .icon:hover {
        transform: scale(1.75);
        transition: 0.25s ease-out;
        color: var(--main-color);
    }

    /* .calendar .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar .header .icon:last-of-type {
    margin-right: 1em;
} */

    .calendar .days {
        text-transform: uppercase;
        font-weight: 900;
        color: var(--text-color-light);
        font-size: 12px;
        padding: 1em 0;
        border-bottom: 1px solid var(--border-color);
    }

    .calendar .body .cell {
        position: relative;
        height: 3.5em;
        border-right: 1px solid var(--border-color);
        overflow: hidden;
        cursor: pointer;
        background: var(--neutral-color);
        transition: 0.25s ease-out;
    }

    .calendar .body .cell:hover {
        background: #e2e2e2;
        /* transition: 0.5s ease-out; */
    }

    .calendar .body .selected {
        border: 1px solid #1a8fff;
        /* border-left: 10px solid transparent; */
        /* border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%); */
        /* border-image-slice: 1; */
    }

    /* .calendar .body .row {
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
    border-bottom: none;
}

.calendar .body .cell:last-child {
    border-right: none;
} */

    .calendar .body .cell .number {
        position: relative;
        text-align: center;
        font-size: 12px;
        line-height: 1;
        top: 0.75em;
        /* right: .75em; */
        font-weight: 400;
    }

    /* .calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
} */

    /* .calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
} */

    .calendar .body .cell:hover .bg,
    .calendar .body .selected .bg {
        opacity: 0.05;
        transition: 0.5s ease-in;
    }

    /* .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
} */
`;
