import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex: 1 1 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100%;


	background-color: #e1e1e1;
`;
