import { useEffect, useMemo, useState } from "react";
import {
	Container,
	Description,
	ImageComponent,
	Left,
	Right,
	Wrapper,
} from "./styles";
import NoImage from "../../../../assets/images/no-image-lg.png";
import PreviewHeader from "../../../../assets/images/preview-header.png";
import PreviewCalendar from "../../../../assets/images/preview-calendar.png";
import Counter from "./Counter";
import TabsInfo from "./TabsInfo";

interface IProps {
	name: string;
	images?: any[];
	price: string;
	installments: number;
	organization: string;
	address: any;
	qty: number;
	technicalDescription: string;
	description: string;
}

const Preview: React.FC<IProps> = ({
	images,
	price = "0",
	installments = 1,
	organization,
	name,
	address,
	qty = 1,
	technicalDescription,
	description,
}) => {
	const [selectedImage, setSelectedImage] = useState(
		images?.length ? images[0] : null
	);

	const [counter, setCounter] = useState(1);

	const organizationAddress = useMemo(() => {
		const { street, complement, number, district } = address;

		const validFields = [street, complement, number, district].filter(
			(item) => item
		);

		return validFields.join(", ");
	}, [address]);

	useEffect(() => {
		return () =>
			images.forEach(
				(file) => file.preview && URL.revokeObjectURL(file.preview)
			);
	}, [images]);

	const formatToReal = (value: number) =>
		value.toLocaleString("pt-br", { style: "currency", currency: "BRL" });

	return (
		<Wrapper>
			<header>
				<img src={PreviewHeader} alt="Cabeçalho" />
			</header>
			<Container>
				<div className="center">
					<Left>
						<ImageComponent>
							{selectedImage && (
								<img
									src={
										selectedImage.preview
											? URL.createObjectURL(selectedImage)
											: `data:image/png;base64, ${selectedImage?.image64}`
									}
									alt="Imagem do produto"
									onLoad={() =>
										selectedImage.preview && URL.revokeObjectURL(selectedImage)
									}
								/>
							)}
							{!selectedImage && <img src={NoImage} alt="Imagem do produto" />}
							<div>
								{images.map((item) => (
									<button onClick={() => setSelectedImage(item)}>
										<img
											src={
												item.preview
													? URL.createObjectURL(item)
													: `data:image/png;base64, ${item?.image64}`
											}
											alt="Imagem do produto"
											onLoad={() => item.preview && URL.revokeObjectURL(item)}
										/>
									</button>
								))}
							</div>
						</ImageComponent>
						<Description>
							<h3>{name}</h3>
							<span>
								{installments}x de{" "}
								{formatToReal(Number(price || "0") / (installments || 1))}
							</span>
							<small>{formatToReal(Number(price || "0"))}</small>
							<div className="company">
								<span>
									Vendido por: <strong>{organization}</strong>
								</span>
								<span>
									Localização: <strong>{organizationAddress}</strong>
								</span>
								<span>
									Distância: <strong>2km</strong>
								</span>
							</div>
							<Counter counter={counter} setCounter={setCounter} amount={qty} />
						</Description>
					</Left>
					<Right>
						<span className="price">{formatToReal(Number(price || "0"))}</span>
						<span className="parc">
							{installments}x de{" "}
							{formatToReal(Number(price || "0") / (installments || 1))}
						</span>
						<img src={PreviewCalendar} alt="Calendário" />
					</Right>
				</div>
				<TabsInfo
					technicalInformation={technicalDescription}
					description={description}
				/>
			</Container>
		</Wrapper>
	);
};

export default Preview;
