import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    .preview-document-container {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;

        margin-top: 10px;
        border-radius: 3px;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100px;

        span {
            white-space: nowrap;
            overflow: hidden;

            text-overflow: ellipsis;
            display: inline-block;
        }

        img {
            border-radius: 3px;
            object-fit: cover;
        }
    }
    /* gap: 3rem; */

    /* .listSale {
        margin-left: 16px;

        box-shadow: 1px 1px 5px 1px var(--primary-gray);
        border-radius: 10px;
        width: 500px;

        overflow-y: auto;
        footer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            margin-right: 10px;
            vertical-align: bottom;
            gap: 10px;
        }
    }

    .limit-name {
        max-width: 15ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    } */

    #select-image {
        width: 200px;
        height: 100px;
    }
`;

export const FormContent = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    .inputSale {
        margin-top: 2.5px;
        width: 300px;
        height: 43px;
        padding: 10px;
        border-radius: 10px;

        /* border: 1px solid rgba(28, 28, 28, 0.25); */

        outline: none;

        font: var(--text-body);
    }

    .drag-image {
        width: 200px;
        height: 100px;
        border: 1px solid black;
    }
`;

export const ContainerTable = styled.div`
    display: flex;
    width: 100%;
`;

export const ContainerForms = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    /* align-items: center; */
    justify-content: center;
`;

export const RowDiv = styled.div`
    display: flex;
    flex-direction: row;

    .button-content {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        gap: 1rem !important;
        margin-top: 10px;
    }
`;
export const ColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* textarea {
        min-height: 100px;
    } */
`;

export const RowInput = styled.div`
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: center;

    > div {
        width: 100%;
    }
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

export const PTable = styled.table`
    border-collapse: collapse;
    font: var(--text-body);
    background: transparent;
    width: 100%;
    th {
        text-align: left;
        padding: 10px;

        background: transparent;
    }
    tbody {
        width: 100%;
        margin-top: 20px;
        tr {
            text-align: left;
            border-top: 1px solid rgba(21, 21, 21, 0.3);

            :hover {
                background-color: rgba(21, 21, 21, 0.1);
            }
            td {
                padding: 6px;
                text-align: left;

                img {
                    max-width: 3vh;
                }

                ul {
                    display: flex;
                    flex-direction: row;
                    div {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }
`;

export const Header = styled.div`
    background-color: var(--primary-blue);
    padding: 5px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: var(--text-medium);
    color: #fff !important;
    .title {
        width: 100%;
        h1 {
            font: var(--text-medium);

            color: #fff;
        }
    }

    .button-content {
        width: 100%;
        display: flex;

        gap: 1rem;
        align-items: center;
        justify-content: flex-end;
        button {
            display: flex;
            align-items: center;
            border: 0;
            padding: 10px;
            border-radius: 10px;
            background-color: transparent;
            color: white;
            cursor: pointer;
        }
        .input {
            color: #fff;
            min-width: 100px;
            max-width: 150px;
            border-radius: 0;
            border: none;
            outline: none;
            background-color: transparent;
            border-bottom: 1px solid #fff;

            ::-webkit-input-placeholder {
                color: #fff;
            }
        }
    }
`;

export const ButtonImage = styled.label`
    display: flex;
    width: 130px;
    height: 40px;
    background-color: #1ea418;
    color: #ffffff;
    cursor: pointer;
    outline: 0;
    border: 1px solid #1ea418;
    opacity: 0.85;
    border-radius: 4px;
    transition: opacity 0.3s;
    text-align: center;
    justify-content: center;
    align-items: center;

    :disabled {
        opacity: 0.7;
    }

    :hover {
        opacity: 1;
    }
`;

export const ContainerModal = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ContainerModalButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-top: 1rem;
    gap: 1rem;
`;
