import api from '.'

export async function getFinancialData(orgId, params: object = null) {
	const response = await api.get(`/financial/seller/${orgId}`, { params })
	return response.data
}

export async function getSales(orgId, params: object = null) {
	const response = await api.get(`/sales/organization/${orgId}`, { params })
	return response.data
}
export async function getGraphic(orgId, params: object = null) {
	const response = await api.get(`/financial/seller/${orgId}/sales-graphic`, { params })
	return response.data
}
