import React, { useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import {
	ColumnDiv,
	Container,
	ContainerForms,
	FormContent,
	RowDiv,
	RowInput,
	Row,
} from "./styles";
import { Input } from "../../../Input";
import { Button } from "../../../StyledComponents";
import { toast } from "react-toastify";
import {
	PropsProducts,
	PropsProductSale,
} from "../../../../utils/types/product/ProductTypes";

import { SelectCustom } from "../../../Select";
import { Modal } from "../../../Modal";
import "suneditor/dist/css/suneditor.min.css";
import DragFile from "../../../DragFile/";
import {
	createProductSale,
	deleteProductSaleImages,
	getProductSaleRating,
	updateProductSale,
	uploadProductSaleImage,
} from "../../../../services/product-sales";
import { Checkbox, FormControlLabel } from "@mui/material";
import Preview from "../Preview";
import RichTextEditor from "../RichTextEditor";
import { useAppSelector } from "../../../../store/hooks";
import Comments from "../../../Comments";

type Props = {
	sugestPrice: number | string;
	cancel: () => void;
	products: PropsProducts[];
	onUpdate: () => void;
	add: (item: any) => void;
	sub: (item: any) => void;
	del: (item: any) => void;
	item?: PropsProductSale;
	addProduct: () => void;
};

const ServiceLabel = {
	OIL: "Troca de óleo",
	OIL_AND_FILTER: "Troca de óleo + filtro",
};

export function FormAddProducSale({ item, cancel, onUpdate }: Props) {
	const { data } = useAppSelector((state) => state.user);

	const [removedFiles, setRemovedFiles] = useState([]);
	const [comments, setComments] = useState([]);

	const [loading, setLoading] = useState(false);

	const [values, setValues] = React.useState({
		carBrand: item?.carBrand || "",
		carName: item?.carName || "",
		composition: item?.composition || "",
		sae: item?.sae || "",
		price: item?.price?.toString() || "",
		installments: item?.installments || "",
		description: item?.description || "",
		qtdServices: item?.qtdServices || 0,
		api: item?.api || "",
		technicalDescription: item?.technicalDescription || "",
		norma: item?.norma || "",
		carYear: {
			to: item?.carYear?.to || "",
			from: item?.carYear?.from || "",
		},
		images: [],
		serviceType: item?.serviceType
			? {
					value: item?.serviceType || "",
					label: ServiceLabel[item?.serviceType] || "",
			  }
			: { value: "", label: "" },
		qtdOil: item?.qtdOil || "",
		title: item?.title || "",
	});

	const previewImages = useMemo(() => {
		const tempArray = [];

		if (item?.images) {
			item?.images
				?.filter((file) => !removedFiles?.find((f) => f?._id === file?._id))
				.forEach((item) => tempArray.push(item));
		}

		if (values?.images?.length) {
			values.images.forEach((item) => tempArray.push(item));
		}

		return tempArray;
	}, [item?.images, removedFiles, values?.images]);

	const [filterAir, setFilterAir] = React.useState<boolean>(
		!!item?.filterType?.includes("FILTER_AIR")
	);
	const [filterGas, setFilterGas] = React.useState<boolean>(
		!!item?.filterType?.includes("FILTER_FUEL")
	);
	const [filterOil, setFilterOil] = React.useState<boolean>(
		!!item?.filterType?.includes("FILTER_OIL")
	);
	const [filterCabin, setFilterCabin] = React.useState<boolean>(
		!!item?.filterType?.includes("FILTER_CABIN")
	);
	const [filterOilActivate, setFilterOilActivate] =
		React.useState<boolean>(false);

	const [modal, setModal] = React.useState<boolean>(false);

	function handleOutSideClick(e: any) {
		if (e.target.id === "preview") setModal(false);
	}

	const { handleSubmit } = useForm();

	function handleToast(error: boolean, message: any) {
		if (error)
			return toast.error(message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

		return toast.success(message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}

	useEffect(() => {
		const fetch = async () => {
			if (item?._id) {
				const res = await getProductSaleRating(
					data?.organization?._id,
					item?._id
				);

				setComments(res?.data?.items);
			}
		};

		fetch();
	}, [data?.organization?._id, item?._id]);

	async function submit() {
		let n = Number(values?.price);
		setLoading(true);

		const idProduct = item?._id;

		const filterType = [];

		const formData = new FormData();

		values?.images?.forEach((image) => {
			formData.append("files", image);
		});

		if (removedFiles.length) {
			try {
				await deleteProductSaleImages(data?.organization?._id, removedFiles);
			} catch (e) {
				return handleToast(
					true,
					e?.response?.data || "Não foi possível remover imagens"
				);
			}
		}

		let images;

		if (values?.images?.length) {
			try {
				const res = await uploadProductSaleImage(
					data?.organization?._id,
					formData
				);

				const prevImages = item?.images || [];

				images = [
					...res?.data?.images,
					...prevImages.filter(
						(file) => !removedFiles?.find((f) => f?._id === file?._id)
					),
				];
			} catch (e) {
				setLoading(false);
				return handleToast(
					true,
					e?.response?.data || "Não foi possível cadastrar imagens"
				);
			}
		}

		if (filterAir) filterType.push("FILTER_AIR");
		if (filterOil) filterType.push("FILTER_OIL");
		if (filterGas) filterType.push("FILTER_FUEL");
		if (filterCabin) filterType.push("FILTER_CABIN");

		const obj = {
			...values,
			price: n,
			description: values.description,
			technicalDescription: values.technicalDescription,
			installments: values.installments,
			filterAir: filterAir,
			filterGas: filterGas,
			filterOil: filterOil,
			composition: values.composition,
			api: values.api,
			norma: values.norma,
			sae: values.sae,
			carName: values.carName,
			carBrand: values.carBrand,
			serviceType: values?.serviceType.value,
			qtdOil: values?.qtdOil,
			filterType,
			images,
			carYear: {
				from: values?.carYear?.from,
				to: values?.carYear?.to,
			},
		};

		if (idProduct) {
			const response = await updateProductSale(
				data.organization?._id,
				idProduct,
				obj
			);
			if (!response.error) {
				handleToast(false, response.message);
			} else {
				setLoading(false);
				return handleToast(true, response.message);
			}

			onUpdate();
		} else {
			const response = await createProductSale(data.organization?._id, obj);

			if (response.error) {
				handleToast(true, response.message);
			} else {
				handleToast(false, response.message);
			}

			onUpdate();
		}

		setLoading(false);
		cancel();
	}

	const handleChange =
		(property: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({
				...values,
				[property]: (event.target as HTMLInputElement).value,
			});
		};

	const handleCheckedFilterAir = () => {
		setFilterAir(!filterAir);
	};
	const handleCheckedFilterGas = () => {
		setFilterGas(!filterGas);
	};
	const handleCheckedFilterOil = () => {
		setFilterOil(!filterOil);
	};
	const handleCheckedFilterCabin = () => {
		setFilterCabin(!filterCabin);
	};

	useEffect(() => {
		if (values?.serviceType?.value === "OIL_AND_FILTER") {
			setFilterOilActivate(true);
		} else {
			setFilterOilActivate(false);
		}
	}, [values?.serviceType]);

	return (
		<Container>
			<ContainerForms>
				<FormContent onSubmit={handleSubmit(submit)}>
					<h4>Título do produto</h4>
					<RowInput>
						<Input
							label="Título"
							mask="text"
							value={values.title}
							type="text"
							fullwidth
							variant="outlined"
							onChange={handleChange("title")}
						/>
					</RowInput>
					<h4>Informações do veículo</h4>
					<Input
						size="small"
						label="Montadora"
						variant="outlined"
						value={values.carBrand}
						onChange={handleChange("carBrand")}
						mask="text"
					/>

					<Input
						size="small"
						label="Modelo"
						variant="outlined"
						value={values.carName}
						onChange={handleChange("carName")}
						mask="text"
					/>
					<RowInput>
						<Input
							size="small"
							label="Ano de"
							variant="outlined"
							value={values.carYear.from}
							onChange={(e) =>
								setValues({
									...values,
									carYear: { ...values.carYear, from: e.target.value },
								})
							}
							mask="text"
						/>
						<Input
							size="small"
							label="Ano para"
							variant="outlined"
							value={values.carYear.to}
							onChange={(e) =>
								setValues({
									...values,
									carYear: { ...values.carYear, to: e.target.value },
								})
							}
							mask="text"
						/>
					</RowInput>
					<h4>Serviço</h4>
					<RowInput>
						<SelectCustom
							fullWidth
							size="small"
							label="Tipo de serviço"
							variant="outlined"
							options={[
								{
									label: "Troca de óleo",
									value: "OIL",
								},
								{
									label: "Troca de óleo + filtro",
									value: "OIL_AND_FILTER",
								},
							]}
							value={values.serviceType}
							onChange={(_, v) => setValues({ ...values, serviceType: v })}
						/>
					</RowInput>

					<h4>Óleo de motor</h4>
					<RowInput>
						<Input
							size="small"
							label="Composição"
							variant="outlined"
							value={values.composition}
							onChange={handleChange("composition")}
							mask="text"
						/>
					</RowInput>
					<ColumnDiv>
						<Input
							size="small"
							label="Viscosidade"
							variant="outlined"
							value={values.sae}
							onChange={handleChange("sae")}
							mask="text"
						/>
						<Input
							size="small"
							label="API"
							variant="outlined"
							value={values.api}
							onChange={handleChange("api")}
							mask="text"
						/>

						<Input
							size="small"
							label="Quantidade de óleo"
							variant="outlined"
							value={values.qtdOil}
							onChange={handleChange("qtdOil")}
							mask="text"
						/>
					</ColumnDiv>
					{filterOilActivate ? (
						<>
							<ColumnDiv>
								<h4
									style={{
										marginBottom: "-10px",
									}}
								>
									Filtro
								</h4>
								<Row
									style={{
										marginBottom: "-20px",
									}}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={filterAir}
												onChange={handleCheckedFilterAir}

												//FILTER_AIR
											/>
										}
										label="Filtro de Ar"
									/>
								</Row>
								<Row
									style={{
										marginBottom: "-20px",
									}}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={filterGas}
												onChange={handleCheckedFilterGas}
											/>
										}
										//FILTER_FUEL

										label="Filtro de combustível"
									/>
								</Row>
								<Row
									style={{
										marginBottom: "-20px",
									}}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={filterOil}
												onChange={handleCheckedFilterOil}
											/>
										}
										label="Filtro de óleo"
									/>
								</Row>
								<Row>
									<FormControlLabel
										control={
											<Checkbox
												checked={filterCabin}
												onChange={handleCheckedFilterCabin}
											/>
										}
										label="Filtro de cabine"
										style={{
											marginBottom: "-20px",
										}}
									/>
								</Row>
							</ColumnDiv>
						</>
					) : (
						<></>
					)}
					<ColumnDiv>
						<h4>Preço do serviço</h4>
						<Input
							label="Preço R$"
							variant="outlined"
							size="small"
							type="number"
							mask="text"
							onChange={handleChange("price")}
							value={values.price}
						/>
						<Input
							size="small"
							label="Parcelas"
							variant="outlined"
							value={values.installments}
							onChange={handleChange("installments")}
							mask="text"
						/>

						<h4>Descrição do serviço e produto</h4>
						<RichTextEditor
							value={values.description}
							setValue={(e) => setValues({ ...values, description: e })}
						/>

						<h4>Informações técnicas do produto</h4>
						<RichTextEditor
							value={values.technicalDescription}
							setValue={(e) =>
								setValues({
									...values,
									technicalDescription: e,
								})
							}
						/>
					</ColumnDiv>
					<DragFile
						description="Imagem do produto à venda"
						accepted={{
							"image/png": [".png"],
							"image/jpeg": [".jpeg"],
							"image/jpg": [".jpg"],
						}}
						background={item?.images[0]?.path}
						onAccept={(f) =>
							setValues({
								...values,
								images: f,
							})
						}
						maxSize={5000000}
						removeInitial={(f) => setRemovedFiles([...removedFiles, f])}
						initialFiles={item?.images?.filter(
							(file) => !removedFiles?.find((f) => f?._id === file?._id)
						)}
					/>

					<ColumnDiv>
						<h4>Quantidade de serviços disponíveis</h4>
						<Input
							placeholder="20"
							variant="outlined"
							size="small"
							mask="text"
							type="number"
							value={values.qtdServices}
							onChange={handleChange("qtdServices")}
						/>
					</ColumnDiv>
					{!!comments?.length && (
						<ColumnDiv>
							<h4>Comentários</h4>
							<Comments comments={comments} />
						</ColumnDiv>
					)}

					<RowDiv>
						<div className="button-content">
							<Button type="button" outline onClick={cancel}>
								Cancelar
							</Button>

							<Button
								onClick={() => setModal(true)}
								type="button"
								backgroundColor="rgb(25, 118, 210)"
							>
								Prévia
							</Button>

							<Button type="submit" disabled={loading}>
								Enviar
							</Button>
						</div>
					</RowDiv>
				</FormContent>
			</ContainerForms>
			{modal && (
				<Modal
					id="preview"
					setState={setModal}
					title="Prévia do anúncio"
					onClick={handleOutSideClick}
					maxHeight={"calc(100vh - 80px)"}
				>
					<Preview
						images={previewImages}
						name={values.title}
						price={values.price}
						installments={values.installments}
						organization={data?.organization?.name}
						address={data?.organization?.address[0]}
						qty={values.qtdServices}
						technicalDescription={values?.technicalDescription}
						description={values?.description}
					/>
				</Modal>
			)}
		</Container>
	);
}
