import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { create } from "../../services/user-services";
import { getUserDataAsync, updateUserAsync } from "./model";

interface IDoc {
	_id?: string;
	filename: string;
	image64: string;
	path: string;
	mimetype: string;
	docType: string;
	image: string;
	title: string;
	organization: string;
	createdAt: string;
	updatedAt: string;
}

export type PropsOrganization = {
	responsableCpf: string;
	responsableRG: string;
	qtdProdServ: string;
	useErp: boolean;
	usePdv: boolean;
	category: string;
	companyName: string;
	notification?: any[];
	address: any[];
	cnpj: string;
	createdAt: string;
	docs: IDoc[];
	municipalRegistration: string;
	name: string;
	registrationStep: number;
	responsable: string;
	responsableEmail: string;
	stateRegistration: string;
	status: string;
	tel: string;
	telDDD: string;
	updatedAt: string;
	isActive: boolean;
	userId: string;
	document: string;
	version: number;
	_id: string;
	bank: {
		accountType: string;
		agency: any;
		accountDigity: any;
		account: any;
		compe: any;
		agencyDigity: any;
	};
};

export type PropsUser = {
	_id: string;
	email: string;
	name: string;
	lastName: string;
	birthDate: Date | null;
	userType: string;
	docType: string;
	address: string;
	district: string;
	complement: string;
	country: string;
	zipcode: string;
	number: string;
	cel: string;
	celDDD: string;
	tel: string;
	telDDD: string;
	image: string;
	image64: string;
	filename: string;
	mimetype: string;
	categories: any[];
	termsOfUseAccepted: boolean;
	privacyPolicyAccepted: boolean;
	lgpdAccepted: boolean;
	document: string;
	isActive: boolean;
	status: string;
};

export type PropsCompany = {
	organization: PropsOrganization;
	user: PropsUser;
};

export const UserInitialState: PropsCompany = {
	organization: {
		address: [],
		qtdProdServ: "",
		useErp: false,
		usePdv: false,
		responsableCpf: "",
		responsableRG: "",
		category: "",
		cnpj: "",
		createdAt: "",
		docs: [],
		municipalRegistration: "",
		companyName: "",
		name: "",
		registrationStep: 1,
		responsable: "",
		responsableEmail: "",
		stateRegistration: "",
		isActive: null,
		bank: null,
		status: "",
		tel: "",
		telDDD: "",
		updatedAt: "",
		userId: "",
		version: 1,
		_id: "",
		document: "",
	},

	user: {
		_id: "",
		email: "",
		name: "",
		lastName: "",
		birthDate: null,
		userType: "",
		document: "",
		docType: "",
		address: "",
		district: "",
		complement: "",
		country: "",
		zipcode: "",
		number: "",
		cel: "",
		celDDD: "",
		tel: "",
		telDDD: "",
		image: "",
		categories: [],
		termsOfUseAccepted: false,
		privacyPolicyAccepted: false,
		lgpdAccepted: false,
		isActive: false,
		status: "",
		image64: "",
		filename: "",
		mimetype: "",
	},
};

export interface UserState {
	data: PropsCompany;
	users: object | null;
	loading: boolean;
	error: boolean;
	message: string | null;
}

const initialState: UserState = {
	data: UserInitialState,
	users: null,
	loading: false,
	error: false,
	message: null,
};

export const createUserAsync = createAsyncThunk(
	"user/createUser",
	async (data: any) => {
		let response = await create(data);
		return response;
	}
);

interface PayloadResponse {
	data: PropsCompany;
	error: boolean;
	message: string;
}

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		signUp: (state, action: PayloadAction<object>) => {
			state.data = action.payload as PropsCompany;
		},
		getUserData: (state, action: PayloadAction<PayloadResponse>) => {
			state.data = action.payload.data as PropsCompany;
			state.error = action.payload.error;
			state.message = action.payload.message;
		},
		storeUserData: (state, action: PayloadAction<PropsCompany>) => {
			state.data = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserDataAsync.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getUserDataAsync.pending, (state) => {
				state.loading = true;
			})
			.addCase(
				getUserDataAsync.fulfilled,
				(state, { payload }: PayloadAction<PayloadResponse>) => {
					state.data = payload.data;
					state.loading = false;
				}
			)
			.addCase(createUserAsync.rejected, (state) => {
				state.loading = false;
			})
			.addCase(createUserAsync.pending, (state) => {
				state.loading = true;
			})
			.addCase(createUserAsync.fulfilled, (state, action) => {
				state.loading = false;
				state.users = action.payload;
			})
			.addCase(updateUserAsync.rejected, (state) => {
				state.loading = false;
			})
			.addCase(updateUserAsync.pending, (state) => {
				state.loading = true;
			})
			.addCase(
				updateUserAsync.fulfilled,
				(state, { payload }: PayloadAction<PayloadResponse>) => {
					state.loading = false;
					state.data = payload.data;
				}
			);
	},
});

export const { signUp, storeUserData } = userSlice.actions;

export const userDataSelector = (state: RootState) => state.user;

export default userSlice.reducer;
