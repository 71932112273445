import api from ".";

export async function listCarBrand(params: object = null) {
    const response = await api.get(`/carbrand`, { params });
    return response.data;
}
export async function listCarModel(params: object = null) {
    const response = await api.get(`/carmodel`, { params });
    return response.data;
}
export async function listCarYear(params: object = null) {
    const response = await api.get(`/caryear`, { params });
    return response.data;
}
export async function listCarCategories(params: object = null) {
    const response = await api.get(`/carcategories`, { params });
    return response.data;
}
