import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
`;

export const Container = styled.div`
    width: 100%;

    margin-top: 50px;

    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    div.center {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
    }
`;

export const Left = styled.div`
    display: flex;
    max-width: 700px;
    gap: 30px;
    max-height: 420px;
`;

export const Right = styled.div`
    span.price {
        font-size: 30px;
        color: rgb(30, 164, 24);
        line-height: 21px;
        font-weight: 500;
        display: block;
    }

    span.parc {
        font-size: 18px;
        line-height: 21px;
        display: block;
        margin-top: 10px;
    }

    img {
        margin-top: 40px;
    }
`;

export const ImageComponent = styled.div`
    display: flex;
    gap: 20px;
    max-height: 420px;

    img {
        object-fit: cover;
    }

    > img {
        width: 327px;
        height: 420px;
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        button,
        img {
            height: 50px;
            width: 50px;
        }

        button {
            border-radius: 5px;
            outline: none;
            display: block;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
`;

export const Description = styled.div`
    display: flex;
    flex-direction: column;
    height: 420px;

    h3 {
        font-weight: 500;
        font-size: 20px;
    }

    > span {
        display: block;
        font-size: 18px;
        margin-top: 24px;
    }

    small {
        font-size: 14px;
        margin-top: 3px;
    }

    div.company {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        gap: 10px;
    }
`;
