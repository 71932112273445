import api from ".";

export async function createProductSale(orgId: string, body) {
	const response = await api.post(`/products-sale/seller/${orgId}`, {
		data: body,
	});
	return response.data;
}

export async function getProductsSales(orgId: string, params: object = null) {
	const response = await api.get(`/products-sale/seller/${orgId}`, {
		params,
	});
	return response.data;
}

export async function getProductsStatusSales(
	orgId: string,
	status: string,
	skip: string
) {
	const response = await api.get(
		`/sales/${orgId}/status/${status}/page/${skip}`
	);
	return response.data;
}

export async function getProductSale(id: string) {
	const response = await api.get(`/sales/salesid/${id}`);
	return response.data;
}

export const getProductSaleRating = async (
	organization: string,
	saleProduct: string
) => {
	const response = await api.get(`products-sale-rating/${saleProduct}`);
	return response.data;
};

export async function updateProductSale(orgId: string, id: string, data) {
	const response = await api.put(`products-sale/${id}/seller/${orgId}`, {
		data,
	});
	return response.data;
}

export async function getProductSaleUpdate(orgId: string, id: any) {
	const response = await api.get(
		`/products-sale/seller/${orgId}/product/${id}`
	);
	return response.data;
}

export async function uploadProductSaleImage(orgId: string, data: any) {
	const response = await api.post(
		`/products-sale/seller/${orgId}/imageupload`,
		data
	);
	return response.data;
}

export async function deleteProductSaleImages(orgId: string, data: any) {
	const response = await api.post(
		`/products-sale/seller/${orgId}/images-delete`,
		{ data }
	);
	return response.data;
}

export async function disableProductSale() {
	const response = await api.patch(`/sales/delete`);
	return response.data;
}

export async function deleteProductSale(orgId: string, id: string) {
	const response = await api.delete(
		`/products-sale/seller/${orgId}/product/${id}`
	);
	return response.data;
}
