import React from "react";
import { useNavigate } from "react-router";

import { FormProduct } from "../../components/Forms/Product";
import { AuthContainer } from "../Containers";
import { Content } from "../Sales/styles";

export function AddProduct() {
    const navigate = useNavigate();
    return (
        <AuthContainer pageTitle="Cadastrar Produto">
            <Content>
                <FormProduct update={() => navigate("/produtos")} />
            </Content>
        </AuthContainer>
    );
}
