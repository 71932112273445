import React from "react";
import Chart from "react-apexcharts";

type Props = {
    categories: string[] ;
    series: ApexAxisChartSeries;
    type?:
        | "line"
        | "area"
        | "bar"
        | "histogram"
        | "pie"
        | "donut"
        | "radialBar"
        | "scatter"
        | "bubble"
        | "heatmap"
        | "treemap"
        | "boxPlot"
        | "candlestick"
        | "radar"
        | "polarArea"
        | "rangeBar";
    width: string;
    heigth?: string;
};

export function Graphic(props: Props) {
    var { categories, series, type, width, heigth } = props;
    let data = {
        options: {
            xaxis: {
                categories,
            },
        },
        series,
    };

    return (
        <Chart
            options={data.options}
            series={series}
            type={type}
            width={width}
            height={heigth}
        />
    );
}
