import { AuthContainer } from "../../Containers";
import { DeleteAccount } from "../../../components/Tabs/DeleteAccount";
import { Container } from "./styled";

export function DeleteAccountPage() {
	return (
		<AuthContainer pageTitle="Configurações | Privacidade">
			<Container>
				<DeleteAccount />
			</Container>
		</AuthContainer>
	);
}
