import styled from "styled-components";

interface ContainerProps {
    className?: string;
}

export const Container = styled.main<ContainerProps>`
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    min-height: 100%;
    background-color: #E1E1E1;
`;
