import React, { useCallback, useEffect, useState } from "react";
import {
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";

import { Container, Content } from "./styles";

import { useAppSelector } from "../../store/hooks";
import { Button } from "../StyledComponents";
import { Loading } from "../Loading";
import { organizationUpdate } from "../../services/organizationUpload-service";
import { handleToast } from "../HandleToast";

export function CompanyProfile() {
	const { data } = useAppSelector((state) => state.user);
	const [loading, setLoading] = useState(false);

	const [values, setValues] = useState<any>({
		qtdProdServ: data?.organization?.qtdProdServ || "",
		useErp: data?.organization?.useErp || false,
		usePdv: data?.organization?.usePdv || false,
	});

	useEffect(() => {
		if (data) {
			setValues(data.organization);
		}
	}, [data]);

	const handleUpdateUser = async (event: React.FormEvent<HTMLFormElement>) => {
		setLoading(true);

		event.preventDefault();

		try {
			await organizationUpdate(data?.organization?._id, values);
			handleToast(false, "Empresa atualizada.");
		} catch (e) {
			handleToast(
				true,
				e?.response?.data || "Não foi possível atualizar usuário."
			);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = useCallback(
		(property: string) =>
			(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				const value = event.target.value;
				setValues({
					...values,
					[property]: value,
				});
			},
		[values]
	);

	return (
		<Container>
			<Content>
				<form onSubmit={handleUpdateUser}>
					<div className="row">
						<div className="column input-div">
							<TextField
								fullWidth
								size="small"
								label="Nome"
								variant="outlined"
								placeholder="Nome"
								value={values?.name}
								onChange={handleChange("name")}
							/>
						</div>
						<div className="column input-div">
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								type="tel"
								label="CNPJ"
								size="small"
								disabled={loading}
								onChange={handleChange("document")}
								value={values?.document}
							/>
						</div>
					</div>
					<div className="row">
						<div className="column input-div">
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label="Nome do responsável"
								size="small"
								disabled={loading}
								onChange={handleChange("responsable")}
								value={values?.responsable}
							/>
						</div>
					</div>
					<div className="row">
						<div className="column input-div">
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								type="tel"
								label="CPF do responsável"
								size="small"
								disabled={loading}
								onChange={handleChange("responsableCpf")}
								value={values?.responsableCpf}
							/>
						</div>
						<div className="column input-div">
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label="RG do responsável"
								size="small"
								disabled={loading}
								onChange={handleChange("responsableRG")}
								value={values?.responsableRG}
							/>
						</div>
					</div>
					<div className="row control">
						<FormControl
							sx={{
								marginBottom: "1rem",
							}}
						>
							<Typography>
								Quantos produtos/serviços vende em sua loja?
							</Typography>
							<TextField
								variant="outlined"
								required
								select
								aria-required
								value={values?.qtdProdServ}
								onChange={handleChange("qtdProdServ")}
								sx={{
									display: "flex",
									flex: 0.6,
								}}
								size="small"
							>
								<MenuItem value="1-50">1 a 50</MenuItem>
								<MenuItem value="51-200">51 a 200</MenuItem>
								<MenuItem value="201-500">201 a 500</MenuItem>
								<MenuItem value="500+">acima de 500</MenuItem>
							</TextField>
						</FormControl>
						<FormControl>
							<Typography>
								Você utiliza sistema para controle de estoque?
							</Typography>
							<RadioGroup
								row
								value={values?.useErp}
								onChange={handleChange("useErp")}
							>
								<FormControlLabel
									value={false}
									control={<Radio />}
									label="Não"
								/>
								<FormControlLabel
									value={true}
									control={<Radio />}
									label="Sim"
								/>
							</RadioGroup>
						</FormControl>
						<FormControl>
							<Typography>
								Você utiliza sistema de caixa em sua loja(PDV)?
							</Typography>
							<RadioGroup
								row
								value={values?.usePdv}
								onChange={handleChange("usePdv")}
							>
								<FormControlLabel
									value={false}
									control={<Radio />}
									label="Não"
								/>
								<FormControlLabel
									value={true}
									control={<Radio />}
									label="Sim"
								/>
							</RadioGroup>
						</FormControl>
					</div>
					<div className="row-button">
						<Button type="submit" disabled={loading}>
							{loading ? <Loading width={"40px"} /> : "Atualizar"}
						</Button>
					</div>
				</form>
			</Content>
		</Container>
	);
}
