import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    width: 100%;
    /* height: 100%; */
    padding: 1rem;
`;
export const RowDiv = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px;

    /* margin: 1rem; */
    background-color: #ffffff;
    /* border: 1px solid var(--border); */

    .input {
        max-width: 128px !important;
        min-width: 50px !important;
    }
`;

export const ModalContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .row-table {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .input-container {
        background-color: blueviolet;
    }
`;

// export const ContainerOld = styled.div`
//     display: grid;
//     grid-template-columns: 30fr 70fr;
//     gap:1rem;

//     .content-card{
//         height: 550px;
//     }
//     h1{
//         color:black;
//     }

//     .add-button {
//         display: flex;
//         width: 100%;

//         margin-bottom: 10px;
//         justify-content:flex-end;
//     }
//     .text-add {
//         display: flex;
//         color var(--primary-blue);
//         border:none;
//         border-bottom: 1px solid var(--primary-blue);
//         cursor: pointer;
//     }
//     .input {
//         margin-top: 2.5px;
//         /* width: 200px; */
//         height: 43px;
//         padding: 10px;
//       background:transparent !important;
//      outline: none;
//         border:none;
//         border-bottom: 1px solid rgba(28, 28, 28, 0.3)!important;

//         font: var(--text-body);
//     }
//     span {
//         color: red;
//     }

//     .content-amount {
//         display: flex;
//         gap: 10px;
//     }
//     .header-product {
//         display: flex;
//         justify-content: space-between;
//     }
//     .form-container {
//         display: flex;
//         flex-direction: column;

//         flex: 1 1 100%;

//         align-items: start;
//         justify-content: start;

//         .column {
//             display: flex;
//             flex-direction: column;
//             margin-bottom: 0.5rem;
//         }

//         .input {
//             min-width: 17rem;
//             max-width: 20rem;
//             width: 100%;
//             margin-bottom: 0.5rem;

//             padding: 0.6rem;
//             border-radius: 10px;

//             background-color: rgba(28, 28, 28, 0.25);
//             outline: 0;
//             border: 0;
//             font: var(--text-body);

//             ::placeholder {
//                 font: var(--text-body);
//             }
//         }
//         .button-content {
//             display: flex;
//             gap: 1rem;

//         }
//     }

//     .row {
//         display: flex;
//         gap: 18px;
//         .input {
//             min-width: 50px;
//             max-width: 150px;
//         }
//     }

//     .content-button{
//         display: flex;
//         align-items: center;
//         justify-content:space-between;
//         .row-table {
//             display: flex;
//             .input{
//                 min-width: 80px;
//                 max-width: 200px;
//             }

//             select {
//                 outline: none;
//                 background-color: var(--primary-blue)!important;
//                 color:#FFF;
//             }
//         }
//     }
// `;

// export const DividerBlack = styled(Divider)`
//     background-color: rgba(0, 0, 0);
//     height: 45px;

// `;
