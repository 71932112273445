import React from "react";
import { Container } from "./style";

interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
	primary?: boolean;
	backgroundColor?: string;
	color?: string;
	outline?: boolean;
	children?: any;
	onClick?: void | any;
	type?: "button" | "submit" | "reset";
	disabled?: boolean;
	width?: string;
	className?: string;
	height?: string;
}

Button.defaultProps = {
	primary: false,
	color: "#FFFFFF",
	backgroundColor: "#1ea418",
	onClick: () => {},
	type: "button",
	disabled: false,
};

export default function Button(props: ButtonProps) {
	var {
		primary,
		backgroundColor,
		color,
		outline,
		children,
		onClick,
		type,
		disabled,
		className,
		width,
		height,
	} = props;

	return (
		<Container
			primary={primary}
			backgroundColor={backgroundColor}
			color={color}
			outline={outline}
			type={type}
			onClick={onClick}
			disabled={disabled}
			width={width}
			className={className}
			height={height}
		>
			{children}
		</Container>
	);
}
