import styled from "styled-components";

export const Container = styled.div`
    margin: 0;
    width: 100%;
    height: 8vh;
    // background-color: #040473;
    margin-top: 50px;
    padding: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
`;
