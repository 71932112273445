import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import styled from "styled-components";

export const Container = styled.section`
    font: var(--text-body);
    box-shadow: 1px 1px 5px 1px gray;
    align-items: center;
    border-radius: 10px;
    min-width: 300px;
    max-width: 500px;

    footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
`;

export const Content = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
        font: var(--text-title);
        font-size: 18px;
        color: white;
    }
`;
export const Header = styled.div`
    background-color: var(--primary-blue);
    padding: 5px;
    border-radius: 10px 10px 0 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        width: 100%;
    }

    .button-content {
        width: 100%;
        display: flex;

        gap: 1rem;
        align-items: center;
        justify-content: end;
        button {
            display: flex;
            align-items: center;
            border: 0;
            padding: 10px;
            border-radius: 10px;
            background-color: transparent;
            color: white;
            cursor: pointer;
        }
        .input {
            color: #fff;
            min-width: 100px;
            max-width: 150px;
            border-radius: 0;
            border: none;
            background-color: transparent;
            border-bottom: 1px solid #fff;

            ::-webkit-input-placeholder {
                color: #fff;
            }
        }
    }
`;

export const PTable = styled.table`
    /* width: 100%; */
    border-collapse: collapse;

    th {
        text-align: left;
        padding: 10px;
        /* background-color: rgba(47, 61, 117, 0.4); */
    }
    tbody {
        width: 100%;
        margin-top: 20px;
        background-color: #fff;

        tr {
            text-align: left;
            border-top: 1px solid rgba(21, 21, 21, 0.3);

            :nth-child(even) {
                background-color: rgba(47, 61, 117, 0.4);
            }

            :hover {
                background-color: rgba(21, 21, 21, 0.3);
            }

            .icon-group {
                display: flex;
                gap: 10px;
            }
            /* td + td {
        border-left: 1px solid rgba(21, 21, 21, 0.3);
      } */
            td {
                padding: 10px;
                text-align: left;

                img {
                    max-width: 3vh;
                }

                ul {
                    display: flex;
                    flex-direction: row;
                    div {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }
`;

export const ProductDelete = styled(AiFillDelete)``;

export const ProductEdit = styled(FaEdit)``;

export const Add = styled(IoMdAdd)`
    color: #fff;
    font-size: 16px;
`;
