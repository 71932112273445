import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	/* width: 100%; */
	align-items: center;
	justify-content: center;
`;

export const Content = styled.div`
	display: flex;
	/* width: 100%; */
	align-items: center;
	justify-content: center;

	.row {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}
	h1 {
		color: #2020e6;
		font: var(--text-title);
		padding-top: 2rem;
		margin-bottom: 2rem;
	}

	/* .data {
        display: flex;
        align-items: center;
        justify-content: center;
    } */

	.button-profile {
		border: 0;
		outline: 0;
		background-color: #1ea418;
		font: var(--text-body);
		color: #fff;
		border-radius: 10px;
		display: flex;
		width: 10rem;
		height: 40px;
		margin: 0 auto;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		:disabled {
			opacity: 0.7;
		}
	}

	.user {
		display: flex;
		flex-direction: column;
	}

	.update-user {
		display: flex;
		width: 100%;
		height: 100%;
		margin-top: 1rem;
		gap: 2.125rem;
		justify-content: center;
		align-items: center;
	}

	form {
		display: flex;
		flex-direction: row;
		gap: 2.125rem;
		margin-bottom: 2rem;

		/* input {
            min-width: 15rem;
            min-height: 2rem;
            border-radius: 10px;
            border: 0;
            outline: 0;
            background-color: rgba(21, 21, 21, 0.1);
            font: var(--text-body);
            padding: 0.8rem;
            margin-bottom: 2.234rem;
        } */

		> div {
			justify-content: space-between;
			display: flex;
			flex-direction: column;
			position: relative;
			i {
				display: flex;
				align-items: center;
				cursor: pointer;
				border: 0;
				background: transparent;
				font-size: 1.2rem;

				z-index: 10;
				position: absolute;
				right: 15px;
				bottom: 0;
				transform: translateY(-50%);
			}
		}
	}

	.form-password {
		display: flex;
		flex-direction: column;
		form {
			display: flex;
			flex-direction: column;
			gap: 2.125rem;
			input {
				margin-bottom: 0;
			}
		}
	}

	.icon {
		margin-right: 1rem;
	}

	.row-button {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		/* margin: 0 auto; */
	}
`;

export const DrawerContent = styled.section`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 450px;
	padding: 20px;
	overflow-x: hidden;

	gap: 15px;

	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 30px;

		> div {
			justify-content: space-between;
			/* align-items: flex-start; */
			display: flex;
			flex-direction: column;
			max-width: 400px;
			width: 100%;
			position: relative;

			i {
				display: flex;
				align-items: center;
				cursor: pointer;
				border: 0;
				background: transparent;
				font-size: 1.2rem;

				z-index: 10;
				position: absolute;
				right: 15px;
				bottom: 20px;

				transform: translateY(50%);
			}
		}
	}

	.row-button {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		/* margin: 0 auto; */
	}
`;
