import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap:20px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 0 auto;
`;

export const Content = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding: 20px 30px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
`;

export const Data = styled.div`
    display: flex;
    width: 100%;
    padding: 1rem;
    align-items: center;
    justify-content: space-around;
    text-align: center;
`;
export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: end;
    justify-content: flex-end;
    text-align: center;

`;
