import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";

import { Loading } from "../../components/Loading";

import { RecoverPasswordStyled } from "./styles";
import { AuthForm } from "./styles";
import { UnAuthContainer } from "../Containers";
import { recPassword } from "../../services/user-services";

import { handleToast } from "../../components/HandleToast";
import Button from "../../components/StyledComponents/Button";

import "react-toastify/dist/ReactToastify.css";

export function RecoverPassword() {
	const schema = yup.object().shape({
		email: yup.string().email("E-mail inválido").required("E-mail obrigatório"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	var [loading, setLoading] = React.useState(false);

	async function handleConfirmEmail(data: any) {
		setLoading(true);
		const response = (await recPassword(data)) as any;
		handleToast(response.error, response.message);
		setLoading(false);
	}

	//

	return (
		<UnAuthContainer>
			<RecoverPasswordStyled>
				<AuthForm onSubmit={handleSubmit(handleConfirmEmail)}>
					<h1>Recuperar senha</h1>
					<TextField
						type="email"
						placeholder="E-mail cadastrado"
						label="E-mail cadastrado"
						size="small"
						variant="outlined"
						fullWidth
						{...register("email")}
						helperText={errors?.email?.message?.toString()}
					/>
					<Button
						type="submit"
						className="form-row"
						disabled={loading ? true : false}
						width="100%"
					>
						{loading ? (
							<Loading
								type={"bars"}
								color={"#fff"}
								width="40px"
								height="10px"
							/>
						) : (
							<>Cadastre-se</>
						)}
					</Button>

					<div className="form-row">
						<p>
							Caso não tenha uma conta,{" "}
							<Link to="/cadastro" className="link-register">
								se inscreva aqui
							</Link>
						</p>
						<p>
							Voltar ao
							<Link to="/" className="link-register">
								{" "}
								Login
							</Link>
						</p>
					</div>
				</AuthForm>
			</RecoverPasswordStyled>
		</UnAuthContainer>
	);
}
