import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import GlobalStyle from "./styles/global";
import App from "./App";

import reportWebVitals from "./reportWebVitals";

import { persistor, store } from "./store";
import { theme } from "./components/StyledComponents/ThemingMaterial";
import { injectStore } from "./services";

injectStore(store);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<>
						<App />
						<ToastContainer
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
					</>
				</PersistGate>
			</Provider>
		</ThemeProvider>
		<GlobalStyle />
	</React.StrictMode>
);

reportWebVitals();
