import React from "react";
import { ButtonGroup } from "@mui/material";
import { ButtonField, InputField } from "./styles";

interface IProps {
	options: string[];
	handleChange?: (value: any) => void;
}

export default function Search(props: IProps) {
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const { options, handleChange } = props;

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let property = options[0];
		handleChange({ [property]: (event.target as HTMLInputElement).value });
	};

	return (
		<ButtonField disableFocusRipple={true} disableRipple={true}>
			<ButtonGroup
				ref={anchorRef}
				size="small"
				fullWidth
				disableFocusRipple={true}
			>
				<InputField
					size="small"
					placeholder={`Buscar ${options[0]}...`}
					autoFocus={false}
					fullWidth
					onChange={onChange}
				/>
			</ButtonGroup>
		</ButtonField>
	);
}
