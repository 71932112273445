import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../services'
import { PropsCompany, PropsUser } from '.'

interface PayloadResponse {
	data: PropsCompany;
	error: boolean;
	message: string;
}

export const getUserDataAsync = createAsyncThunk<PayloadResponse, undefined>(
	"user/getUserData",
	async (_, apiThunk) => {
		const response = await api.get<PayloadResponse>(`/seller`)
		return response.data
	}
)

export const updateUserAsync = createAsyncThunk<PayloadResponse, PropsUser>(
	"user/updateUser",
	async (data, apiThunk) => {
		const response = await api.put<PayloadResponse>(
			`/seller/${data._id}/update`,
			data
		);
		return response.data;
	}
);
