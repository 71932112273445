import { Container } from "./styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { HiDocument } from "react-icons/hi";

interface IProps {
	accepted: { [key: string]: string[] };
	onAccept: (file: any) => void;
	description: string;
	background?: string;
	pdf?: string;
	maxFiles?: number;
	initialFiles?: any[];
	removeInitial?: (id: string) => void;
	maxSize?: number;
}

const DragFile: React.FC<IProps> = ({
	accepted,
	onAccept,
	description,
	pdf,
	maxFiles = 4,
	initialFiles,
	removeInitial,
	maxSize,
}) => {
	const [files, setFiles] = useState([]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			const tempFiles = acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			);

			if (files.length < maxFiles) {
				setFiles([...files, ...tempFiles]);
			}
		},
		[files, setFiles, maxFiles]
	);

	useEffect(() => {
		return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
	}, [files]);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: accepted,
		maxSize,
		maxFiles,
	});

	const deleteFile = useCallback(
		(file: string) => {
			const filteredArray = files.filter((f) => f.preview !== file);

			URL.revokeObjectURL(file);

			setFiles(filteredArray);
		},

		[setFiles, files]
	);

	const removeInitialFile = useCallback(
		(file: any) => {
			removeInitial(file);
		},
		[removeInitial]
	);

	useEffect(() => {
		onAccept(files);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files]);

	const renderImage = useCallback(() => {
		const newFiles =
			files?.map((file) => (
				<div className="preview-document-container">
					{file.type.includes("image") && (
						<img
							style={{
								width: "80px",
								height: "80px",
							}}
							src={URL.createObjectURL(file)}
							alt={file?.name}
							onLoad={() => {
								URL.revokeObjectURL(file.preview);
							}}
						/>
					)}
					{file.type.includes("pdf") && <HiDocument size={30} />}
					<span id={file?.name}>{file?.name}</span>
					<DeleteOutlineIcon
						color="error"
						id={file?.size}
						onClick={() => deleteFile(file.preview)}
						style={{ cursor: "pointer" }}
					/>
				</div>
			)) || [];
		const prevFiles =
			initialFiles?.map((file) => {
				return (
					<div className="preview-document-container">
						{file?.mimetype?.includes("image") && (
							<img
								style={{
									width: "80px",
									height: "80px",
								}}
								src={
									file?.image64
										? `data:image/png;base64, ${file?.image64}`
										: file?.path
								}
								alt={file?.name}
							/>
						)}
						{file?.mimetype?.includes("pdf") && <HiDocument size={30} />}
						<span id={file?.name}>{file?.name}</span>
						<DeleteOutlineIcon
							color="error"
							onClick={() => removeInitialFile(file)}
							style={{ cursor: "pointer" }}
						/>
					</div>
				);
			}) || [];

		return [...prevFiles, ...newFiles];
	}, [files, initialFiles, deleteFile, removeInitialFile]);

	return (
		<Container>
			<div id="file-upload-form" className="uploader" {...getRootProps()}>
				<label htmlFor="file-upload" id="file-drag">
					<div id="start">
						<i className="fa fa-download" aria-hidden="true" />
						<input {...getInputProps()} />
						<CloudUploadIcon color="info" />

						{!!pdf && (
							<div>
								<HiDocument size={30} /> {pdf}
							</div>
						)}
						<div>{description}</div>
					</div>
				</label>
			</div>
			<div className="row">{renderImage()}</div>
		</Container>
	);
};

export default DragFile;
