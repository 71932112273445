import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

const labels: { [index: string]: string } = {
	0: "N/D",
	0.5: "Péssimo",
	1: "Péssimo+",
	1.5: "Ruim",
	2: "Ruim+",
	2.5: "Regular",
	3: "Regular+",
	3.5: "Bom",
	4: "Bom+",
	4.5: "Ótimo",
	5: "Ótimo+",
};

export default function TextRating({ value = 0 }) {
	return (
		<Box
			sx={{
				width: 160,
				display: "flex",
				alignItems: "center",
			}}
		>
			<Rating
				name="text-feedback"
				value={value}
				readOnly
				precision={0.5}
				emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
			/>
			<Box sx={{ ml: 2 }}>{labels[value]}</Box>
		</Box>
	);
}
