import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    gap: 30px;
    .form-container {
        margin: 0;
    }
    .div-loading {
        top: 0;
        right: 0;
        position: fixed;
        z-index: 10000;
        width: 100%;
        height: 100%;
        background-color: rgba(21, 21, 21, 0.8);
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        h1 {
            color: var(--primary-white);
            font: var(--text-title);
            font-size: 30px;
        }
    }
`;

export const Content = styled.section`
    display: flex;
    justify-content: flex-start;
    background-color: #ffffff;
`;

export const FormContainer = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const FormButtonContainer = styled.section`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    padding: 15px 25px;
    gap: 1rem;
`;

export const PaddingFormContainer = styled.section`
    padding: 20px;
`;

export const FormStep = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    padding: 20px;

    font: var(--text-body);

    .row {
        display: flex;
        flex-direction: row;
        /* flex-wrap: wrap; */
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
    }
    .column {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        // min-width: 350px;
    }

    .equal-column > * {
        flex: 1;
    }

    .fullwidth {
        width: 100%;
    }

    .input-div {
        width: 100%;
        /* margin: 0.5rem; */
        margin-bottom: 30px;
    }

    .input {
        padding: 10px;
        border-radius: 10px;

        border: 1px solid rgba(28, 28, 28, 0.25);

        outline: none;

        font: var(--text-body);
    }
    .input-row {
        width: 70px;
    }
    .content-button {
        margin-top: 1rem;
        display: flex;

        justify-content: end;
        align-items: center;
        gap: 1rem;
    }
    .gap {
        display: flex;
        gap: 1rem;
        margin-bottom: 3rem;
    }
    .doc-container {
        display: flex;
        align-items: center;

        width: 200px;
        height: 80px;
        margin-bottom: 0.5rem;
        outline: 0;
        border: 1px solid rgba(28, 28, 28, 0.25);

        border-radius: 10px;
    }
    .preview-doc {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .button-file {
        margin: auto;
        padding: 8px;
        text-align: center;
        background: #3733d8;
        color: var(--primary-white);
        outline: 0;
        border-radius: 10px;
        cursor: pointer;
    }
    .image-container {
        display: flex;
        align-items: center;
        margin-left: 10px;

        width: 300px;
        height: 250px;
        margin-bottom: 0.5rem;
        outline: 0;
        border: 1px solid rgba(28, 28, 28, 0.25);

        border-radius: 10px;

        .preview-image {
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;
            align-items: center;
            justify-content: center;
            width: 100%;

            .button-image {
                position: absolute;
                top: 920px;
                margin: 0 auto;
                /* margin-bottom: 10px; */
            }
            .button-image2 {
                position: absolute;
                top: 560px;

                margin: auto;
                padding: 10px;
                text-align: center;
                background: #3733d8;
                color: var(--primary-white);
                outline: 0;
                border-radius: 10px;
                cursor: pointer;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                img {
                    border-radius: 5px;
                    max-width: 200px;
                    max-height: 200px;
                    object-fit: fill;
                }
            }
        }

        .button-image {
            margin: auto;
            padding: 10px;
            text-align: center;
            background: #3733d8;
            color: var(--primary-white);
            outline: 0;
            border-radius: 10px;
            cursor: pointer;
        }
    }

    .textLimit {
        max-width: 15ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const FinalStep = styled.div`
    width: 100%;
    background: white;

    padding: 30px;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    img {
        width: 200px;
        height: 150px;
        object-fit: cover;
    }

    p {
        font-size: 20px;
        max-width: 400px;
        text-align: center;
        font-weight: 600;
    }
`;
