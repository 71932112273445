import React from "react";
import ReactLoading from "react-loading";
import { Container } from "./styles";

type Props = {
    type?:
        | "blank"
        | "balls"
        | "bars"
        | "bubbles"
        | "cubes"
        | "cylon"
        | "spin"
        | "spinningBubbles"
        | "spokes";
    color?: string;
    height?: string;
    width?: string;
};

Loading.defaultProps = {
    type: 'bars'
}

export function Loading(props: Props) {
    var { type, color, height, width } = props;
    return (
        <Container>
            <ReactLoading
                className="loading"
                type={type}
                color={color}
                height={height}
                width={width}
            />
        </Container>
    );
}
