import styled from "styled-components";

export const Container = styled.div`
    button {
        background: #ddd;
    }

    .Mui-selected {
        color: rgb(25, 118, 210) !important;
        background: white;
        border-color: rgb(25, 118, 210) !important;
        border: none;
    }

    .MuiTabs-indicator {
        background-color: rgb(25, 118, 210) !important;
    }
`;
