import styled from "styled-components";

export const Container = styled.section`
    background-color: #ffffff;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
    @media (max-width: 1000px) {
        .sales-cards-wrapper {
            max-width: 100%;
        }
    }
`;

export const LowCard = styled.section`
    position: relative;
    display: flex;
    max-width: 100%;

    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 100%;
    }
`;

export const Select = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 15px;

    input {
        width: 120px;
        border: none;
        height: 45px;
        padding: 5px;
        box-sizing: border-box;
        outline: none;
        cursor: pointer;
    }
    label {
        width: 100%;
        text-align: left;
        font-size: 12px;
        gap: 1rem;
    }
`;
