import { useState } from "react";
import Logo from "../../../../assets/images/logo.png";
import { Stepper, Step, StepLabel } from "@mui/material";
import { Container, Content, FinalStep } from "./styles";
import { FormBank } from "../../Bank";

import { PropsCompany, storeUserData } from "../../../../store/user";
import { updateRegistrationSteps } from "../../../../services/organizationUpload-service";
import FormCompany from "../../FormComapny";
import FormMarketplace from "../../FormMarketplace";
import FormResponsible from "../../FormResponsible";
// import FormDocuments from "../../FormDocuments";
import { getUserData } from "../../../../services/user-services";
import { useAppDispatch } from "../../../../store/hooks";
import { handleToast } from "../../../HandleToast";

type PropsStep = {
	stepLocation: number;
	setCompany: PropsCompany;
};

const steps = [
	{
		id: 1,
		step: "Informações da loja",
	},
	{
		id: 2,
		step: "Dados do mercado",
	},
	{
		id: 3,
		step: "Responsável legal da loja",
	},
	{
		id: 4,
		step: "Dados bancário",
	},
	{
		id: 5,
		step: "Aguardando aprovação",
	},
];

export function FormUserSteps(props: PropsStep) {
	const { stepLocation, setCompany } = props;

	const [step, setStep] = useState<number>(stepLocation);

	const dispatch = useAppDispatch();

	async function handleUser() {
		const response = await getUserData();

		if (!response?.error) {
			dispatch(storeUserData(response?.data));
		} else {
			handleToast(true, response?.message);
		}
	}

	async function handleNextStep() {
		const nextStep = stepLocation > step ? stepLocation : step + 1;

		await updateRegistrationSteps(setCompany?.organization?._id, {
			registrationStep: nextStep,
		});

		setStep(step + 1);

		await handleUser();
	}
	function handleBackStep() {
		setStep(step - 1);
	}

	const renderStepInfo = (step: number) => {
		switch (step) {
			case 1:
				return <FormCompany nextStep={handleNextStep} company={setCompany} />;
			case 2:
				return (
					<FormMarketplace
						nextStep={handleNextStep}
						company={setCompany}
						backStep={handleBackStep}
					/>
				);
			case 3:
				return (
					<FormResponsible
						nextStep={handleNextStep}
						company={setCompany}
						backStep={handleBackStep}
					/>
				);
			case 4:
				return (
					<FormBank
						onUpdate={handleNextStep}
						company={setCompany}
						cancel={handleBackStep}
						backStep={handleBackStep}
					/>
				);
			case 5:
				return (
					<FinalStep>
						<div>
							<img src={Logo} alt="logo" />
							<p>Recebemos seu cadastro que estará passando por aprovação.</p>
						</div>
					</FinalStep>
				);
		}
	};

	return (
		<Container>
			<Stepper activeStep={step - 1} alternativeLabel>
				{steps.map((item, index) => (
					<Step key={index}>
						<StepLabel>{item?.step}</StepLabel>
					</Step>
				))}
			</Stepper>
			<Content>{renderStepInfo(step)}</Content>
		</Container>
	);
}
