import React, { InputHTMLAttributes, useCallback } from "react";
import { TextField } from "@mui/material";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	mask: "currency" | "number";
	ref: any;
}

interface CustomProps {
	mask: "currency" | "number";
	variant?: "standard" | "filled" | "outlined";
	onChange?: (value) => void;
	onBlur?: (event: React.ChangeEvent) => void;
	value?: any;
	type?: any;
	defaultValue?: any;
	name?: string;
	className?: string;
	placeholder?: string;
	label?: string;
	sx?: any;
	fullwidth?: true | false;
	disabled?: true | false;
	select?: boolean;
	maxLength?: number;
}

interface Props {
	mask: "currency" | "number" | "text";
	variant?: "standard" | "filled" | "outlined";
	onChange?: (value) => void;
	onBlur?: (event: React.ChangeEvent) => void;
	value?: any;
	type?: any;
	defaultValue?: any;
	name?: string;
	className?: string;
	placeholder?: string;
	label?: string;
	sx?: any;
	fullwidth?: true | false;
	disabled?: true | false;
	size?: "small" | "medium";
	select?: boolean;
	maxLength?: number;
	shrink?: boolean;
}

function currency(e: React.FormEvent<HTMLInputElement>) {
	let value = e.currentTarget.value;
	value = value.replace(/\D/g, "");
	value = value.replace(/(\d)(\d{2})$/, "$1,$2");
	value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

	e.currentTarget.value = value;
	return e;
}
function number(e: React.FormEvent<HTMLInputElement>) {
	let value = e.currentTarget.value;
	value = value.replace(/\D/g, "");

	e.currentTarget.value = value;
	return e;
}

export const MaskInput: React.FC<InputProps> = ({
	mask,
	disabled,
	...props
}) => {
	const handleKeyUp = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			if (mask === "currency") {
				currency(e);
			}

			if (mask === "number") {
				number(e);
			}
		},

		[mask]
	);

	return <input {...props} onKeyUp={handleKeyUp} readOnly={disabled} />;
};

const InputMask = React.forwardRef<InputProps, CustomProps>(function InputMask(
	props,
	ref
) {
	const { mask, disabled, maxLength, ...others } = props;

	return <MaskInput {...others} ref={ref} mask={mask} maxLength={maxLength} />;
});

export const Input: React.FC<Props> = (props) => {
	const {
		onBlur,
		variant,
		mask,
		label,
		sx,
		size,
		fullwidth,
		disabled,
		select,
		maxLength,
		shrink,
		...others
	} = props;

	return (
		<TextField
			select={select}
			{...others}
			disabled={disabled}
			InputProps={{
				inputComponent: InputMask as any,
				inputProps: {
					mask: mask ? mask : "number",
					maxLength: maxLength ? maxLength : "number",
					disabled,
				},
			}}
			size={size ? size : "small"}
			variant={variant ? variant : "outlined"}
			label={label}
			sx={sx}
			fullWidth={fullwidth}
			InputLabelProps={{ shrink: shrink }}
		/>
	);
};
