import styled from "styled-components";

export const LoadingWrapper = styled.aside`
    position: fixed;
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.65);
`;


export const AsideStyle = styled.aside`
	display: flex;
	flex: 0.4 1 100%;
	height: 100%;
	width: 50vh;
	justify-content: center;
	align-items: center;

	.title {
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			img {
					width: 30%;
					height: 30%;
			}
			h1 {
					font: var(--text-title);
					color: #fff;
			}
			h2 {
					font: var(--text-body);
					color: #fff;
			}
	}

	@media (max-width: 1100px) {
		display: flex;

		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;

		.title {
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			img {
				width: 30%;
				height: 30%;
			}

			h1 {
				font: var(--text-title);
				color: #fff;
			}
			h2 {
				font: var(--text-body);
				color: #fff;
			}
		}
	}
`;

export const Container = styled.section`
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @media (min-width: 730px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
`

export const Content = styled.section`
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
`
