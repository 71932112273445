import React from "react";
import { useNavigate } from "react-router-dom";
import { FormCollaborator } from "../../components/Forms/Collaborator";
import { AuthContainer } from "../Containers";

export function AddCollaborator() {
	const navigate = useNavigate();
	return (
		<AuthContainer pageTitle="Adicionar Colaborador">
			<FormCollaborator cancel={() => navigate("/colaborador")} />
		</AuthContainer>
	);
}
