import styled from "styled-components";

export const AuthForm = styled.form`
	display: flex;
	flex: 1 1 100%;
	flex-direction: column;
	width: 100%;
	gap: 10px;

	@media (min-width: 730px) {
		max-width: 600px;
	}

	.MuiFormHelperText-root {
		color: red;
	}
`;

export const Row = styled.section`
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media (min-width: 730px) {
		flex-direction: row;
		flex: 1 0 100%;
		width: 100%;
	}
`;

export const Container = styled.div`
	width: 100%;
	margin: 0 auto;
	h1 {
		font: var(--text-title);
	}
	form {
		height: 100%;
		margin: 40px auto;

		span {
			font-size: 13px;
			width: 100%;

			color: var(--alert-primary);
		}
		.input-column {
			display: flex;
			flex-direction: column;
			margin: 0 auto;
		}
		.span {
			width: 30rem;
			margin-bottom: 10px;
		}
		.input-row {
			display: flex;
			justify-content: center;
			align-items: center;
			input {
				min-width: 14.5rem;
			}
			div {
				margin-bottom: 0;
			}
			span {
				margin-bottom: 10px;
			}
		}
		.terms-row {
			display: flex;
			flex-direction: row;

			justify-content: start;
			align-items: center;
			gap: 5px;
			margin-bottom: 0;
			span {
				color: #07b20b;
				font-size: 15px;
				cursor: pointer;
			}
			p {
				font-size: 13px;
				padding: 0;
			}
		}
	}
	.contact {
		font-size: 12px;
	}
	.select-services-container {
		width: 100%;
		border: 0;
		outline: 0;
		margin-bottom: 10px;
	}
	.select-services__control {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		margin: 0;
		padding: 5px 0;
		background: rgba(21, 21, 21, 0.25);
		border: 0;
		outline: 0;
		border-radius: 10px;
	}
	.select-services__value-container {
		align-items: center;
		margin: 0;
	}
	.select-services__indicators {
		align-items: center;
		margin: 0;
	}
	.select-services__placeholder {
		margin: 0;

		font: var(--text-body);
	}

	.select-services__multi-value {
		align-items: center;
		margin: 0;
	}
	.select-services__multi-value__remove {
		margin: 0;
	}

	.select-services__multi-value__label {
		margin: 0;
	}

	.select-services__menu {
		align-items: center;
		width: 100%;
		margin: 0;
		border: 0;
		outline: 0;
	}
	.select-services__menu-list {
		align-items: center;
		display: flex;
		flex-direction: column;
		width: 100%;
		border: 0;
		outline: 0;
	}

	.input-row {
		display: flex;
		flex-direction: row;
		width: 1rem;

		justify-content: center;

		gap: 1rem;

		input {
			min-width: 10rem;

			margin-bottom: 0.5rem;
			padding: 0.6rem;
			border-radius: 10px;
			color: var(--primary-gray);
			border: 1px solid rgba(28, 28, 28, 0.25);
			outline: 0;

			font: var(--text-body);

			::placeholder {
				font: var(--text-body);
			}
		}
	}

	@media (max-width: 1100px) {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		form {
			margin-top: 180px;
		}

		.input-row {
			display: flex;
			flex-direction: row;

			justify-content: center;

			gap: 1.5rem;

			input {
				min-width: 3rem;
			}
		}
		.flex {
			margin-left: 20px;
		}
	}
`;
