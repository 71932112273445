import styled from "styled-components";
export const Container = styled.div`
    .loading {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        align-items: center;
        margin-bottom: 0;
    }
`;
