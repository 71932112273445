import { useState, useEffect } from "react";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Container } from "./styles";
import { convertToHTML } from "draft-convert";

interface IProps {
	value?: string;
	setValue: (value: string) => void;
}

const RichTextEditor: React.FC<IProps> = ({ value, setValue }) => {
	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromBlockArray(convertFromHTML(value) as any)
		)
	);

	useEffect(() => {
		setValue(convertToHTML(editorState.getCurrentContent()));
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorState]);

	return (
		<Container>
			<Editor
				editorState={editorState}
				toolbarClassName="toolbarClassName"
				wrapperClassName="wrapperClassName"
				editorClassName="editorClassName"
				onEditorStateChange={setEditorState}
			/>
		</Container>
	);
};

export default RichTextEditor;
