import { useState } from "react";

import { Container, Content, DrawerContent } from "./styles";

import { FiEye, FiEyeOff } from "react-icons/fi";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Drawer, TextField } from "@mui/material";
import { Button } from "../StyledComponents";
import { handleToast } from "../HandleToast";
import { updateUserPassword } from "../../services/user-services";
import { useAppSelector } from "../../store/hooks";

export function UpdatePassword(props) {
	const [modal, setModal] = useState(false);
	const [passwordShow, setPasswordShow] = useState(false);
	const [newPasswordShow, setNewPasswordShow] = useState(false);
	const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

	const user = useAppSelector((state) => state.user);

	const schema = yup.object().shape({
		oldPassword: yup
			.string()
			.required("Informe a senha antiga")
			.min(6, "Senha deve conter mínimo de 6 dígitos.")
			.max(32, "Senha deve conter máximo de 32 dígitos."),
		password: yup
			.string()
			.required("Informe a nova senha")
			.min(6, "Senha deve conter mínimo de 6 dígitos.")
			.max(32, "Senha deve conter máximo de 32 dígitos.")
			.matches(/^(?=.*[a-z])/, "Senha deve conter um caracter minúsculo.")
			.matches(/^(?=.*[A-Z])/, "Senha deve conter um caracter maiúsculo.")
			.matches(/^(?=.*[0-9])/, "Senha deve conter um número."),
		passwordConfirm: yup
			.string()
			.oneOf([yup.ref("password"), null], "Senhas devem ser iguais"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	async function handleUpdateUser(data: any) {
		if (data.password === data.oldPassword) {
			return handleToast(true, "As senhas devem ser diferentes");
		}
		const response = await updateUserPassword({
			oldPassword: data.oldPassword,
			password: data.password,
			userId: user.data.user._id,
		});

		if (!response.error) {
			return handleToast(response.error, response.message);
		}
		return handleToast(response.error, response.message);
	}

	return (
		<Container>
			<Drawer anchor="right" open={modal} onClose={() => setModal(false)}>
				<DrawerContent>
					<form
						onSubmit={handleSubmit(handleUpdateUser)}
						autoComplete="false"
						autoSave="false"
						autoCapitalize="false"
					>
						<h4>Trocar senha</h4>
						<div>
							<TextField
								type={`${passwordShow ? "text" : "password"}`}
								{...register("oldPassword")}
								size="small"
								label="Senha antiga"
							/>
							{passwordShow ? (
								<i>
									<FiEyeOff onClick={() => setPasswordShow(false)} />
								</i>
							) : (
								<i>
									<FiEye onClick={() => setPasswordShow(true)} />
								</i>
							)}
							<span
								style={{
									color: "red",
									fontSize: "14px",
									position: "absolute",
									bottom: "-20px",
								}}
							>
								{errors?.oldPassword?.message as string}
							</span>
						</div>
						<div>
							<TextField
								type={`${newPasswordShow ? "text" : "password"}`}
								{...register("password")}
								size="small"
								label="Senha nova"
							/>
							{newPasswordShow ? (
								<i>
									<FiEyeOff onClick={() => setNewPasswordShow(false)} />
								</i>
							) : (
								<i>
									<FiEye onClick={() => setNewPasswordShow(true)} />
								</i>
							)}
							<span
								style={{
									color: "red",
									fontSize: "14px",
									position: "absolute",
									bottom: "-20px",
								}}
							>
								{errors?.password?.message as string}
							</span>
						</div>
						<div>
							<TextField
								type={`${confirmPasswordShow ? "text" : "password"}`}
								{...register("passwordConfirm")}
								size="small"
								label="Confirmar senha"
							/>
							{confirmPasswordShow ? (
								<i>
									<FiEyeOff onClick={() => setConfirmPasswordShow(false)} />
								</i>
							) : (
								<i>
									<FiEye onClick={() => setConfirmPasswordShow(true)} />
								</i>
							)}
							<span
								style={{
									color: "red",
									fontSize: "14px",
									position: "absolute",
									bottom: "-20px",
								}}
							>
								{errors?.passwordConfirm?.message as string}
							</span>
						</div>

						<div className="row-button">
							<Button outline onClick={() => setModal(false)}>
								Cancelar
							</Button>
							<Button type="submit">Trocar senha</Button>
						</div>
					</form>
				</DrawerContent>
			</Drawer>
			<Content>
				<Button type="button" onClick={() => setModal(true)}>
					Trocar senha
				</Button>
			</Content>
		</Container>
	);
}
