import api from ".";

export async function uploadDocs(id: string, data: any) {
    const response = await api.post(`/organization/${id}/docs/upload`, data);
    return response.data;
}
export async function createDocs(id: string, data: any) {
    const response = await api.post(`/organization/${id}/docs/create`, data);
    return response.data;
}

export async function updateDocs(id: string, data: any) {
    const response = await api.put(`/organization/${id}/update`, data);
    return response.data;
}

export async function addressPost(orgId: any, data: any) {
    const response = await api.post(
        `/organization/${orgId}/registration`,
        data
    );
    return response.data;
}

export async function addressUpdateRegistration(
    orgId: any,
    data: any,
    addressId: string
) {
    const response = await api.put(
        `/organization/${orgId}/registration-update/${addressId}`,
        data
    );
    return response.data;
}

export async function addressUpdate(orgId: any, id: any, data: any) {
    const response = await api.put(
        `/organization/${orgId}/address/${id}/update`,
        data
    );
    return response.data;
}

export async function addressDelete(orgId: any, id: any, data: any) {
    const response = await api.post(
        `/organization/${orgId}/address/${id}`,
        data
    );
    return response.data;
}
export async function organizationUpdate(orgId: any, data: any) {
    const response = await api.put(`/organization/${orgId}`, data);
    return response.data;
}

export async function settingsOrganizationUpdate(id: any, data: any) {
    const response = await api.put(`organization/${id}/settings`, data);
    return response.data;
}

export async function updateRegistrationSteps(id: any, data: any) {
    const response = await api.patch(`/organization/${id}/registrations`, data);
    return response.data;
}
