import { FaUser } from "react-icons/fa";
import { FiAlignJustify } from "react-icons/fi";
import styled from "styled-components";

export const HeaderStyle = styled.section`
	position: relative;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: #fff;
	margin-bottom: 10px;
	padding: 20px 0px;

	* {
		font-family: "Roboto";
	}

	/* .logo {
        width: 100px;
        img {
            margin-left: 10px;
            max-width: 100%;
            resize: contain;
            object-fit: fill;
        }
    } */

	div {
		width: auto;
		display: flex;
		gap: 1rem;
		align-items: center;
	}

	.header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 100%;
		height: 4rem;
		gap: 1rem;
		background-color: white;
		box-shadow: 1px 1px 10px 5px rgba(21, 21, 21, 0.3);
	}

	.content-title {
		display: flex;
		color: #2420c7;
		width: 100%;
		margin-left: 2rem;
		h1 {
			width: 100%;
			font: var(--text-medium) !important;
		}

		@media (max-width: 700px) {
			display: flex;
			padding-right: 2rem;
			margin-left: 1rem;
			h1 {
				font: var(--text-small) !important;
			}
		}
	}

	.divider {
		height: 35px;
		width: 1px;
		background-color: rgba(21, 21, 21, 0.3);
	}

	.icons {
		display: flex;
		max-width: 4rem;
		gap: 1rem;
		font-size: 1.5rem;
		margin-right: 2rem;
		cursor: pointer;
		@media (max-width: 800px) {
			margin-right: 1rem;
		}
	}
	.notification {
		display: flex;
		position: relative;
		.notify {
			background: red;
			color: white;
			width: 13px;
			height: 13px;
			justify-content: center;
			border-radius: 100%;
			position: absolute;
			top: 10px;
			left: -2px;
			font-size: 10px;
		}
	}

	.outSideNotification {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 1000;
		width: 100%;
		height: 100%;
		background-color: transparent;
	}

	.contentNotify {
		display: flex;
		flex-direction: column;
		text-align: start;
		align-items: start !important;
		width: 100%;

		padding: 10px;
		gap: 0;

		transition: 0.1s;

		:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}

	.newNotifications {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		background: #ffffff;
		font-family: "open sans";
		font-size: 0.85em;
		line-height: 1.6em;
		/* border-radius: 15px; */
		width: 300px;
		height: auto;
		max-height: 500px;
		overflow-y: auto;
		padding: 20px;
		position: absolute;
		right: 40px;
		top: 65px;
		gap: 0 !important;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		div {
			display: flex;
		}
		div + div {
			border-top: 1px solid black;
		}
	}
	.newNotifications:after {
		/*Triangulo*/
		content: "";
		width: 0;
		height: 0;
		position: absolute;
		z-index: 1000;

		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		/*Faz seta "apontar para baixo. Definir o valor como 'top' fará ela "apontar para cima" */
		/*Aqui entra a cor da "aba" do balão */
		border-bottom: 20px solid #4ca2cd;
		top: -20px; /*localização. Experimente alterar para 'bottom'*/
		right: 20px;
	}

	.show-menu {
		display: none;
		@media (max-width: 800px) {
			display: flex;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			justify-content: center;
			align-items: center;
		}
	}
`;

export const ShowMenu = styled(FiAlignJustify)`
	display: none;
	color: black;

	@media (max-width: 800px) {
		display: flex;
		color: var(--primary-blue);
	}
`;

export const InputDiv = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;

	justify-content: center;
	position: relative;

	button {
		display: flex;
		align-items: center;
		cursor: pointer;
		border: 0;
		background: transparent;
		font-size: 1.2rem;
		color: gray;
		z-index: 10;
		position: absolute;
		margin-right: -150px;
	}
	input {
		box-shadow: 1px 1px 10px 1px rgba(21, 21, 21, 0.3);
		border: 0;
		border-radius: 5rem;

		text-align: left;
		padding: 10px;
		outline: 0;
		position: relative;
	}
`;

export const Avatar = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	cursor: pointer;

	p {
		font: var(--text-body);
		display: flex;
		align-items: center;
	}
	.image {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		width: 100%;

		@media (max-width: 800px) {
			gap: 0.5rem;
		}

		img {
			width: 40px;
			height: 40px;
			border-radius: 100%;
		}
	}
`;

export const ImageUser = styled(FaUser)`
	width: 40px;
	height: 40px;

	border-radius: 100%;
`;
