import { FaUser } from "react-icons/fa";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	gap: 1rem;

	.container-bottom {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		background-clip: border-box;
		border: 0 solid rgba(0, 0, 0, 0.125);
	}
`;

export const ContentProfilerWrapper = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	gap: 2rem;
	background-color: #fff;
	background-clip: border-box;
	border: 0 solid rgba(0, 0, 0, 0.125);
`;

export const ContentWrapper = styled.section`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	width: 100%;
	justify-content: center;
	// padding: 2rem;
	gap: 2rem;
	background-color: #fff;
	background-clip: border-box;
	border: 0 solid rgba(0, 0, 0, 0.125);
`;

export const ImageWrapper = styled.section`
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 100%;

	img {
		width: 150px;
		height: 150px;
		object-fit: fill;
		border-radius: 50%;
		border: 0 solid rgba(0, 0, 0, 0.125);
	}
`;

export const RatingWrapper = styled.section`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 1rem;
`;

export const Content = styled.div`
	display: flex;
	flex-flow: row wrap;
	height: 100%;
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: #e1e1e1;
	gap: 1rem;

	@media (max-width: 800px) {
		flex-direction: column;
	}

	.button-column {
		display: flex;
		gap: 1rem;
	}

	.button-modal {
		border: 0;
		outline: 0;
		background-color: #1ea418;
		font: var(--text-body);
		color: #fff;
		border-radius: 10px;
		display: flex;
		width: 10rem;
		height: 40px;
		margin: 0 auto;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		:disabled {
			opacity: 0.7;
		}
	}
`;

export const ImageButton = styled.label`
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 10rem;
	background-color: #1ea418;
	border: 1px solid #1ea418;
	color: #ffffff;
	opacity: 0.85;
	border-radius: 4px;
	transition: opacity 0.3s;
	cursor: pointer;
	outline: 0;
	margin: 10px;

	:disabled {
		opacity: 0.7;
	}

	:hover {
		opacity: 1;
	}
`;

export const UserDefault = styled(FaUser)`
	width: 150px;
	height: 150px;
	object-fit: fill;
	border-radius: 100%;
	border: 0 solid rgba(0, 0, 0, 0.125);
	padding: 0.5rem;
`;

export const PrivacityContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;
	margin: 1rem;
	border-top: 1px solid #e1e1e1;
	h3 {
		margin-top: 1rem;
	}

	@media (max-width: 800px) {
		flex-direction: column;
	}
`;

export const ContainerButton = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
	gap: 1rem;
`;

export const ModalWrapper = styled.div`
	width: 100%;

	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.content-button {
			display: flex;
			padding-bottom: 1rem;
			gap: 1rem;
			margin-left: auto;
			margin-top: 30px;
		}
	}
`;
