import { useState } from "react";

import { deleteOrganization } from "../../../services/organization-services";
import { organizationUpdate } from "../../../services/organizationUpload-service";
import { userDelete } from "../../../services/user-services";
import { handleToast } from "../../HandleToast";
import { Modal } from "../../Modal";
import { Button } from "../../StyledComponents";

import { Container, ContentButton } from "./styles";
import { useAppSelector } from "../../../store/hooks";

export function DeleteAccount() {
	const { data } = useAppSelector((state) => state.user);

	const [modal, setModal] = useState<boolean>(false);
	const [modalEdit, setModalEdit] = useState<boolean>(false);

	async function handleDeleteAccount() {
		if (data.organization?._id && data.user?._id) {
			const response = await userDelete(data.user?._id);
			const result = await deleteOrganization(data.organization?._id);
			if (!response.error && !result.error) {
				return localStorage.removeItem("persist:root");
			}
		} else {
			return handleToast(true, "Não foi possível deletar o perfil");
		}
	}

	async function handleStatusAccount() {
		var status = !data.organization?.isActive;

		var obj = {
			isActive: status,
		};

		await organizationUpdate(data.organization?._id, obj);

		setModalEdit(false);
	}
	return (
		<Container>
			<ContentButton>
				<Button type="button" onClick={() => setModal(true)}>
					Deletar Conta
				</Button>
				<Button type="button" onClick={() => setModalEdit(true)}>
					Ativar/Desativar conta
				</Button>
			</ContentButton>

			{modalEdit && (
				<Modal
					id="modal"
					setState={setModalEdit}
					title={
						data.user.isActive === true ? "Desativar conta" : "Ativar conta"
					}
				>
					<div>
						<p>
							Ao excluir a conta você concorda que todos os dados referente a
							sua conta serão apagados permanentemente.
						</p>
						<br />
					</div>
					<ContentButton>
						<Button
							outline
							type="button"
							onClick={() => {
								setModalEdit(false);
							}}
						>
							Cancelar
						</Button>
						<Button type="button" onClick={handleStatusAccount}>
							Confirmar
						</Button>
					</ContentButton>
				</Modal>
			)}

			{modal && (
				<Modal id="modal" setState={setModal} title="Deletar conta">
					<div>
						<p>
							Ao excluir a conta você concorda que todos os dados referente a
							sua conta serão apagados permanentemente.
						</p>
						<br />
					</div>
					<ContentButton>
						<Button
							outline
							type="button"
							onClick={() => {
								setModal(false);
							}}
						>
							Cancelar
						</Button>
						<Button onClick={handleDeleteAccount}>Confirmar</Button>
					</ContentButton>
				</Modal>
			)}
		</Container>
	);
}
