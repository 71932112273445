import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import { InputAdornment, TextField } from "@mui/material";
import validator from "validator";

import { create } from "../../../services/user-services";
import { Loading } from "../../Loading";
import { AuthForm, Container, Row } from "./styles";

import { Modal } from "../../Modal";
import { handleToast } from "../../HandleToast";
import { getSystemSettings } from "../../../services/system-service";
import { Button } from "../../StyledComponents";

import "react-toastify/dist/ReactToastify.css";
import { IconButton } from "../../IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IData, PasswordVisibilityState, Props } from "./types";

export function FormUser(props: Props) {
	const navigate = useNavigate();

	var { id = "modal" } = props;

	const [loading, setLoading] = useState(false);
	const [modalTerms, setModalTerms] = useState(false);
	const [modalPrivacy, setModalPrivacy] = useState(false);

	const [modalLGPD, setModalLGPD] = useState(false);
	const [seePassword, setSeePassword] = useState<PasswordVisibilityState>({
		pass: false,
		confirmPass: false,
	});

	const [data, setData] = React.useState<IData>();

	React.useEffect(() => {
		handleContracts();
	}, []);

	const togglePassword = useCallback((field: keyof PasswordVisibilityState) => {
		setSeePassword((prev) => ({
			...prev,
			[field]: !prev[field],
		}));
	}, []);

	//eslint-disable-next-line no-useless-escape
	const schema = yup.object().shape({
		email: yup.string().email("E-mail inválido").required("E-mail obrigatório"),
		password: yup
			.string()
			.required("Senha obrigatória")
			.min(6, "Senha deve conter mínimo de 6 dígitos.")
			.max(32, "Senha deve conter máximo de 32 dígitos.")
			.matches(/^(?=.*[a-z])/, "Senha deve conter um caracter minúsculo.")
			.matches(/^(?=.*[A-Z])/, "Senha deve conter um caracter maiúsculo.")
			.matches(/^(?=.*[0-9])/, "Senha deve conter um número.")

			.matches(
				/^(?=.*[!@#%&])(?=.{8,})/,
				"Senha deve conter um caractere especial."
			),
		confirmPassword: yup
			.string()
			.required("Confirme sua senha")
			.min(6, "Senha deve conter mínimo de 6 dígitos.")
			.max(32, "Senha deve conter máximo de 32 dígitos.")
			.oneOf([yup.ref("password"), null], "As senhas devem ser iguais"),
		name: yup.string().required("Nome é obrigatorio"),
		lastName: yup.string(),
		tel: yup.string(),
		cel: yup.string().required("Informe o celular"),

		termsOfUse: yup
			.boolean()
			.required("aceite os termos de uso para continuar")
			.isTrue("aceite os termos"),

		lgpd: yup.boolean().required("termos de lgpd").isTrue("aceite os termos"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		reValidateMode: "onBlur",
	});

	function handleOutSideClick(e: any) {
		if (e.target.id === id) setModalTerms(false);
		if (e.target.id === id) setModalLGPD(false);
		if (e.target.id === id) setModalPrivacy(false);
	}

	const handleContracts = async () => {
		const response = await getSystemSettings();
		if (!response.error) {
			setData(response.data.items[0]);
		}
	};

	const handleAuth = async (data: any) => {
		setLoading(true);

		let { cel, tel } = data;

		data.privacyPolicy = data.termsOfUse;
		data.userType = "seller";

		let _cel = validator?.isEmpty(cel) ? null : cel.split(" ");
		if (_cel !== null) {
			data.celDDD = _cel[0]?.replace(/([^\d])+/gim, "");
			data.cel = _cel[1]?.replace(/([^\d])+/gim, "");
		}
		let _tel = validator?.isEmpty(tel) ? null : tel.split(" ");
		if (_tel !== null) {
			data.telDDD = _tel[0]?.replace(/([^\d])+/gim, "");
			data.tel = _tel[1]?.replace(/([^\d])+/gim, "");
		}

		const response = await create(data);

		handleToast(response?.error, response?.message);
		if (!response?.error) {
			navigate("/");
		}
		return setLoading(false);
	};

	return (
		<Container>
			<AuthForm onSubmit={handleSubmit(handleAuth)}>
				<h1>Cadastre-se</h1>
				<TextField
					type="text"
					placeholder="Nome"
					label="Nome"
					size="small"
					variant="outlined"
					fullWidth
					{...register("name")}
					helperText={errors?.name?.message?.toString()}
				/>

				<TextField
					type="text"
					placeholder="Sobrenome"
					label="Sobrenome"
					size="small"
					variant="outlined"
					fullWidth
					{...register("lastName")}
					helperText={errors?.name?.message?.toString()}
				/>

				<TextField
					type="email"
					placeholder="E-mail"
					label="E-mail"
					size="small"
					variant="outlined"
					fullWidth
					{...register("email")}
					helperText={errors?.email?.message?.toString()}
				/>
				<Row>
					<TextField
						type="tel"
						placeholder="Telefone"
						label="Telefone"
						size="small"
						variant="outlined"
						fullWidth
						InputProps={{
							inputComponent: InputMask,
							inputProps: {
								mask: "(99) 9999-9999",
							},
						}}
						{...register("tel")}
					/>
					<TextField
						type="tel"
						placeholder="Celular"
						label="Celular"
						size="small"
						variant="outlined"
						fullWidth
						InputProps={{
							inputComponent: InputMask,
							inputProps: {
								mask: "(99) 99999-9999",
							},
						}}
						{...register("cel")}
						helperText={errors?.cel?.message?.toString()}
					/>
				</Row>
				<Row>
					<TextField
						label="Senha"
						type={seePassword.pass ? "text" : "password"}
						placeholder="Senha"
						size="small"
						variant="outlined"
						fullWidth
						{...register("password")}
						helperText={errors?.password?.message?.toString()}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton handlePress={() => togglePassword("pass")}>
										{seePassword.pass ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					<TextField
						type={seePassword.confirmPass ? "text" : "password"}
						placeholder="Confirme a senha"
						label="Confirme a senha"
						size="small"
						variant="outlined"
						fullWidth
						{...register("confirmPassword")}
						helperText={errors?.confirmPassword?.message?.toString()}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										handlePress={() => togglePassword("confirmPass")}
									>
										{seePassword.confirmPass ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Row>

				<div className=" flex">
					<div className="form-row terms-row">
						<label>
							<input
								type="checkbox"
								{...register("termsOfUse")}
								// value="true"
							/>{" "}
						</label>
						<div className="terms">
							<p>
								Li e aceito os{" "}
								<span
									onClick={() => {
										setModalTerms(true);
									}}
								>
									Termos de uso
								</span>{" "}
								e a{" "}
								<span
									onClick={() => {
										setModalPrivacy(true);
									}}
								>
									Política de privacidade
								</span>
							</p>
						</div>
					</div>
					<div className="span">
						<span>{errors?.termsOfUse?.message?.toString()}</span>
					</div>
					{modalTerms ? (
						<Modal
							id={id}
							onClick={handleOutSideClick}
							setState={setModalTerms}
							title="Termos de uso"
						>
							<section
								dangerouslySetInnerHTML={{
									__html: data?.termsOfUse.html,
								}}
							/>
						</Modal>
					) : null}
					{modalPrivacy ? (
						<Modal
							id={id}
							onClick={handleOutSideClick}
							setState={setModalPrivacy}
							title="Política de privacidade"
						>
							<section
								dangerouslySetInnerHTML={{
									__html: data?.privacyPolicy.html,
								}}
							/>
						</Modal>
					) : null}
				</div>

				<div className="flex">
					<div className="form-row terms-row">
						<label>
							<input type="checkbox" {...register("lgpd")} />
						</label>
						<div className="terms">
							<p>
								LGPD{" "}
								<span
									onClick={() => {
										setModalLGPD(true);
									}}
								>
									LGPD - Lei Geral de Proteção de Dados
								</span>
							</p>
						</div>
					</div>
					<div className="span">
						<span>{errors?.lgpd?.message?.toString()}</span>
					</div>
					<div className="column">
						{modalLGPD ? (
							<Modal
								id={id}
								onClick={handleOutSideClick}
								setState={setModalLGPD}
								title="LGPD"
							>
								<section
									dangerouslySetInnerHTML={{
										__html: data?.lgpd?.html,
									}}
								/>
							</Modal>
						) : null}
					</div>
				</div>
				<div className="form-row">
					<p className="contact">
						Ao prosseguir, aceito que a LUBBRI entre em contato comigo por
						telefone, e-mail ou WhatsApp (incluindo mensagens automáticas para
						fins comerciais).
					</p>
				</div>
				<Button type="submit" disabled={loading ? true : false} width="100%">
					{loading ? (
						<Loading type={"bars"} color={"#fff"} width="35px" height="35px" />
					) : (
						<>Cadastre-se</>
					)}
				</Button>
				<p>
					Já possui uma conta?
					<Link to="/" className="link-register">
						{" "}
						Login
					</Link>
				</p>
			</AuthForm>
		</Container>
	);
}
