import React from "react";
import { PropsProducts } from "../../utils/types/product/ProductTypes";
import { Add, Container, PTable } from "./styles";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

type Props = {
    products: PropsProducts[];
    onSelectItem?: (add) => void;
    onUpdate?: () => void;
    page: number;
    setPage: Function;
    paginate: number;
};
export function TableAddProductSale(props: Props) {
    var {
        products,
        onSelectItem,
        // onUpdate
        page,
        setPage,
        paginate,
    } = props;

    function handleAdd(add: any) {
        onSelectItem(add);
    }

    function handleRender(data: Array<PropsProducts>) {
        let rows = data.map((item, index) => (
            <tr key={item?._id}>
                <td>{index + 1}</td>

                <td>{item?.brand}</td>
                <td>{item?.category}</td>
                <td className="description-limit" title={item?.description}>
                    {item?.description}
                </td>
                <td style={{ textAlign: "right" }}>
                    {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    }).format(item?.costPrice)}
                </td>

                <td style={{ textAlign: "right" }}>
                    {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    }).format(item?.salePrice)}
                </td>
                <td style={{ textAlign: "center" }}>
                    <img src={item?.image} alt="imagem do produto" />
                </td>
                <td style={{ textAlign: "center" }}>{item?.amount}</td>
                <td>
                    <Add onClick={() => handleAdd(item)} />
                </td>
            </tr>
        ));

        return rows;
    }
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <Container>
            <PTable>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Marca</th>
                        <th>Categoria</th>
                        <th>Descrição</th>
                        <th>P/Custo</th>
                        <th>P/Venda</th>
                        <th>Imagem</th>
                        <th>Quantidade</th>
                        <th>Adicionar</th>
                    </tr>
                </thead>

                <tbody className="scroll">{handleRender(products)}</tbody>
            </PTable>
            <footer>
                <Stack spacing={2} style={{ alignItems: "center" }}>
                    <Pagination
                        count={paginate}
                        showFirstButton
                        showLastButton
                        page={page}
                        onChange={handlePageChange}
                    />
                </Stack>
            </footer>
        </Container>
    );
}
