import { Container } from "./styles";
import Logo from "../../assets/images/logo-omegaco.png";

export function FooterC() {
	return (
		<Container>
			<span>Desenvolvido por:</span>
			<img
				src={Logo}
				width={100}
				height={40}
				style={{ objectFit: "cover" }}
				alt="Logo OmegaCoTech"
			/>
		</Container>
	);
}
