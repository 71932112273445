import { useCallback, memo } from "react";
import { IconButton } from "@mui/material";
import { Avatar, Container, Divider, IconsWrapper, ImageUser } from "./styles";
import { FiLogOut } from "react-icons/fi";
import { useAppDispatch } from "../../../store/hooks";
import { clearToken } from "../../../store/token";
import { useNavigate } from "react-router-dom";
import { PropsUser } from "../../../store/user";

interface IProps {
	collapsed: boolean;
	user: PropsUser;
}

const SidebarFooter: React.FC<IProps> = ({ collapsed, user }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	function navProfile() {
		navigate("/perfil");
	}

	function handleLogout() {
		dispatch(clearToken());
		navigate("/");
	}

	const renderUserImage = useCallback(() => {
		if (user?.image64)
			return (
				<img src={`data:${user.mimetype};base64,${user.image64}`} alt="" />
			);

		return <ImageUser />;
	}, [user]);

	return (
		<Container collapsed={collapsed}>
			<Avatar collapsed={collapsed}>
				<div className="image" onClick={navProfile}>
					{!collapsed && <p>{user?.name}</p>}
					{renderUserImage()}
				</div>
			</Avatar>
			{!collapsed && <Divider />}
			<IconsWrapper>
				<IconButton onClick={handleLogout}>
					<FiLogOut />
				</IconButton>
			</IconsWrapper>
		</Container>
	);
};

export default memo(SidebarFooter);
