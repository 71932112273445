import api from ".";

export async function create(data: any) {
	const response = await api.post("/seller/create", data);
	return response.data;
}

export async function recPassword(data: any) {
	const response = await api.patch("/seller/lostpassword", data);
	return response.data;
}

export async function getUserData() {
	const response = await api.get(`/seller`);

	return response.data;
}

export async function userDelete(id: string) {
	const response = await api.delete(`/user/${id}`);
	return response.data;
}

export async function listUserById(id: any) {
	const response = await api.get(`/user/userid/${id}`);
	return response.data;
}

export async function imageUpload(file: any) {
	const response = await api.post(`/seller/imageupload`, file);
	return response.data;
}

export async function imageUpdate(image: any) {
	const response = await api.patch(`/seller/image/update`, { image });
	return response.data;
}

export async function updateUser(id: string, data: any) {
	const response = await api.put(`/user/${id}/update`, data);
	return response.data;
}

export async function updateImageSeller(data: any) {
	let obj = {
		data,
	};
	const response = await api.patch(`/seller/image/update`, obj);
	return response.data;
}

export async function updateSeller(id: string, data: any) {
	const response = await api.put(`/seller/${id}/update`, data);
	return response.data;
}
export async function updateUserPassword(data: any) {
	const response = await api.patch(`/user/${data.userId}/updatepassword`, data);

	return response.data;
}
