// services/index.js

import axios from "axios";
import { Config } from "../config";
import type { Store } from "../store";
import { v4 } from "uuid";
import { handleToast } from "../components/HandleToast";
import { saveToken } from "../store/token";
import { storeUserData } from "../store/user";

let store: Store = null;

export function injectStore(_store: Store) {
	store = _store;
}

const instance = axios.create({
	baseURL: Config.API_URL,
	headers: {
		timeout: 3000,
	},
});

export const handleError = (error: any) => {
	// crashlytics here

	return Promise.reject(error);
};

instance.interceptors.request.use(
	async (request) => {
		const myUUID = v4();

		try {
			if (request.method === "delete" || request.method === "get") {
				request.headers["X-transaction-code"] = myUUID;
			}
			if (
				request.method === "post" ||
				request.method === "put" ||
				request.method === "patch"
			) {
				request.data.transactionCode = myUUID;
			}
			if (
				!request.url?.endsWith("signin") &&
				!request.url?.endsWith("/seller/create") &&
				!request.url?.endsWith("/seller/auth")
			) {
				const state = store.getState();

				const { data } = state.token;
				request.headers.Authorization = `Bearer ${data}`;
			}

			return request;
		} catch (error: any) {
			return handleError(error);
		}
	},
	(error) => {
		return handleError(error);
	}
);

instance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error?.response) {
			const { status } = error?.response;

			if (status === 400 || status === 500) {
				return error.response;
			}

			if (status === 401) {
				window.location.href = "/";

				store.dispatch(saveToken(null));
				store.dispatch(storeUserData(null));

				return handleToast(
					true,
					error.response.message || error.response.data.message
				);
			}

			return handleError(error);
		}
	}
);

export default instance;
