import React, { ReactNode } from "react";
import { Close, Content, Header, ModalStyled, OutModalStyled } from "./styles";

type Props = {
    id: string;
    onClick?: (e) => void;
    children: ReactNode;
    title?: string;
    width?: string;
    // state: boolean;
    setState: Function;
    maxHeight?: string;
};

export function Modal(props: Props) {
    var { id, children, title, setState, onClick, width, maxHeight } = props;

    return (
        <OutModalStyled className="modal-wrapper" id={id} onClick={onClick}>
            <ModalStyled className="modal-container" maxHeight={maxHeight}>
                <Header className="modal-header">
                    <h3>{title}</h3>
                    <Close onClick={() => setState(false)} />
                </Header>
                <Content width={width}>{children}</Content>
            </ModalStyled>
        </OutModalStyled>
    );
}
