import { Box } from "@mui/material";
import { Graphic } from "../Graphic";
import { batch } from "react-redux";

type Props = {
	data?: any;
};

export function GraphicMonth(props: Props) {
	let { data } = props;
	let _monthSeries: number[] = [];
	let _monthCategories: string[] = [];

	const value = () => {
		data.forEach((resp) => {
			_monthSeries.push(parseInt(resp.total));
			_monthCategories.push(resp._id);
		});
	};

	const series = [
		{
			name: "R$",
			data: _monthSeries,
		},
	];

	const categories = _monthCategories;

	batch(() => {
		value();
	});
	return (
		<Box sx={{ backgroundColor: "#ffffff", flex: 1, padding: "15px" }}>
			<div>
				<h1 style={{ fontSize: "25px", margin: "5px 0" }}>Receita</h1>
				<h4>Total do mês</h4>
			</div>
			<div>
				<Graphic
					categories={categories}
					series={series}
					type="line"
					width="100%"
					heigth="300"
				/>
			</div>
		</Box>
	);
}
