import React from "react";
import { BiCalendar } from "react-icons/bi";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { TbCalendarStats, TbCalendarOff, TbCalendar } from "react-icons/tb";
import { BiCalendarCheck, BiTimeFive } from "react-icons/bi";

import { CardInfo } from "../../components/CardInfo";
import { AuthContainer } from "../Containers";
import {
	Content,
	Row,
	Container,
	ListContainer,
	ContentHeader,
	DrawerContent,
	CardsWrapperScheduling,
	ContainerCalendar,
	ContainerButton,
	ContainerVehicleData,
	SelectDrawerContent,
	Select,
	ContainerButtonModal,
} from "./styles";
import Calendar from "../../components/Calendar";
import {
	changeSchedulesStatus,
	listSchedules,
} from "../../services/schedule-service";
import { handleToast } from "../../components/HandleToast";
import moment from "moment";
import {
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
} from "@material-ui/core";
import { Drawer, ListItemButton, TextField } from "@mui/material";
import Button from "../../components/StyledComponents/Button";
import { formatDateMax } from "../../utils/functions/FormatDates";
import { Modal } from "../../components/Modal";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "../../store/hooks";

interface IOrganizationAddress {
	city: string;
	complement: string;
	country: string;
	createdAt: string;
	district: string;
	number: number;
	state: string;
	street: string;
	updatedAt: string;
	zipcode: string;
	_id: string;
}

interface IOrganization {
	address: IOrganizationAddress[];
	companyName: string;
	isActive: boolean;
	name: string;
	_id: string;
}

interface ISchedule {
	_id: string;
	startDate: string;
	endDate: string;
	organization: IOrganization;
	title: string;
	description?: string;
	status: string;
}

export function Scheduling() {
	const { data } = useAppSelector((state) => state.user);

	const [schedules, setSchedules] = React.useState<ISchedule[]>();

	const [loading, setLoading] = React.useState<boolean>(true);
	const [selected, setSelected] = React.useState<ISchedule>(null);
	const [schedulesAll, setSchedulesAll] = React.useState(0);
	const [schedulesCanceled, setSchedulesCanceled] = React.useState(0);
	const [schedulesConfirmed, setSchedulesConfirmed] = React.useState(0);
	const [schedulesAwait, setSchedulesAwait] = React.useState(0);
	const [modal, setModal] = React.useState(false);

	const [values, setValues] = React.useState({
		canceledReason: "",
	});

	const id = "modal";

	function handleOutSideClick(e: any) {
		if (e.target.id === id) setModal(false);
	}

	const schema = yup.object().shape({
		canceledReason: yup.string().required("Informe o motivo do cancelamento"),
	});

	const { setValue } = useForm({
		resolver: yupResolver(schema),
	});

	const handleSchedules = React.useCallback(
		async (date: string) => {
			setLoading(true);
			const response = await listSchedules(data.organization?._id, {
				startDate: date,
			});
			const cards = await listSchedules(data.organization?._id, {
				page: -1,
			});
			if (!response.error) {
				setLoading(false);
				setSchedules(response.data.items);

				let dataResponse = response.data;
				dataResponse.inProgress = 0;
				dataResponse.canceled = 0;
				dataResponse.completed = 0;

				response.data.items.forEach((element) => {
					if (element.status === "SCHEDULED_AWAIT") {
						dataResponse.inProgress += 1;
					}
					if (element.status === "SCHEDULED_CANCELED") {
						dataResponse.canceled += 1;
					}
					if (element.status === "SCHEDULED_FINISHED") {
						dataResponse.completed += 1;
					}
				});
				let canceled = cards.data.items.filter(
					(canc) => canc === "SCHEDULE_CANCELED"
				);
				let confirmed = cards.data.items.filter(
					(canc) => canc.status === "SCHEDULED_CONFIRMED"
				);
				let awaits = cards.data.items.filter(
					(canc) => canc.status === "SCHEDULE_AWAIT"
				);
				setSchedulesCanceled(canceled.length);
				setSchedulesConfirmed(confirmed.length);
				setSchedulesAwait(awaits.length);
				setSchedulesAll(cards.data.items.length);
			}

			setLoading(false);
			return handleToast(true, response.message);
		},
		[data.organization?._id]
	);

	const changeStatus = React.useCallback(
		async (_status, id) => {
			const body = {
				status: _status,
			};

			const response = await changeSchedulesStatus(id, body);

			if (!response.error) {
				handleToast(false, response.message);
				setSelected({ ...selected, status: _status });
			}
		},
		[selected]
	);

	const handleChange =
		(property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({
				...values,
				[property]: (event.target as HTMLInputElement).value,
			});
			if (property === "canceledReason")
				setValue("canceledReason", event.target.value);
		};

	async function handleSubmit(id) {
		let body = {
			status: "SCHEDULED_CANCELED",
			canceledReason: values?.canceledReason,
		};

		const response = await changeSchedulesStatus(id, body);
		if (!response.error) {
			handleToast(false, response.message);
			setModal(!modal);
		}
	}

	const formatStatus = React.useCallback((status) => {
		switch (status) {
			case "Aguardando Serviço":
				return "Aguardando serviço";

			case "SCHEDULE_AWAIT":
				return "Aguardando";

			case "SCHEDULE_CREATED":
				return "Agendamento criado";

			case "SCHEDULE_DATE_MODIFIED":
				return "Data modificada";

			case "SCHEDULED_CONFIRMED":
				return "Confirmado";

			case "SCHEDULED_CANCELED":
				return "Cancelado";

			case "SCHEDULED_FINISHED":
				return "Finalizado";

			default:
				return "Erro ao carregar";
		}
	}, []);

	const changeColor = React.useCallback((status: string): object => {
		switch (status) {
			case "Aguardando Serviço":
				return { color: "orange" };

			case "SCHEDULED_AWAIT":
				return { color: "orange" };

			case "SCHEDULED_CREATED":
				return { color: "blue" };

			case "SCHEDULED_DATE_MODIFIED":
				return { color: "gray" };

			case "SCHEDULED_CONFIRMED":
				return { color: "blue" };

			case "SCHEDULED_CANCELED":
				return { color: "red" };

			case "SCHEDULED_FINISHED":
				return { color: "green" };

			default:
				return { color: "black" };
		}
	}, []);

	const renderList = (data: ISchedule[]) => {
		const rows = [];
		if (data) {
			data.forEach((item, index) => {
				rows.push(
					<>
						<ListItemButton onClick={() => setSelected(item)}>
							<ListItem key={index}>
								{/* <ListItemAvatar>
                                    <Avatar>{item?.organization?.image}</Avatar>
                                </ListItemAvatar> */}
								<ListItemText
									primary={item?.organization?.name}
									secondary={`${moment(item?.startDate).format(
										"DD MMM YYYY"
									)} - ${moment(item?.startDate).format("HH:mm")} às ${moment(
										item?.endDate
									).format("HH:mm")}`}
								/>
							</ListItem>
						</ListItemButton>
						{index < schedules.length - 1 && <Divider variant="inset" />}
					</>
				);
			});
		}

		return <List>{rows}</List>;
	};

	React.useEffect(() => {
		const today = moment().format("YYYY-MM-DD");
		handleSchedules(today);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthContainer pageTitle="Agendamentos" loading={loading}>
			<Content>
				<CardsWrapperScheduling>
					<CardInfo
						value={schedulesAll || 0}
						subtitle="Pedidos agendados"
						subtitleIcon={<BiCalendar />}
						color="blue"
						icon={<TbCalendar />}
					/>
					<CardInfo
						value={schedulesConfirmed || 0}
						subtitle="Pedidos concluídos"
						subtitleIcon={<AiOutlineCheckCircle />}
						color="green"
						icon={<BiCalendarCheck />}
					/>
					<CardInfo
						value={schedulesCanceled || 0}
						subtitle="Pedidos cancelados"
						subtitleIcon={<AiOutlineCloseCircle />}
						color="red"
						icon={<TbCalendarOff />}
					/>
					<CardInfo
						value={schedulesAwait || 0}
						subtitle="Pedidos em andamento"
						subtitleIcon={<BiTimeFive />}
						color="orange"
						icon={<TbCalendarStats />}
					/>
				</CardsWrapperScheduling>
				<Row>
					<ContainerCalendar>
						<Calendar onCellClick={(date) => handleSchedules(date)} />
					</ContainerCalendar>
					<Container>
						<ContentHeader>
							<h4>Agenda do dia</h4>
						</ContentHeader>
						<ListContainer className="list-container">
							{renderList(schedules)}
						</ListContainer>
					</Container>
				</Row>
			</Content>
			<Drawer
				open={!!selected}
				onClose={() => setSelected(null)}
				anchor="right"
			>
				<DrawerContent>
					<Typography>Dados do usuário</Typography>
					<Divider light component={"li"} />
					<TextField
						label="Usuário"
						variant="outlined"
						value={data.user.name}
						fullWidth
						disabled
					/>
					<TextField
						label="Celular"
						variant="outlined"
						value={`${data?.user.celDDD} - ${data?.user.cel}`}
						fullWidth
						disabled
					/>
					<Divider light component={"li"} />
					<Typography>Detalhes do Agendamento</Typography>
					<SelectDrawerContent>
						<Select>
							<label>Data inicial:</label>
							<input
								type="date"
								id="currentDate"
								value={selected ? formatDateMax() : ""}
							/>
						</Select>
						<Select>
							<label>Hora inicial:</label>
							<input
								type="time"
								id="currentTime"
								value={moment(selected?.startDate).utc().format("HH:mm")}
							/>
						</Select>
					</SelectDrawerContent>
					<SelectDrawerContent>
						<Select>
							<label>Data final:</label>
							<input
								type="date"
								id="currentDate"
								value={selected ? formatDateMax() : ""}
							/>
						</Select>
						<Select>
							<label>Hora final:</label>
							<input
								type="time"
								id="currentTime"
								value={moment(selected?.endDate).utc().format("HH:mm")}
							/>
						</Select>
					</SelectDrawerContent>
					<ContainerButton>
						<Button>Editar agendamento</Button>
					</ContainerButton>
					<ContainerButton>
						<Typography>Status:</Typography>
						<h4 style={changeColor(selected?.status)}>
							{selected ? formatStatus(selected.status) : "Erro"}
						</h4>
					</ContainerButton>
					<Divider light component={"li"} />
					<Typography>Dados do Veículo</Typography>
					<ContainerVehicleData>
						<TextField label="Modelo" variant="outlined" value={""} fullWidth />
						<TextField label="Ano" variant="outlined" value={""} fullWidth />
						<TextField label="Placa" variant="outlined" value={""} fullWidth />
					</ContainerVehicleData>
					<ContainerButton>
						<Button
							onClick={() => changeStatus("SCHEDULED_CONFIRMED", selected._id)}
						>
							Confirmar
						</Button>
						<Button backgroundColor="red" onClick={() => setModal(true)}>
							Cancelar
						</Button>
						<Button
							outline={true}
							backgroundColor="white"
							color="black"
							onClick={() => changeStatus("SCHEDULED_FINISHED", selected._id)}
						>
							Finalizar
						</Button>
						{modal && (
							<Modal
								id={id}
								onClick={handleOutSideClick}
								setState={setModal}
								width="100%"
								title="Deseja mesmo cancelar?"
							>
								<div>
									<TextField
										fullWidth
										label="Motivo do cancelamento"
										onChange={handleChange("canceledReason")}
										id="fullWidth"
										multiline
										maxRows={4}
									/>
								</div>
								<ContainerButtonModal>
									<Button type="button" onClick={() => setModal(false)} outline>
										Cancelar
									</Button>
									<Button
										type="submit"
										onClick={() => handleSubmit(selected._id)}
									>
										Confirmar
									</Button>
								</ContainerButtonModal>
							</Modal>
						)}
					</ContainerButton>
				</DrawerContent>
			</Drawer>
		</AuthContainer>
	);
}
